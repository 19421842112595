export default `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<title>T&amp;C_sc</title>
    <style>
		.level1{
		margin-left:1.5cm;
	    text-indent:-30pt;
		}
        .level1 .left{		
		    display: inline-block;
			width: 1em;
			text-align:right}
        .level1 .right{
		    margin-left:0.58cm;
			text-align:left}	
	
        .list{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-24pt;
		text-align:left;
		}

		.item .left{	
		    display: inline-block;	
			text-align:right;}
        .item .right{
		    margin-left:0.9cm;
			text-align:left}
			
        .list2{
		list-style: none;		 
		margin-left:1.5cm;
	    text-indent:-24pt;
		text-align:left;
		}
		
		.item2 .left{	
		    display: inline-block;			
			width: 1em;
			text-align:right}
        .item2 .right{	
		    margin-left:0.38cm;
			text-align:left}

        .list3{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-20pt;
		text-align:left;
		}

		.item3 .left{
		    width:1em;
			text-align:left;}
        .item3 .right{	
		    margin-left:0.2cm;	
			text-align:left}
        h3 span:last-child {
            margin-left: 0.7em !important;
        }

        h2 {
            font-size: 16px;
            font-weight: 700;
        }
        
        h3 {
            font-size: 14px;
            font-weight: 700;
        }
    </style>
</head>
<body>  
      
      <br>
      <section>
        <p style="margin-left:0.8cm;">本行网上银行平台所提供服务的条款载列如下。在前往下一页之前，请仔细阅读有关条款：
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">
                <b>A.</b>
              </span>
              <span class="right">
                <b>网上银行服务的条款及细则</b>，当中载列阁下存取及使用汇丰环球私人银行网上银行平台的条款及细则<b>；</b>
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>B.</b>
              </span>
              <span class="right">
                <b>汇丰私人银行视野及研究的条款及细则</b>，当中载列阁下可通过汇丰环球私人银行网上银行平台存取及使用的汇丰私人银行视野及研究的额外条款及细则<b>；</b>
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>C.</b>
              </span>
              <span class="right">
                <b>推播通知服务的条款及细则</b>，当中载列本行向阁下提供并且与阁下可通过汇丰环球私人银行网上银行平台存取或使用的产品及服务有关的推播通知服务的额外条款及细则<b>；</b>及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>D.</b>
              </span>
              <span class="right">
                <b>电子签署服务的条款及细则</b>，当中载列任何使用电子签署签立的文件适用的额外条款及细则。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>E.</b>
              </span>
              <span class="right">
                <b>可疑委托警示模式的条款及细则</b>，此乃与潜在可疑转账交易的警示有关。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">A.	网上银行服务的条款及细则</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">定义及解释</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">除本文另有界定外，本行的标准条款与条件（可经不时修订）（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Simplified%20Chinese%20version.pdf">此</a>查阅，“<b>标准条款与条件</b>”）中界定的表述于本网上银行服务的条款及细则（“<b>网上银行条款</b>”）中使用时具有相同涵义。如本网上银行条款与标准条款与条件之间有任何分歧，概以本网上银行条款为准。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">
            于本网上银行条款中，除文义另有所指外，以下词汇具有以下涵义：
          </span>
        </p>
        <ol class="list">
          <li class="item">
            <p>
              <span class="right">
                “<b>指定网上用户</b>”指获阁下授权使用阁下账户的网上银行服务的指定人士（包括阁下）。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                “<b>信息</b>”指本行通过任何互联网网站提供由任何信息提供商提供的金融、市场或其他信息及数据，包括B部中界定的“刊物”所包含的任何有关信息。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>信息提供商</b>”指提供信息的任何人士或实体，包括提供信息的本行及任何集团办事处，以及提供任何信息给信息提供商的任何其他人士或实体。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>互联网网站</b>”指由本行或任何其他人士代表本行建立、运作及╱或维护以提供网上银行服务的互联网网站或其他平台，包括移动银行应用程序。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>网上银行服务</b>”指经不时修订的下文第2条（<i>网上银行服务</i>）所介绍的服务，包括根据B部提供的视野及研究服务、移动银行应用程序服务及根据D部提供的电子签署。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>网上银行条款</b>”指网上银行服务的条款及细则。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>移动银行应用程序</b>”指本行提供的应用程序，下载至阁下的移动装置即可通过该程序使用移动银行应用程序服务。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>移动银行应用程序服务</b>”指向阁下提供的服务，藉以让阁下通过移动银行应用程序使用网上银行服务，包括C部中界定的推播通知服务。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>密码</b>”指指定网上用户使用的密码，以便使用网上银行服务。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>报告</b>”指以任何形式、媒介或途径利用信息编制的任何报告，包括B部中界定的任何“刊物”。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>安全码</b>”指由本行提供并用作使用网上银行服务的任何代码。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>标准条款与条件</b>”具有本网上银行条款第1条（<i>定义及解释</i>）所赋予的涵义。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>本行</b>”或具有该或类似涵义的词语指本行。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>用户名</b>”指本行接受的指定网上用户的用户识别代码，以便使用网上银行服务。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>阁下</b>”或具有该或类似涵义的词语指客户，如文义许可，包括经客户授权就使用网上银行服务向本行发出指示或要求的任何人士，包括任何指定网上用户。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">网上银行服务</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">本行可不时通过任何互联网网站提供网上服务以便阁下向本行发出通信，从而操作客户账户、进行银行事务、投资、理财及任何性质的买卖及交易，以及获取本行不时提供的服务、产品、信息、报告、利益及优惠。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">本行有权不时决定并修订所提供的网上银行服务范围及类别，包括但不限于：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">随时增加、修改或减少网上银行服务；</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">制订或更改使用网上银行服务（或该服务任何部分）的限制，例如限制阁下使用网上银行服务每日进行任何买卖或交易的类别或任何买卖或交易的最高或最低交易额；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">指定及更改网上银行服务（或该服务任何部分）的正常服务时间，以及任何类别的网上银行服务、买卖或交易的每日截止时间。本行在适用的每日截止时间后收到阁下的任何指示，将被视为在下一营业日收到。本行可根据不同时区的市场运作时间，指定网上银行服务的营业日及每日截止时间。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">本行可酌情全面或就任何客户账户或一组客户账户暂停或终止网上银行服务，但在任何有关情况下，如阁下受到影响，本行须尽可能通知阁下，但在有合理必要保障本行合法权益的情况下，或根据任何法律或为了金融犯罪管理活动（包括但不限于为防止诈骗之目的），则可无须事先通知而暂停或终止。如阁下就网上银行服务的使用遭暂停、限制或终止，本行可结束任何只能通过网上银行服务使用的账户、产品或服务，阁下将不再收到电子账单，阁下也应从阁下的装置删除本行的移动银行应用程序（如适用）。</span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">本行可要求阁下就使用网上银行服务而指定或登记特定客户账户。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">阁下可向本行发出事先书面通知而随时终止使用网上银行服务及╱或终止对任何指定网上用户的授权。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">为使其涵义得以施行，本网上银行条款中所有需要在网上银行服务暂停或终止及／或阁下暂停或终止使用网上银行服务后维持有效的条文（包括但不限于第4条（<i>一般免责声明</i>）、第7条（<i>用户名及密码与安全</i>）、第9条（<i>信息及信息提供商</i>）、第11条（<i>本行的责任</i>）及第12条（<i>客户的责任</i>））于暂停或终止后仍具有十足效力及作用。尽管有此等暂停或终止，只要该等条文与阁下仍未履行或解除的任何义务或法律责任有关，阁下仍须继续受本网上银行条款约束。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">适用条款</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">使用网上银行服务进行任何买卖及交易，须受制于本网上银行条款、标准条款与条件及所有其他管辖有关客户账户、交易、买卖、服务、产品、信息、报告、利益或优惠的账户文件。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4</span>
          <span class="right">一般免责声明</span>
        </h3>
        <p class="level1">
          <span class="left">4.1</span>
          <span class="right">所有阁下可通过网上银行服务进行的交易（不时提供者）纯属执行性质并根据阁下自身的判断进行。本行并无责任评估或确保阁下通过网上银行服务买卖的产品或进行的交易是否合适。如本行以书面形式明示将本第4.1条摒除于适用范围之外，则本第4.1条并不适用。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.2</span>
          <span class="right">如果适用法律要求为其作出合适程度评估，则本行可能已向或为阁下作出的招揽或推荐仅在作出时有效，且本行无法确保有关产品是否持续合适。通过使用网上银行服务执行某个产品的交易，即表示阁下明白并接受，如果与阁下、该产品或其发行人或市况相关的情况有变，则该产品可能不再适合阁下，而阁下使用网上银行服务进行交易的风险概由阁下承担。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.3</span>
          <span class="right">阁下有责任自行通过阁下惯常的买卖渠道，独立决定买卖的市价及利率；并有责任在依赖任何信息及╱或报告或据此行事前，自行予以核证。阁下也有责任就使用网上银行服务、信息及报告以及进行的任何买卖及交易在所有适用法律下对阁下可能产生的影响，寻求法律、税务及其他方面的独立专业意见。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.4</span>
          <span class="right">本行通过互联网网站或为回复网上查询而提供的任何汇率、利率、买卖报价及其他价格和信息仅供参考，而不具任何约束力。尽管本行曾提供不同的利率、报价或信息，阁下一经确认接受本行就有关交易而提供的任何利率、报价及信息，即对阁下有约束力。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.5</span>
          <span class="right">阁下明白通过互联网或电子邮件使用服务有相关风险，包括但不限于未经授权使用、系统故障、电信服务及互联网延误及中断。网上银行服务的反应时间可能会因市场状况、系统性能、存取设备位置或其他因素而有所不同，而信息也可能在需求高峰、市场波动、系统升级、维护期间或因任何其他原因而遭限制或无法存取。阁下确认，通信中断或故障、软件设备故障、阁下或本行的计算机系统因任何原因发生故障，或未有更新阁下的装置浏览器或作业系统或移动银行应用程序均可能会妨碍或限制阁下使用网上银行服务的能力，如中断或故障超出本行的合理控制范围，本行不会对任何有关中断或故障承担任何责任。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.6</span>
          <span class="right">网上银行服务只在选定的司法管辖区提供。可使用网上银行服务存取的信息不拟在任何司法管辖区分发给有关分发将违反该司法管辖区的任何法例或规例的任何人士或供其使用。本行并不保证网上银行服务或其中的信息符合其他司法管辖区的法律或适合在其他司法管辖区使用。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.7</span>
          <span class="right">阁下确认，阁下可在或通过网上银行服务检视的任何产品或服务可能未经本行获发牌或授权提供有关产品或服务的任何司法管辖区以外或阁下的居住或所在地的任何中央银行、政府或监管机构注册或授权。因此，就阁下可在或通过网上银行服务检视的产品或服务而言，阁下可能不受本行获发牌或授权提供有关产品或服务的任何司法管辖区以外或阁下的居住地的证券法、银行法、保险法或其他相关法律保障。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.8</span>
          <span class="right">如阁下通过移动应用程序商店存取及／或下载本行的移动银行应用程序，阁下确认：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">本行对移动银行应用程序的效能负责，阁下应就与本行的移动银行应用程序相关的任何维护及支持事宜、内容事宜、故障或其他申索与本行联系；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">当阁下使用本行的移动银行应用程序时，就本网上银行条款而言，有关移动应用程序商店被视为第三方受益人，其可依赖本网上银行条款。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">5</span>
          <span class="right">指定网上用户</span>
        </h3>
        <p class="level1">
          <span class="left">5.1</span>
          <span class="right">阁下须：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">指定阁下授权的人士就客户账户使用网上银行服务，并按本行不时指定的方式及形式限制各有关人士所获授权范围；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">就指定网上用户的邮寄地址及╱或电邮地址的任何更改知会本行；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">确保所有指定网上用户遵守本网上银行条款及标准条款与条件。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">5.2</span>
          <span class="right">阁下及各指定网上用户须提供本行为提供网上银行服务而不时合理要求的信息。
          </span>
        </p>
        <p class="level1">
          <span class="left">5.3</span>
          <span class="right">阁下须就使用网上银行服务负责，有责任采取适当措施以监控网上银行服务的使用情况，并负责指定和更换指定网上用户，也须采取恰当的安全措施，以免网上银行服务被未经授权人士使用或被用作未经授权用途，包括但不限于本网上银行条款第7条（<i>用户名及密码</i>）中载明的措施。
          </span>
        </p>
        <p class="level1">
          <span class="left">5.4</span>
          <span class="right">阁下明白，如阁下已委任指定网上用户使用任何网上银行服务，该指定网上用户为及代表阁下接受并同意本网上银行条款，而阁下也同意受指定网上用户的有关接受及／或同意约束。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">6</span>
          <span class="right">使用网上银行服务</span>
        </h3>
        <p class="level1">
          <span class="left">6.1</span>
          <span class="right">网上银行服务只供网上银行服务订用客户使用。</span>
        </p>
        <p class="level1">
          <span class="left">6.2</span>
          <span class="right">阁下不得进行或试图进行以下任何活动：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">反编译、反向工程、翻译、转换、改编、改变、修改、提升、增添、删除或以任何方式干扰或进入网上银行服务的任何部分或任何互联网网站或其中组成的任何软件；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">使用或在知情下容许任何其他人士使用网上银行服务、信息及╱或报告作任何非法目的或活动；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">移除或试图移除网上银行服务中的任何标题或商标、版权或限制权利通知；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">使用任何自动化工具（即机器人）存取网上银行服务或执行活动；及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">允许使用任何安全测试工具以提供、扫描或试图渗透或评估网上银行服务的安全性，
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">阁下如察觉任何有关活动，须尽快通知本行。</span>
        </p>
        <p class="level1">
          <span class="left">6.3</span>
          <span class="right">阁下不得上传或散布包含病毒或其他恶意程式码的档案到网上银行服务，也不得作出任何其他行为破坏或试图破坏用于提供网上银行服务的系统及网络。一旦发现网上银行服务实际或怀疑遭病毒或其他恶意程式码入侵，阁下必须立即通知本行并与本行合作以减低有关病毒或恶意程式码的影响。
          </span>
        </p>
        <p class="level1">
          <span class="left">6.4 </span>
          <span class="right">阁下确认经由互联网传送的信息或通信，可能会出现时间延迟。
          </span>
        </p>
        <p class="level1">
          <span class="left">6.5</span>
          <span class="right">阁下确认：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">阁下就使用网上银行服务提供的任何信息可能会向本行的业务部门、任何集团办事处及／或可能位于其他司法管辖区的授权服务提供商披露或供其存取；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">阁下使用网上银行服务可能会受到本行、任何集团办事处及／或授权服务提供商监控，而本行可能出于内部业务考虑或任何适用法律而收集及使用由此产生的任何信息，
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            一切均按照标准条款与条件的条款进行，阁下并且同意有关披露、监控及／或使用。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">7</span>
          <span class="right">用户名及密码和安全</span>
        </h3>
        <p class="level1">
          <span class="left">7.1</span>
          <span class="right">各指定网上用户须按照本行不时规定的程序指定其用户名及密码。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.2</span>
          <span class="right">各指定网上用户可随时更改用户名及密码，但任何更改须在本行接受后方为有效。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.3</span>
          <span class="right">本行可行使绝对酌情权要求阁下使用安全码，以便使用网上银行服务。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.4</span>
          <span class="right">各指定网上用户须真诚行事，在合理谨慎尽责的原则下采取一切合理措施，将用户名、密码及安全码保密以防盗用情况发生。无论任何时间或情况，指定网上用户均不得向任何其他人士披露用户名、密码及╱或安全码。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.5</span>
          <span class="right">如果指定网上用户的用户名、密码及╱或安全码不慎或在未经授权的情况下被泄露予其他人士知悉，阁下须负全责，并须承担指定网上用户的用户名、密码、安全码或移动装置被未经授权人士使用或被用作未经授权用途的风险。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.6</span>
          <span class="right">
            阁下如在移动银行应用程序中激活生物识别凭据使用功能（例如指纹认证或人脸识别），并允许使用有关生物识别凭据登录移动银行应用程序或本行可能不时支持的任何其他网上银行服务（只面向兼容装置），便须确保只在装置上登记阁下自己的生物识别凭据。阁下确认并同意：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">本行无法控制移动装置的技术、可用性、性能、验证方法、安全性及可靠性，因此概不就任何未经授权人士通过生物识别凭据登录网上银行服务的情况承担责任；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">阁下不得采取任何行动以停用阁下装置提供的任何功能或同意装置的任何设置，从而危及使用生物识别凭据进行验证的安全性（例如停用人脸识别的“注视感知功能”）。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">7.7</span>
          <span class="right">阁下或有关指定网上用户如发现或怀疑用户名、密码及╱或安全码被披露给未经授权人士，或网上银行服务被未经授权使用，须尽快亲身通知本行，或根据本行不时指定的电话号码致电通知本行。本行可要求阁下或有关指定网上用户以书面形式确认所提供的信息。在本行实际收到该等通知前，阁下须就任何及所有因未经授权人士使用网上银行服务或网上银行服务被用作未经授权用途负责。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">8</span>
          <span class="right">客户指示</span>
        </h3>
        <p class="level1">
          <span class="left">8.1</span>
          <span class="right">各指定网上用户须先输入其用户名、密码、安全码及本行不时规定的任何其他标识符，方可登录互联网网站使用网上银行服务及代表阁下在网上向本行发出通信。本行获授权按各指定网上用户的通信行事。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.2</span>
          <span class="right">指定网上用户须在本行不时指定的网址及联系点向本行发出通信。除非本行另行明确同意，否则指定网上用户不得向本行的个别主管人员发出任何通信。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.3</span>
          <span class="right">通信须按本行不时指定的方式发出及在本行实际收到后，始视为经由本行收妥。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.4</span>
          <span class="right">任何通信一经发出，如未得本行同意，概不得废除或撤回。除核对有关指定网上用户的用户名、密码、安全码及其他有关标识符（如有）外，本行并无责任核证作出任何通信人士的身分或授权，或此等通信的真实性。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.5</span>
          <span class="right">本行只会执行本行认为合理可行的通信，并将遵照正常业务惯例及程序行事。如本行得悉或怀疑存在与使用网上银行服务相关的安全漏洞或其他可疑情况，本行有权延迟或拒绝按通信行事。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.6</span>
          <span class="right">所有经由本行按照通信代阁下办理的交易均对阁下具有约束力。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.7</span>
          <span class="right">若阁下选择并同意，本行可在网上向阁下发出已收讫通信及╱或已执行交易或买卖的通知或确认。阁下同意查核有关通知或确认以确保其准确及完整。如果阁下未有收到通知或确认，或阁下相信有关通知或确认中的任何信息为不准确或不完整，阁下将向本行查询。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.8</span>
          <span class="right">互联网网站上提供有关任何账户、交易或买卖的信息均仅供参考。本行对该等账户、交易或买卖纪录，如无欺诈或明显错误情况，均为该等纪录的证据。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">9</span>
          <span class="right">信息提供商</span>
        </h3>
        <p class="level1">
          <span class="left">9.1</span>
          <span class="right">信息提供商可就其提供的任何信息不时制订有关的条款及细则，并将事先通知该等条款及细则的生效日期。阁下在该等条款及细则生效当日或之后使用该等信息，即表示阁下接纳该等条款及细则。
          </span>
        </p>
        <p class="level1">
          <span class="left">9.2</span>
          <span class="right">信息及报告只供参考。</span>
        </p>
        <p class="level1">
          <span class="left">9.3</span>
          <span class="right">除在适用法律规定或任何有关文件订明的范围内，本行及任何信息提供商对任何信息或报告的次序、准确性、真实性、可靠性、充足性、及时性或完整性，或其是否适宜作任何用途，概不作出保证、声明或担保。阁下同意独立评估及核实任何信息或报告中的有关事宜，除在适用法律规定或任何有关文件订明的范围内，本行及任何信息提供商不会就阁下或任何其他人士因依赖信息或报告而承担任何法律责任（不论是侵权或合约或其他责任）。然而，本条款并不排除本行或信息提供商根据适用法律就行为、声明或陈述对阁下或任何其他人士可能承担的任何责任。
          </span>
        </p>
        <p class="level1">
          <span class="left">9.4</span>
          <span class="right">信息将按其由信息提供商所提供的形式提供。本行不会就任何其他信息提供商提供的信息作出批注或表示任何意见，也无责任对有关信息进行检查或核证。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">10</span>
          <span class="right">保密信息</span>
        </h3>
        <p class="level1">
          <span class="left">10.1</span>
          <span class="right">保密信息属本行及有关信息提供商的商业秘密、机密及专有财产。
          </span>
        </p>
        <p class="level1">
          <span class="left">10.2</span>
          <span class="right">除本网上银行条款或本行明文许可外，阁下不得进行或试图进行以下任何活动：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">将任何保密信息向任何其他人士出售、转移、披露、出让、转达、出租、再授权、分享、借出、分派、传输、广播、电缆广播、传阅、复印，或以其他方式以任何形式或任何手段提供或散布，或将任何保密信息作商业用途；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">下载或复制任何保密信息作阁下个人参考及使用以外的其他用途；及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">将保密信息上显示的任何所有权标记（包括但不限于任何商标或版权通告）以任何方式移除、涂掉、擦去、迁移或更改。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.3</span>
          <span class="right">在下列情况下，披露限制并不适用于下列任何保密信息：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">法律强制规定作出披露，但只限于法律规定的范围，及只在阁下已经向本行发出书面通知，说明要求披露的保密信息；或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">本行已书面明确同意披露的保密信息。</span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.4</span>
          <span class="right">有关保密信息的一切权利、所有权及权益，以及任何及所有有关版权、专利权、商标、服务标记、专有财产、商业秘密及专用作品，均属本行及╱或有关信息提供商的独家财产。此等权利、所有权或权益（除根据本网上银行条款有权使用信息及报告外）均没有转移或转让予阁下。阁下不得作出显示阁下拥有任何此等权利、所有权或权益的任何声明或作为。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">11</span>
          <span class="right">本行的责任</span>
        </h3>
        <p class="level1">
          <span class="left">11.1</span>
          <span class="right">本行将根据不时适用于本行的任何法律、通告、行为守则及现行市场惯例，采取合理可行的步骤，以确保与网上银行服务有关的系统已装置足够的安全设施，并在系统运作时，对有关风险予以监控。
          </span>
        </p>
        <p class="level1">
          <span class="left">11.2</span>
          <span class="right">本行、任何集团办事处或任何信息提供商，概不保证或声明网上银行服务、信息及报告不含有任何对阁下硬件、软件或设备造成不利影响的病毒或其他破坏性程序。
          </span>
        </p>
        <p class="level1">
          <span class="left">11.3</span>
          <span class="right">除非引用下文第12.2条，或由于本行、任何集团办事处或本行╱其各自的主管人员或雇员的欺诈、疏忽或故意失责所引致，本行或任何集团办事处概不就以下事项所引起或与之有关的后果，而向阁下或任何其他人士承担任何责任：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">由于阁下或任何其他不论是否获授权的人士使用网上银行服务及╱或取得任何信息；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">由于在提供网上银行服务、传送与网上银行服务有关的信息或通信或与互联网网站连线时因任何本行的作为、不作为或本行所能合理控制范围以外的情况，包括但不限于通信网络失灵、提供服务的第三方的作为或不作为、机械故障、电力故障、失灵、操作故障，或设备、装置或设施不足，或因任何法律、守则、指令、监管指引或政府命令（不论是否具法律效力）而出现任何干扰、截取、中断、延误、损失、无法提供信息、毁坏或其他故障（包括但不限于任何干扰、截取、中断、延误或无法显示安全码）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">通过或在任何通信网络提供商的系统、设备或仪器传送及╱或储存任何与阁下及╱或网上银行服务有关的信息及╱或数据。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">11.4</span>
          <span class="right">本行、任何集团办事处或任何信息提供商，概无需对阁下或任何其他人士就任何偶发性、间接、特殊、相应或惩罚性损害赔偿负责，包括但不限于有关使用、收入、利润或储蓄方面的任何损失责任。本条款不适用于本行／彼等欺诈、疏忽或故意失责的情况。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">12</span>
          <span class="right">客户的责任</span>
        </h3>
        <p class="level1">
          <span class="left">12.1</span>
          <span class="right">除非引用下文第12.2条或除非本行存在欺诈、疏忽或故意失责的行为，否则因阁下或任何其他人士（不论是否获得授权）使用网上银行服务及／或因使用网上银行服务取得任何信息或报告或任何其他信息而引起或与之有关的后果，概由阁下全部承担。
          </span>
        </p>
        <p class="level1">
          <span class="left">12.2</span>
          <span class="right">根据第7条（<i>用户名及密码</i>）下阁下责任的限制，及如果本行合理地认为阁下并无严重疏忽或欺诈，则阁下无需就使用网上银行服务进行未经授权交易而产生的损失或资金错置负责。
          </span>
        </p>
        <p class="level1">
          <span class="left">12.3</span>
          <span class="right">除非引用上文第12.2条，或由于本行、任何集团办事处、任何信息提供商或本行╱其各自的主管人员或雇员的欺诈、疏忽或故意失责所致，否则阁下须弥偿本行、任何集团办事处、任何信息提供商及其各自的主管人员及雇员因提供网上银行服务、信息及╱或报告或行使或保存本行在本网上银行条款及标准条款与条件赋予的权力及权利或因提起或招致的任何法律行动或诉讼所合理及适当地招致的一切法律责任、索偿、申索、损失、损害赔偿、讼费、任何形式的费用及开支（包括但不限于按全数赔偿原则支付法律费用）。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">13</span>
          <span class="right">修订</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">本行可不时对本网上银行条款进行修订及／或增补。网上银行条款的任何修订及╱或增补在本行通过互联网网站刊登或按照本行认为合适的其他方式向阁下发出事先合理通知后，即告生效。如果阁下在修订生效当日或之后继续维持或使用网上银行服务，则有关修订将对阁下具有约束力。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">14</span>
          <span class="right">通知及通信形式</span>
        </h3>
        <p class="level1">
          <span class="left">14.1</span>
          <span class="right">本行可不时根据本网上银行条款就各类通知订明其通知形式（不论书面通知或其他形式）及通信模式。
          </span>
        </p>
        <p class="level1">
          <span class="left">14.2</span>
          <span class="right">以电邮发出的通信在传送至阁下最近以书面形式通知本行的电邮邮址后，即视作阁下已收悉。
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">B.	汇丰私人银行视野及研究的条款及细则</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">本条款及细则的简介</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">阁下使用由本行提供的汇丰私人银行视野及研究服务（“<b>视野及研究服务</b>”）受以下条款规限：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">本汇丰私人银行视野及研究的条款及细则（“<b>视野及研究条款</b>”）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">网上银行服务的条款及细则（“<b>网上银行条款</b>”）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">本行的标准条款与条件（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Simplified%20Chinese%20version.pdf">此</a>查阅），
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">以上条款统称为“<b>条款</b>”。如本视野及研究条款与网上银行条款或标准条款与条件有任何冲突，则须按以上次序决定优先顺序（从高到低）。本行可在向阁下发出合理通知后不时修订视野及研究条款。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">视野及研究条款中使用但未有另行界定的词汇与网上银行条款及标准条款与条件所赋予的涵义相同。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">为免生疑问，网上银行条款中界定的“网上银行服务”及“互联网网站”将分别包括视野及研究服务及视野及研究网站（“<b>视野及研究</b>”）。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">存取及使用刊物</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">本行可向阁下及／或阁下的指定网上用户提供视野及研究的存取权限（各自称为“<b>用户</b>”），这是让用户（其中包括）存取以下若干事项的电子平台：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">环球私人银行宏观金融市场评论（“<b>环球私银宏观报告</b>”）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">由本行的环球市场部门下属的环球研究部（“<b>汇丰环球研究</b>”）提供的汇丰环球研究报告（“<b>汇丰研究报告</b>”）及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">非汇丰的第三方刊物（“<b>第三方刊物</b>”）
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">（本文统称为“<b>刊物</b>”）。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">刊物可能会讨论多个资产类别的金融市场及投资产品，并可能包括涵盖特定金融工具及发行人的金融研究材料。各类用户可存取的刊物种类将由本行酌情决定。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">只有已向本行提供详细信息并已获本行批准为用户的人士方可使用视野及研究服务。阁下有责任确保阁下指定的各用户均已阅读并明白条款。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">本行将让各用户通过网上银行服务经由视野及研究存取刊物。用户也可通过电邮从其客户关系经理或投资顾问收取刊物，或使用视野及研究的订阅提示功能。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">刊物只提供英文版本予各用户。阁下提名任何用户，即确认各用户均具备充分的英语水平，能够完全明白刊物的内容及当中可能包含的任何重要免责声明或风险警告。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">用户不得尝试直接联系任何刊物的发行人，包括但不限于任何汇丰环球研究分析师或第三方，或其任何代表或雇员。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">金融知识、经验及阅读能力</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">刊物可能涵盖不同的主题及产品类别，包括但不限于气候变化、商品、可转换证券、高等级信用、高收益信用、信用策略、货币、经济、股票策略、固定收益、利率、结构性及资产抵押证券、股权及结构性产品。刊物会涉及市场、经济及财务数据、概念及比率，以及其他技术信息或概念，各用户需要具备相当程度的金融知识、经验及阅读能力。阁下提名任何用户，即确认各用户均具备足分的金融知识、经验及阅读能力以阅读及明白刊物及其限制，特别是下文第6.1及6.2条所述者，并将在评估通过视野及研究定期提供的材料时行使独立判断。在理解任何刊物时遇到困难的用户应联系负责客户账户的客户关系经理或投资顾问。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4</span>
          <span class="right">限制存取、安全性及系统可用性</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">刊物并非给予也不拟分发予任何身为有关分发、出版、提供或使用会违反法例或规例的任何司法管辖区的公民或居民或位于有关司法管辖区的人士或实体的用户或供其使用。本行将根据各用户居住记录的司法管辖区以及本行对各有关司法管辖区适用的法例及监管规定的评估，向各用户提供刊物的存取权。各用户确认其并非任何存取刊物可能会违反当地法例或规例的国家的居民。各用户均有责任在其改变居籍的司法管辖区时立即通知本行。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">5</span>
          <span class="right">知识产权</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">刊物只供各用户个人专用。刊物仍属本行、本行的部门（不包括私人银行部门）、任何集团办事处的其他成员（例如汇丰环球研究）或制作及出版刊物的第三方的财产，未经本行明确书面同意，用户并无获得任何权利以任何方式或形式复制或分发全部或部分刊物或其内容，并且只有在明确承诺确保作允许用途时充分注明来源的情况下方可复制或分发刊物。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">6</span>
          <span class="right">关于刊物内容及用途的重要限制及警告</span>
        </h3>
        <p class="level1">
          <span class="left">6.1</span>
          <span class="right">并非投资建议或推荐／不就适当性或合适性作出声明
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">刊物只供各用户参考。</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">刊物不构成也不应解释为本行向阁下或任何用户发出任何形式的法律、税务或投资建议或招揽及／或推荐，也非本行向阁下或任何用户发出认购、购买、赎回或出售任何金融工具的要约或邀请，或进行与有关工具相关的任何交易。刊物的内容可能不适合阁下的财务状况、投资经验及投资目标，本行不会就刊物呈列的任何金融工具或投资策略对阁下的适当性或合适性作出任何声明。用户应根据阁下的情况自行判断，以决定特定产品是否适合阁下。在适用的情况下，用户应考虑听取专业建议，包括有关阁下的法律、税务或会计状况的建议。用户如希望进行投资产品交易，应联系阁下在本行的客户关系经理或投资顾问。用户不应单纯根据任何刊物的内容作出任何投资决定。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">6.2</span>
          <span class="right">关于刊物内容的重要警告</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">任何刊物所包含的过往表现数据不应视为当前或未来表现的指标或保证，概不就当前或未来表现作出任何明示或暗示的声明或保证。表现数据可能并无计及发行及赎回（或购买及出售）金融工具所产生的费用、佣金及其他开支。刊物所述任何金融工具的价格、价值及收入可跌可升，并且不能排除阁下可能希望投资或已经投资于该等工具的任何本金全部损失的风险。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">任何刊物的内容只应视为截至首次刊发当日的最新消息，而不论用户可能存取有关刊物的日期。这包括刊物所包含的任何意见或估计，该等意见或估计仅反映在原刊发日期的判断。本行可能会定期修订及更新刊物，并保留酌情决定删除或修改刊物任何内容的权利，而不作事先通知。虽然本行将尝试向用户提供刊物的适时更新版本，但本行并无责任如此行事。向用户提供的任何更新将通过视野及研究提供，各用户有责任确保其及时得悉有关更新。刊物可能与过往刊物的内容不一致或得出不同的结论。所有意见均反映在原刊发日期所作的判断。集团办事处并无责任确保过往刊物的收件人得悉已发布刊物。用户有责任按自身需要检查是否有更新版本。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">各用户确认，汇丰研究报告及第三方刊物未经本行独立验证。本行、任何集团办事处或本行／其服务提供商概不就刊物所载信息的准确性或完整性承担任何责任，本行明确声明不就汇丰研究报告及第三方刊物中表达的意见、声明或保证承担任何及所有责任。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">本行可能曾发布其他内容／材料，与第三方刊物或汇丰研究报告所呈列的信息相比可能存在若干差异、看似不一致或得出不同结论。这反映该内容／材料的作者的不同假设、观点及分析方法，本行并无责任确保提请各用户注意任何差异或不一致情况。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">本行、本行的部门（不包括私人银行部门）、本行的母公司、附属公司或其他联属公司，以及其各自的董事、经理及雇员：
              </span>
            </p>
            <ul class="list3">
              <li class="item3">
                <p>
                  <span class="left">(i)</span>
                  <span class="right">过去、现在或未来可能投资于刊物所述的证券或金融工具，并且可能不时增加或出售有关投资；
                  </span>
                </p>
              </li>
              <li class="item3">
                <p>
                  <span class="left">(ii)</span>
                  <span class="right">过去、现在或未来可能充当该等证券或金融工具的庄家；
                  </span>
                </p>
              </li>
              <li class="item3">
                <p>
                  <span class="left">(iii)</span>
                  <span class="right">过去、现在或未来可能与刊物所述的发行人有关系，并且可能已经或将会提供金融服务，例如企业融资、投资银行或包销服务；及／或
                  </span>
                </p>
              </li>
              <li class="item3">
                <p>
                  <span class="left">(iv)</span>
                  <span class="right">过去、现在或未来可能出任刊物所述发行人的执行董事会成员。
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li class="item2">
            <p>
              <span class="left"></span>
              <span class="right">汇丰环球研究已制定程序识别及管理与其研究业务相关的任何潜在利益冲突。汇丰环球研究的分析师及其他参与编制及发布汇丰研究报告的员工参与及设有独立于本行投资银行业务的管理汇报机制。本行的投资银行业务与汇丰环球研究之间设有资讯屏障以及相关政策和程序，确保以适当的方式处理任何机密及／或价格敏感信息。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(F)</span>
              <span class="right">刊物所述的金融工具面对货币、市场、流动性及其他风险，可能对其价值、价格或收入（如有）产生正面或负面影响。该等风险在与特定金融工具相关的文件中有更详尽的描述，在进行任何投资前应充分考虑有关风险。刊物可能载有特定的限制／警告条文，各用户应仔细阅读有关条文连同任何刊物所载有的任何免责声明。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">6.3</span>
          <span class="right">超出范围的报告</span>
        </p>
        <p class="level1">
          <span class="left"></span>
          <span class="right">汇丰环球研究可能编制涵盖汇丰环球研究资产配置及汇丰环球研究模型投资组合但与本行私人银行的观点不一致的报告。在可行的范围内，该等报告一般不会在视野及研究刊载。然而，该等报告的内容及提述可能会出现在向私人银行客户提供的其他汇丰研究报告中。如对视野及研究所提供的任何研究报告的内容有疑问，用户应首先联系阁下的客户关系经理或投资顾问。
          </span>
        </p>
        <p class="level1">
          <span class="left">6.4</span>
          <span class="right">进一步披露</span>
        </p>
        <p class="level1">
          <span class="left"></span>
          <span class="right">有关使用视野及研究服务及刊物的进一步披露及警告，请参考各刊物所包含的“重要提示及免责声明”连结。各用户同意在使用视野及研究前阅读该等披露及警告以及其不时的更新信息。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">7</span>
          <span class="right">终止／暂停存取及修订</span>
        </h3>
        <p class="level1">
          <span class="left">7.1</span>
          <span class="right">在不损害本行根据网上银行条款终止或暂停任何服务的权利的情况下，如本行酌情判断任何条款已遭违反或因任何其他原因而认为可能需要终止或暂停任何用户存取视野及研究服务，则本行可暂停或终止有关服务。尤其是，在不限制前述规定的一般性的情况下，如任何用户的存取可能违反任何法律，则本行可终止或暂停有关存取。本行也可在向阁下发出合理通知后不时修订视野及研究条款。
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">C.	推播通知服务的条款及细则</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">本条款及细则的简介</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">阁下使用由本行提供的推播通知服务（定义见下文第1.2条）受以下条款规限：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">本推播通知服务的条款及细则（“<b>推播通知条款</b>”）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">网上银行服务的条款及细则（“<b>网上银行条款</b>”）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">本行的标准条款与条件（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Simplified%20Chinese%20version.pdf">此</a>查阅），
              </span>
            </p>
          </li>
        </ol>

        <p class="level1">
          <span class="left"></span>
          <span class="right">以上条款统称为“<b>条款</b>”。如本推播通知条款与网上银行条款及标准条款与条件有任何冲突，则须按以上次序决定优先顺序（从高到低）。本行可在向阁下发出合理通知后不时修订推播通知条款。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">在本推播通知条款中：</span>
        </p>
        <ol class="list">
          <li class="item">
            <p>
              <span class="right">“<b>信息中心</b>”指本行的移动银行应用程序中储存所有推播通知的功能。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>推播通知</b>”指通过推播通知服务发送至本行移动银行应用程序的信息中心的信息。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">“<b>推播通知服务</b>”指本行可根据本推播通知条款提供的服务。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">本推播通知条款中使用但未有另行界定的词汇与网上银行条款所赋予的涵义相同。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.4</span>
          <span class="right">为免生疑问，网上银行条款中界定的“移动银行应用程序服务”将包括推播通知服务。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">推播通知服务的范围</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">推播通知服务仅通过本行的移动银行应用程序或不时通知阁下的渠道提供。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">阁下可通过适当的电信设备使用推播通知服务以电子方式接收资讯及通信。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">通过推播通知服务提供的资讯及通信类型将由本行酌情决定。为免生疑问，推播通知服务与本行标准条款与条件第九节所界定的电子账单服务无关。推播通知并非也不应解释为电子账单。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">本行可能在未有事先通知的情况下不时指定或更改推播通知服务的范围和范畴及其功能（或将其终止）。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">本行可能在未有事先通知的情况下停止向阁下提供推播通知服务（或更改向阁下提供的推播通知服务范围），以遵循适用法例或规例的限制或与该等限制相关的内部政策而无需向阁下负上法律责任。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">开启及使用推播通知服务</span>
        </h3>
        <p class="level1">
          <span class="left">3.1</span>
          <span class="right">阁下将通过本行的移动银行应用程序登记推播通知服务。当阁下登记推播通知服务时，阁下需要告知本行欲接收的推播通知种类。阁下可在登记后以本行接纳的方式或形式不时更改此信息。
          </span>
        </p>
        <p class="level1">
          <span class="left">3.2</span>
          <span class="right">本行可能会限制阁下用来接收推播通知的移动装置数目及类型。本行可能会视乎客户类型或移动装置的类型或型号施加不同限制。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4 </span>
          <span class="right">推播通知</span>
        </h3>
        <p class="level1">
          <span class="left">4.1</span>
          <span class="right">阁下应确保阁下的移动装置及相关服务能够接收推播通知。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.2</span>
          <span class="right">本行的推播通知只会发送一次，不予重发。如果阁下已删除本行发送的推播通知并需要查阅有关资讯，请与本行联系。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.3</span>
          <span class="right">即使阁下收到声称由本行通过推播通知服务发出的要求，要阁下提供密码或账户或保安详情，请勿回复，因为本行绝不会作出该要求。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.4</span>
          <span class="right">如果阁下已更换阁下的移动装置，阁下需要重新安装本行的移动银行应用程序。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.5</span>
          <span class="right">本行发送的推播通知均为单向形式。阁下不能在信息中心内对推播通知作出回应。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.6</span>
          <span class="right">视乎移动装置的类型，推播通知服务依赖由Apple Inc.（“<b>Apple</b>”）或Google LLC（“<b>Google</b>”）提供的相关通信服务。因Apple或Google服务的任何故障或不足而导致推播通知传送延迟或失败均不在本行的控制范围之内。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.7</span>
          <span class="right">阁下需要连接至移动数据网络或WiFi无线网络以接收推播通知。如欲在海外接收推播通知信息，请检查是否已启用数据漫游。然而，请注意，如果阁下身在特定国家或地区，或使用某些类型的移动装置，则未必能向阁下提供推播通知服务。阁下需自行承担因本地或国际网络连接而产生的任何费用及收费。
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">D.	电子签署的条款及细则</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">本条款及细则的简介</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">阁下使用电子签署（本行的电子签署服务）存取及以电子方式签署账户文件受以下条款规限：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">本电子签署服务的条款及细则（“<b>电子签署条款</b>”）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">网上银行服务的条款及细则（“<b>网上银行条款</b>”）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">本行的标准条款与条件（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Simplified%20Chinese%20version.pdf">此</a>查阅），
              </span>
            </p>
          </li>
        </ol>

        <p class="level1">
          <span class="left"></span>
          <span class="right">以上条款统称为“<b>条款</b>”。如本电子签署条款与网上银行条款及标准条款与条件有任何冲突，则须按以上次序决定优先顺序（从高到低）。本行可在向阁下发出合理通知后不时修订电子签署条款。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">在本电子签署条款中，对“<b>Adobe Sign</b>”的提述指由第三方平台运营商Adobe Systems Software Ireland Limited（注册办事处位于4-6 Riverwalk, Citywest Business Campus, Dublin 24, Ireland，“<b>Adobe</b>”）通过Adobe Sign平台提供的电子签署产品及服务。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">本电子签署条款中使用但未有另行界定的词汇与网上银行条款所赋予的涵义相同。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.4</span>
          <span class="right">为免生疑问，网上银行条款中界定的“网上银行服务”将包括本D部的电子签署。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">Adobe条款及细则</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">本互联网网站可能不时提示阁下以电子方式签署文件。如阁下选择以电子方式签署文件，阁下将被带往Adobe Sign平台，该平台受Adobe自身的特定条款及细则、隐私及cookie声明（包括免责声明）约束，并将构成阁下与Adobe之间的独立法律协议（“<b>Adobe条款及细则</b>”）。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">如任何条款及细则之间有任何冲突，则须按以下次序决定优先顺序（从高到低）：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">Adobe条款及细则；及</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">本电子签署条款。</span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">用户确认</span>
        </h3>
        <p class="level1">
          <span class="left">3.1</span>
          <span class="right">Adobe Sign平台上以电子方式签署文件，即表示阁下及／或阁下的指定网上用户（各自称为“<b>用户</b>”）均为自身及代表阁下确认并同意：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">阁下授权并同意本行使用Adobe Sign平台以电子形式收集阁下的签名并将阁下的签名连结到文件 — 该等文件连同所收集及连结的签名称为“<b>电子文件</b>”；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">本行使用第三方服务提供商Adobe的服务来收集阁下的签名并将阁下的签名连结到电子文件。当本行将阁下重新导向到Adobe Sign平台时，阁下将可查阅可以电子方式签署的文件。到访Adobe Sign平台，即表示阁下直接向Adobe表示同意阁下使用Adobe Sign平台所适用的Adobe条款及细则。本行或任何集团办事处概不对Adobe Sign平台的运营或其服务及功能负责。阁下须自行承担使用Adobe Sign平台的风险；本行或任何集团办事处概不对与阁下到访及使用Adobe Sign平台有关的任何损害或以任何其他方式负责或承担法律责任；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">本互联网网站上的任何资讯或阁下将被重新导向到Adobe Sign平台概不表示或暗示本行或任何集团办事处对Adobe或其建议、意见、信息、产品或服务的合适性给予认可、发出批准或负上责任；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">阁下将在签署前阅读整份电子文件，且阁下在每份电子文件上的签名是由阁下附加，确认阁下有意受有关电子文件约束。阁下并无也不会允许任何其他人士协助阁下将阁下的签名附加到任何电子文件；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">阁下在每份电子文件上的签名均须清晰准确地反映在有关电子文件上，而以该电子形式收集、接收及／或储存的签名将在各种用途上视为等同于阁下在列印本上的签名，且须经本行核实该签名符合本行的内部规定后方可作实；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(F)</span>
              <span class="right">由本行或代表本行保存阁下以电子形式附加签名的电子文件将视为有效、准确及真确，并犹如该等电子文件是以书面形式由阁下在列印本上签署一样具有相同效力；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(G)</span>
              <span class="right">除非法律另有规定，否则本行有权视任何电子文件所收集的签名为有效及可予强制执行；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(H)</span>
              <span class="right">任何电子文件，任何形式的活动或交易日志、计算机或电子记录、计算机打印文件的任何凭证，或任何其他形式的计算机或电子数据或信息储存或系统（统称“<b>电子记录</b>”）即为有关信息的记录，而阁下载于电子文件及电子记录中有关任何事宜的指示、同意、确认及协定可在任何法律程序中用作证明其内容的凭据；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(I)</span>
              <span class="right">阁下如欲索取电子文件副本，可联系阁下的客户关系经理；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(J)</span>
              <span class="right">如不同意或对电子文件的内容有任何疑问，阁下可在按下最后的“点击签署”按钮前随时关闭浏览器并退出签署程序。所有电子文件仅在阁下点击最后的“点击签署”按钮（出现在所有电子文件的末端）时方会视为已签立；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(K)</span>
              <span class="right">阁下签立电子文件并不代表本行接纳阁下的指示。电子文件仍须经本行核实及批准，并只有在经本行批准后方告生效。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">E.	可疑委托警示模式的条款及细则</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">本条款及细则的介简</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">本条款与阁下使用可疑委托警示模式（“<b>警示模式</b>”）有关，警示模式为在使用移动银行应用程序时向阁下发出潜在可疑转账交易警示的服务（“<b>服务</b>”），并只适用于在香港开立的账户。使用服务受以下条款规限：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">与警示模式有关的本条款及细则（“<b>警示模式条款</b>”）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">网上银行服务的条款及细则（“<b>网上银行条款</b>”）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">本行的标准条款与条件（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Simplified%20Chinese%20version.pdf">此</a>查阅），</span>
            </p>
          </li>
        </ol>

        <p class="level1">
          <span class="left"></span>
          <span class="right">以上条款统称为“<b>条款</b>”。如本警示模式条款与网上银行条款及标准条款与条件有任何冲突，则须按以上次序决定优先顺序（从高到低）。警示模式条款将于服务在移动银行应用程序内可供使用当日生效。本行可在向阁下发出合理通知后不时修订警示模式条款。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">在本警示模式条款中：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">“警示”指对一项转账交易或相关的收款人或收款人账户可能涉及欺诈或诈骗的警告信息；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">“防诈数据库”包括由香港警务处或香港其他执法机关、政府机构或监管机构运作或管理的任何防诈骗搜索引擎及／或防欺骗数据库，不论其是否可供一般公众人士或指定实体或组织使用（包括但不限于香港警务处的防骗视伏器）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">“转账交易”指阁下通过本行经移动银行应用程序并使用任何本行不时决定的货币进行的资金转移，不论收款人账户是否在本行开立；如文义要求或允许，包括阁下向本行发出进行转账交易的指示。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">阁下在本警示模式条款生效日期当日或之后作出任何转账交易，即阁下确认阁下已接受本警示模式条款并会受其约束。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.4</span>
          <span class="right">警示旨在帮助阁下在作出转账交易时保持警觉提防欺诈、诈骗及欺骗。警示并不替代或免除阁下保障自身的利益、资金及资产免受欺诈或其他非法活动损害的责任。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">本行的角色、责任及责任限制</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">本行：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">无法控制防诈数据库的管理、运作或其他方面；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">单靠防诈数据库不时提供的信息来编制警示；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">不会就防诈数据库并无提供信息的收款人、收款人账户或交易编制警示。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">本行不会保证亦不能保证任何防诈数据库提供的信息是否完整、真实、准确及最新，也不会保证亦不能保证阁下没有收到警示的转账交易不涉欺诈，或阁下收到警示的转账交易必属欺诈。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">本行可按其认为适当的方式编制及传送警示。本行可不时考虑本行的需要以及相关人士的反馈、意见、指引或建议，酌情决定及／或更改警示的内容、传送警示的渠道或方式，及／或转账交易的货币。相关人士可包括但不限于香港的执法机关或其他政府机构、监管机构或行业公会。本行可通过电子或其他方式向阁下传送警示。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">本行无须负责阁下或任何其他人士因任何防诈数据库提供或未有提供任何信息，或因其延误、无法使用、中断、故障或错误而可能引致或蒙受的任何种类的损失、损害或开支，或本行可合理控制以外的情况而可能引致或蒙受的任何种类的损失、损害或开支。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">本行无须负责阁下或任何其他人士有关或因警示（或其延误或无法传送），或有关或因处理、执行或取消警示（或因其延误或无法传送）所涉的转账交易，而可能引致或蒙受的任何种类的损失、损害或开支，除非所引致或蒙受的任何损失、损害或开支是由于本行的欺诈、疏忽或故意失责引致。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">在任何情况下，就任何收益损失或任何特别、间接、附带、相应而生或惩罚性损失或损害赔偿（不论是否可预见或可能招致），本行、本行的关联公司或集团公司、本行的特许人及上述彼等各自的人员、雇员及代理均无须向阁下或任何其他人士负责。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.7</span>
          <span class="right">本条款的内容均无意排除或限制任何不能合法地排除或限制的权利或责任。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">阁下的责任</span>
        </h3>
        <p class="level1">
          <span class="left">3.1</span>
          <span class="right">阁下有责任采取合理可行的步骤以保障阁下自身的利益、资金及资产免受欺诈或其他非法活动的损害。阁下每次均有责任查证及确保收款人、收款人账户、交易及交易详情实属真确并可靠。阁下应认真考虑是否进行或取消一项警示所涉的转账交易。阁下就进行或取消一项警示所涉的转账交易的决定均对阁下具约束力，且阁下应为后果负全责。
          </span>
        </p>
      </section>
      <br>
      
      <p>更新：2024年 4月 18 日</p>



</body></html>

`;
