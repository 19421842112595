export default `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<title>T&amp;C_tc</title>
    <style>
		.level1{
		margin-left:1.5cm;
	    text-indent:-30pt;
		}
        .level1 .left{		
		    display: inline-block;
			width: 1em;
			text-align:right}
        .level1 .right{
		    margin-left:0.58cm;
			text-align:left}	
	
        .list{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-24pt;
		text-align:left;
		}

		.item .left{	
		    display: inline-block;	
			text-align:right;}
        .item .right{
		    margin-left:0.9cm;
			text-align:left}
			
        .list2{
		list-style: none;		 
		margin-left:1.5cm;
	    text-indent:-24pt;
		text-align:left;
		}
		
		.item2 .left{	
		    display: inline-block;			
			width: 1em;
			text-align:right}
        .item2 .right{	
		    margin-left:0.38cm;
			text-align:left}

        .list3{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-20pt;
		text-align:left;
		}

		.item3 .left{
		    width:1em;
			text-align:left;}
        .item3 .right{	
		    margin-left:0.2cm;	
			text-align:left}
        h3 span:last-child {
            margin-left: 0.7em !important;
        }

        h2 {
            font-size: 16px;
            font-weight: 700;
        }
        
        h3 {
            font-size: 14px;
            font-weight: 700;
        }
    </style>
</head>
<body> 
      <br>
      <section>
        <p style="margin-left:0.8cm;">
          本行網上理財平台所提供服務的條款載列如下。在前往下一頁之前，請仔細閱讀有關條款：
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">
                <b>A.</b>
              </span>
              <span class="right">
                <b>網上理財服務的條款及細則</b>，當中載列閣下存取及使用滙豐環球私人銀行網上理財平台的條款及細則；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>B.</b>
              </span>
              <span class="right">
                <b>滙豐私人銀行視野及研究的條款及細則</b>，當中載列閣下可透過滙豐環球私人銀行網上理財平台存取及使用的滙豐私人銀行視野及研究的額外條款及細則；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>C.</b>
              </span>
              <span class="right">
                <b>推播通知服務的條款及細則</b>，當中載列本行向閣下提供並且與閣下可透過滙豐環球私人銀行網上理財平台存取或使用的產品及服務有關的推播通知服務的額外條款及細則；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>D.</b>
              </span>
              <span class="right">
                <b>電子簽署服務的條款及細則</b>，當中載列任何使用電子簽署簽立的文件適用的額外條款及細則。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>E.</b>
              </span>
              <span class="right">
                <b>可疑委託警示模式的條款及細則</b>，此乃與潛在可疑轉賬交易的警示有關。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">A. 網上理財服務的條款及細則</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">釋義及詮釋</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">
            除本文另有界定外，本行的標準章則（可經不時修訂）（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Traditional%20Chinese%20version.pdf">此</a>查閱，<b>「標準章則」</b>）中界定的表述於本網上理財服務的條款及細則（<b>「網上理財條款」</b>）中使用時具有相同涵義。如本網上理財條款與標準章則之間有任何分歧，概以本網上理財條款為準。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">
            於本網上理財條款中，除文義另有所指外，以下詞彙具有以下涵義：
          </span>
        </p>
        <ol class="list">
          <li class="item">
            <p>
              <span class="right">
                「<b>指定網上用戶</b>」指獲閣下授權使用閣下戶口之網上理財服務之指定人士（包括閣下）。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>資料</b>」指本行透過任何互聯網網站提供由任何資料供應商供應之金融、市場或其他資料及數據，包括B部中界定的「刊物」所包含的任何有關資料。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>資料供應商</b>」指供應資料之任何人士或實體，包括提供資料之本行及任何集團辦事處，以及供應任何資料予資料供應商之任何其他人士或實體。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>互聯網網站</b>」指由本行或任何其他人士代表本行建立、運作及╱或維持以提供網上理財服務之互聯網網站或其他平台，包括流動理財應用程式。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>網上理財服務</b>」指下文經不時修訂之第2條（<i>網上理財服務</i>）所載述之服務，包括根據B部提供的視野及研究服務、流動理財應用程式服務及根據D部提供的電子簽署。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>網上理財條款</b>」指網上理財服務的條款及細則。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>流動理財應用程式</b>」指本行提供的應用程式，下載至閣下的流動裝置即可藉其使用流動理財應用程式服務。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>流動理財應用程式服務</b>」指向閣下提供的服務，藉以讓閣下透過流動理財應用程式使用網上理財服務，包括C部中界定的推播通知服務。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>密碼</b>」指指定網上用戶就網上理財服務而使用之密碼。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>報告</b>」指以任何形式、媒介或途徑利用資料編製之任何報告，包括B部中界定的任何「刊物」。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>保安編碼</b>」指由本行提供並用作使用網上理財服務的任何編碼。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>標準章則</b>」具有本網上理財條款第1條（<i>釋義及詮釋</i>）所賦予的涵義。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>本行</b>」或具有該或類似涵義之詞語指本行。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>使用者名稱</b>」指本行接納之指定網上用戶使用者識別代碼，以便使用網上理財服務。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>閣下</b>」或具有該或類似涵義之詞語指客戶，如文義許可，包括經客戶授權就使用網上理財服務向本行發出指示或要求的任何人士，包括任何指定網上用戶。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right"> 網上理財服務</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">
            本行可不時透過任何互聯網網站提供網上服務以便閣下向本行發出通訊，從而操作客戶戶口、進行銀行事務、投資、理財及任何性質之買賣及交易，以及獲取本行不時提供之服務、產品、資料、報告、利益及優惠。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">
            本行有權不時決定並修訂所提供之網上理財服務範圍及類別，包括但不限於：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">隨時增加、修改或削減網上理財服務；</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                制訂或更改使用網上理財服務（或該服務之任何部分）之限制，例如限制閣下使用網上理財服務每日進行任何買賣或交易之類別或任何買賣或交易之最高或最低交易額；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                指定及更改網上理財服務（或該服務之任何部分）之正常服務時間，以及任何類別之網上理財服務、買賣或交易之每日截數時間。本行於適用之每日截數時間後接獲閣下之任何指示，將被視為於下一營業日收到。本行可根據不同時區之市場運作時間，指定網上理財服務的營業日及每日截數時間。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">本行可酌情全面或就任何客戶戶口或一組客戶戶口暫停或終止網上理財服務，但在任何有關情況下，如閣下受到影響，本行須盡可能通知閣下，但在有合理必要保障本行合法權益的情況下，或根據任何法律或為了金融罪案管理活動（包括但不限於為防止詐騙之目的），則可毋須事先通知而暫停或終止。如閣下就網上理財服務的使用遭暫停、限制或終止，本行可結束任何只能透過網上理財服務使用的戶口、產品或服務，閣下將不再收到電子結單，閣下亦應從閣下的裝置刪除本行的流動理財應用程式（如適用）。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">
            本行可要求閣下就使用網上理財服務而指定或登記專用客戶戶口。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">
            閣下可向本行發出事先書面通知而隨時終止使用網上理財服務及╱或終止對任何指定網上用戶之授權。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">
            為使其涵義得以施行，本網上理財條款中所有需要在網上理財服務暫停或終止及／或閣下暫停或終止使用網上理財服務後維持有效的條文（包括但不限於第4條（<i>一般免責聲明</i>）、第7條（<i>使用者名稱及密碼與安全</i>）、第9條（<i>資料及資料供應商</i>）、第11條（<i>本行之責任</i>）及第12條（<i>客戶之責任</i>））於暫停或終止後仍具有十足效力及作用。儘管有此等暫停或終止，只要該等條文與閣下仍未履行或解除之任何義務或法律責任有關，閣下仍須繼續受本網上理財條款約束。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">適用條款</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            使用網上理財服務進行任何買賣及交易，須受制於本網上理財條款、標準章則及所有其他管限有關客戶戶口、交易、買賣、服務、產品、資料、報告、利益或優惠之戶口文件。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4</span>
          <span class="right">一般免責聲明</span>
        </h3>
        <p class="level1">
          <span class="left">4.1</span>
          <span class="right">
            所有閣下可透過網上理財服務進行的交易（不時提供者）純屬執行性質並根據閣下本身的判斷進行。本行並無責任評估或確保閣下透過網上理財服務買賣的產品或進行的交易是否合適。如本行以書面形式明示將本第4.1條摒除於適用範圍之外，則本第4.1條並不適用。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.2</span>
          <span class="right">
            倘適用法律要求為其作出合適程度評估，則本行可能已向或為閣下作出之招攬或推薦僅於作出時有效，且本行無法確保有關產品是否持續合適。透過使用網上理財服務就產品執行交易，即表示閣下明白並接納，倘若與閣下、該產品或其發行人或市況相關之情況有變，則該產品可能不再適合閣下，而閣下使用網上理財服務進行交易之風險概由閣下承擔。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.3</span>
          <span class="right">
            閣下有責任自行透過閣下慣常之買賣渠道，獨立決定買賣之市價及利率；並有責任在依賴任何資料及╱或報告或據此行事前，自行予以核證。閣下亦有責任就使用網上理財服務、資料及報告以及進行之任何買賣及交易在所有適用法律下對閣下可能產生之影響，尋求法律、稅務及其他方面之獨立專業意見。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.4</span>
          <span class="right">
            本行透過互聯網網站或為回覆網上查詢而提供之任何匯率、利率、買賣報價及其他價格及資料乃僅供參考之用，而不具任何約束力。儘管本行曾提供不同之利率、報價或資料，閣下一經確認接納本行就有關交易而提供之任何利率、報價及資料，即對閣下有約束力。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.5</span>
          <span class="right">
            閣下明白透過互聯網或電子郵件使用服務有相關風險，包括但不限於未經授權使用、系統故障、電訊服務及互聯網延誤及中斷。網上理財服務的反應時間可能會因市場狀況、系統性能、存取設備位置或其他因素而有所不同，而資料亦可能在需求高峰、市場波動、系統升級、維護期間或因任何其他原因而遭限制或無法存取。閣下確認，通訊中斷或故障、軟件設備故障、閣下或本行的電腦系統因任何原因發生故障，或未有更新閣下的裝置瀏覽器或作業系統或流動理財應用程式均可能會妨礙或限制閣下使用網上理財服務的能力，如中斷或故障超出本行的合理控制範圍，本行不會對任何有關中斷或故障承擔任何責任。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.6</span>
          <span class="right">
            網上理財服務只在選定的司法管轄區提供。可使用網上理財服務存取的資料不擬在任何司法管轄區分發給有關分發將違反該司法管轄區的任何法例或規例的任何人士或供其使用。本行並不保證網上理財服務或其中的資料符合其他司法管轄區的法律或適合在其他司法管轄區使用。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.7</span>
          <span class="right">
            閣下確認，閣下可在或透過網上理財服務檢視的任何產品或服務可能未經本行獲發牌或授權提供有關產品或服務的任何司法管轄區以外或閣下的居住或所在地的任何中央銀行、政府或監管機構註冊或授權。因此，就閣下可在或透過網上理財服務檢視的產品或服務而言，閣下可能不受本行獲發牌或授權提供有關產品或服務的任何司法管轄區以外或閣下的居住地的證券法、銀行法、保險法或其他相關法律保障。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.8</span>
          <span class="right">
            如閣下透過流動應用程式商店存取及／或下載本行的流動理財應用程式，閣下確認：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                本行對流動理財應用程式的效能負責，閣下應就與本行的流動理財應用程式相關的任何維護及支援事宜、內容事宜、故障或其他申索與本行聯絡；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                當閣下使用本行的流動理財應用程式時，就本網上理財條款而言，有關流動應用程式商店被視為第三方受益人，其可依賴本網上理財條款。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">5</span>
          <span class="right">指定網上用戶</span>
        </h3>
        <p class="level1">
          <span class="left">5.1</span>
          <span class="right">閣下須：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                指定閣下授權之人士就客戶戶口使用網上理財服務，並按本行不時指定之方式及形式限制各有關人士所獲授權範圍；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                就指定網上用戶之郵寄地址及╱或電郵地址的任何更改知會本行；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                確保所有指定網上用戶遵守本網上理財條款及標準章則。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">5.2</span>
          <span class="right">
            閣下及各指定網上用戶須提供本行為提供網上理財服務而不時合理要求的資料。
          </span>
        </p>
        <p class="level1">
          <span class="left">5.3</span>
          <span class="right">
            閣下須就使用網上理財服務負責，有責任採取適當措施以監控網上理財服務之使用情況，並負責指定和更換指定網上用戶，亦須採取恰當之保安措施，以免網上理財服務被未經授權人士使用或被用作未經授權用途，包括但不限於本網上理財條款第7條（<i>使用者名稱及密碼</i>）所載措施。
          </span>
        </p>
        <p class="level1">
          <span class="left">5.4</span>
          <span class="right">
            如閣下已委任指定網上用戶使用任何網上理財服務，該指定網上用戶為及代表閣下接受並同意本網上理財條款，而閣下亦同意受指定網上用戶的有關接受及／或同意約束。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">6</span>
          <span class="right">使用網上理財服務</span>
        </h3>
        <p class="level1">
          <span class="left">6.1</span>
          <span class="right">網上理財服務只供網上理財服務訂用客戶使用。</span>
        </p>
        <p class="level1">
          <span class="left">6.2</span>
          <span class="right">閣下不得進行或試圖進行以下任何活動：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                反編譯、反向工程、翻譯、轉換、改編、改變、修改、提昇、增添、刪除或以任何方式干擾或進入網上理財服務之任何部分或任何互聯網網站或當中組成之任何軟件；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                使用或在知情下容許任何其他人士使用網上理財服務、資料及╱或報告作任何非法目的或活動；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                移除或試圖移除網上理財服務中的任何標題或商標、版權或限制權利通知；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">
                使用任何自動化工具（即機械人）存取網上理財服務或執行活動；及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">
                允許使用任何安全測試工具以提供、掃描或試圖滲透或評估網上理財服務的安全性，
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">閣下如察覺任何有關活動，須盡快通知本行。</span>
        </p>
        <p class="level1">
          <span class="left">6.3</span>
          <span class="right">
            閣下不得上傳或散佈包含病毒或其他惡意程式碼的檔案到網上理財服務，也不得作出任何其他行為破壞或試圖破壞用於提供網上理財服務的系統及網絡。一旦發現網上理財服務實際或懷疑遭病毒或其他惡意程式碼入侵，閣下必須立即通知本行並與本行合作以減低有關病毒或惡意程式碼的影響。
          </span>
        </p>
        <p class="level1">
          <span class="left">6.4 </span>
          <span class="right">
            閣下確認經由互聯網傳送之資料或通訊，可能會出現時差。
          </span>
        </p>
        <p class="level1">
          <span class="left">6.5</span>
          <span class="right"> 閣下確認：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                閣下就使用網上理財服務提供的任何資料可能會向本行的業務部門、任何集團辦事處及／或可能位於其他司法管轄區的授權服務供應商披露或供其存取；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                閣下使用網上理財服務可能會受到本行、任何集團辦事處及／或授權服務供應商監控，而本行可能出於內部業務考慮或任何適用法律而收集及使用由此產生的任何資料，
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            一切均按照標準章則的條款進行，閣下並且同意有關披露、監控及／或使用。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">7</span>
          <span class="right">使用者名稱及密碼與安全</span>
        </h3>
        <p class="level1">
          <span class="left">7.1</span>
          <span class="right">
            各指定網上用戶須按照本行不時訂明之程序指定其使用者名稱及密碼。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.2</span>
          <span class="right">
            各指定網上用戶可隨時更改使用者名稱及密碼，惟任何更改須於本行接納後方為有效。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.3</span>
          <span class="right">
            本行可行使絕對酌情權要求閣下使用保安編碼，以便使用網上理財服務。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.4</span>
          <span class="right">
            各指定網上用戶須以真誠行事，在合理謹慎盡責的原則下採取一切合理措施，將使用者名稱、密碼及保安編碼保密以防盜用情況發生。無論任何時間或情況，指定網上用戶均不得向任何其他人士披露使用者名稱、密碼及╱或保安編碼。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.5</span>
          <span class="right">
            倘若指定網上用戶之使用者名稱、密碼及╱或保安編碼不慎或在未經授權之情況下被洩露予其他人士知悉，閣下須負全責，並須承擔指定網上用戶之使用者名稱、密碼、保安編碼或流動裝置被未經授權人士使用或被用作未經授權用途之風險。
          </span>
        </p>
        <p class="level1">
          <span class="left">7.6</span>
          <span class="right">
            閣下如在流動理財應用程式上啟動生物識別憑據使用功能（譬如指紋認證或人臉識別），並允許使用有關生物識別憑據登入流動理財應用程式或本行可能不時支援之任何其他網上理財服務（僅為兼容裝置而設），便須確保只在裝置上登記閣下自身之生物識別憑據。閣下確認並同意：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                本行無法控制流動裝置的技術、供應情況、性能、驗證方式、安全性及可靠性，因此概不就任何未經授權人士透過生物識別憑據登入網上理財服務之情況承擔責任；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                閣下不得採取任何行動以停用閣下裝置提供的任何功能或同意裝置的任何設定，從而危及使用生物識別憑據進行驗證的安全性（例如停用人臉識別的「螢幕注視感知功能」）。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">7.7</span>
          <span class="right">
            閣下或有關指定網上用戶如發現或懷疑使用者名稱、密碼及╱或保安編碼被披露予未經授權人士，或網上理財服務被未經授權使用，須盡快親身通知本行，或根據本行不時指定之電話號碼致電通知本行。本行可要求閣下或有關指定網上用戶以書面形式確認所提供之資料。在本行實際收到該等通知前，閣下須就任何及所有因未經授權人士使用網上理財服務或網上理財服務被用作未經授權用途負責。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">8</span>
          <span class="right">客戶指示</span>
        </h3>
        <p class="level1">
          <span class="left">8.1</span>
          <span class="right">各指定網上用戶須先鍵入其使用者名稱、密碼、保安編碼及本行不時規定之任何其他識別字符，方可登入互聯網網站使用網上理財服務及代表閣下於網上向本行發出通訊。本行獲授權按各指定網上用戶之通訊行事。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.2</span>
          <span class="right">
            指定網上用戶須於本行不時指定之網址及聯絡點向本行發出通訊。除非本行另行明確同意，否則指定網上用戶不得向本行之個別主管人員發出任何通訊。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.3</span>
          <span class="right">
            通訊須按本行不時指定之方式發出及於本行實際收到後，始視為經由本行收妥。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.4</span>
          <span class="right">
            任何通訊一經發出，如未得本行同意，概不得廢除或撤回。除核對有關指定網上用戶之使用者名稱、密碼、保安編碼及其他有關識別字符（如有）外，本行並無責任核證作出任何通訊之人士之身分或授權，或此等通訊之真確性。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.5</span>
          <span class="right">
            本行只會執行本行認為合理可行之通訊，並將遵照正常業務慣例及程序行事。如本行得悉或懷疑存在與使用網上理財服務相關的安全漏洞或其他可疑情況，本行有權延遲或拒絕按通訊行事。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.6</span>
          <span class="right">
            所有經由本行按照通訊代閣下辦理之交易均對閣下具有約束力。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.7</span>
          <span class="right">
            若閣下選擇並同意，本行可在網上向閣下發出已收訖通訊及╱或已執行交易或買賣之通知或確認。閣下同意查核有關通知或確認以確保其準確及完整。倘閣下未有收到通知或確認，或閣下相信有關通知或確認中的任何資料為不準確或不完整，閣下將向本行查詢。
          </span>
        </p>
        <p class="level1">
          <span class="left">8.8</span>
          <span class="right">
            於互聯網網站提供有關任何戶口、交易或買賣之資料均僅供參考之用。本行對該等戶口、交易或買賣之紀錄，如無欺詐或明顯錯誤之情況，均為該等紀錄之證據。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">9</span>
          <span class="right">資料供應商</span>
        </h3>
        <p class="level1">
          <span class="left">9.1</span>
          <span class="right">
            資料供應商可就其提供之任何資料不時制訂有關之條款及細則，並將事先通知該等條款及細則之生效日期。閣下於該等條款及細則生效當日或之後使用該等資料，即表示閣下接納該等條款及細則。
          </span>
        </p>
        <p class="level1">
          <span class="left">9.2</span>
          <span class="right"> 資料及報告只供參考之用。</span>
        </p>
        <p class="level1">
          <span class="left">9.3</span>
          <span class="right">
            除在適用法律規定或任何有關文件訂明的範圍內，本行及任何資料供應商對任何資料或報告之次序、準確性、真確性、可靠性、充裕程度、時間性或完整性，或其是否適宜作任何用途，概不作出保證、聲明或擔保。閣下同意獨立評估及核實任何資料或報告中的有關事宜，除在適用法律規定或任何有關文件訂明的範圍內，本行及任何資料供應商不會就閣下或任何其他人士因依賴資料或報告而承擔任何法律責任（不論是侵權或合約或其他責任）。然而，本條款並不排除本行或資料供應商根據適用法律就行為、聲明或陳述對閣下或任何其他人士可能承擔的任何責任。
          </span>
        </p>
        <p class="level1">
          <span class="left">9.4</span>
          <span class="right">
            資料將按其由資料供應商所提供之形式提供。本行不會就任何其他資料供應商提供之資料作出批註或表示任何意見，亦無責任對有關資料進行檢查或核證。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">10</span>
          <span class="right">保密資料</span>
        </h3>
        <p class="level1">
          <span class="left">10.1</span>
          <span class="right">保密資料屬本行及有關資料供應商之商業秘密、機密及專有財產。
          </span>
        </p>
        <p class="level1">
          <span class="left">10.2</span>
          <span class="right">
            除本網上理財條款或本行明文許可外，閣下不得進行或試圖進行以下任何活動：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                將任何保密資料向任何其他人士出售、轉移、披露、出讓、轉達、出租、分授許可權、分享、借出、分派、傳輸、廣播、電纜廣播、傳閱、複印，或以其他方式以任何形式或任何手段提供或散佈，或將任何保密資料作商業用途；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                下載或複製任何保密資料作閣下私人參考及使用以外之其他用途；及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                將保密資料上顯示之任何所有權標記（包括但不限於任何商標或版權通告）以任何方式移除、塗掉、擦去、遷移或更改。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.3</span>
          <span class="right">
            在下列情況下，對披露之限制並不適用於下列任何保密資料：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                法律強制規定作出披露，惟只限於法律規定之範圍，及只在閣下已經向本行發出書面通知，說明要求披露之保密資料；或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">本行已書面明確同意披露之保密資料。</span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.4</span>
          <span class="right">
            有關保密資料之一切權利、所有權及權益，以及任何及所有有關版權、專利權、商標、服務標記、專有財產、商業秘密及專用作品，均屬本行及╱或有關資料供應商之獨家財產。此等權利、所有權或權益（除根據本網上理財條款有權使用資料及報告外）均未有轉移或轉讓予閣下。閣下不得作出顯示閣下擁有任何此等權利、所有權或權益之任何聲明或作為。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">11</span>
          <span class="right">本行之責任</span>
        </h3>
        <p class="level1">
          <span class="left">11.1</span>
          <span class="right">
            本行將根據不時適用於本行之任何法律、通告、行為守則及現行市場慣例，採取合理可行之步驟，以確保與網上理財服務有關之系統已裝置足夠之保安設施，並於系統運作時，對有關風險予以監控。
          </span>
        </p>
        <p class="level1">
          <span class="left">11.2</span>
          <span class="right">
            本行、任何集團辦事處或任何資料供應商，概不保證或聲明網上理財服務、資料及報告不含有任何對閣下硬件、軟件或設備造成不利影響之病毒或其他破壞性程式。
          </span>
        </p>
        <p class="level1">
          <span class="left">11.3</span>
          <span class="right">
            除非引用下文第12.2條，或由於本行、任何集團辦事處或本行╱其各自之主管人員或僱員之欺詐、疏忽或故意失責所引致，本行或任何集團辦事處概不就以下事項所引致或與之有關之後果，而向閣下或任何其他人士承擔任何責任：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                由於閣下或任何其他不論是否獲授權之人士使用網上理財服務及╱或取得任何資料；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                由於在提供網上理財服務、傳送與網上理財服務有關之資料或通訊或與互聯網網站連線時因任何本行之作為、不作為或本行所能合理控制範圍以外之情況，包括但不限於通訊網絡失靈、提供服務之第三者之作為或不作為、機械故障、電力故障、失靈、操作故障，或設備、裝置或設施不足，或因任何法律、守則、指令、監管指引或政府命令（不論是否具法律效力）而出現任何干擾、截取、中斷、延誤、損失、無法提供資料、毀壞或其他故障（包括但不限於任何干擾、截取、中斷、延誤或無法顯示保安編碼）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                透過或在任何通訊網絡供應商之系統、設備或儀器傳送及╱或儲存任何與閣下及╱或網上理財服務有關之資料及╱或數據。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">11.4</span>
          <span class="right">
            本行、任何集團辦事處或任何資料供應商，概毋須對閣下或任何其他人士就任何偶發性、間接、特殊、相應或懲罰性損害賠償負責，包括但不限於有關使用、收入、利潤或儲蓄方面之任何損失責任。本條款不適用於本行／彼等欺詐、疏忽或故意失責的情況。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">12</span>
          <span class="right">客戶之責任</span>
        </h3>
        <p class="level1">
          <span class="left">12.1</span>
          <span class="right">除非引用下文第12.2條或除非本行存在欺詐、疏忽或故意失責的行為，否則因閣下或任何其他人士（不論是否獲得授權）使用網上理財服務及／或因使用網上理財服務取得任何資料或報告或任何其他資料而引致或與之有關之後果，概由閣下全部承擔。
          </span>
        </p>
        <p class="level1">
          <span class="left">12.2</span>
          <span class="right">根據第7條（<i>使用者名稱及密碼</i>）下閣下責任之限制，及倘本行合理地認為閣下並無嚴重疏忽或欺詐，則閣下毋須就使用網上理財服務進行未經授權交易而產生之損失或資金錯置負責。
          </span>
        </p>
        <p class="level1">
          <span class="left">12.3</span>
          <span class="right">
            除非引用上文第12.2條，或由於本行、任何集團辦事處、任何資料供應商或本行╱其各自之主管人員或僱員之欺詐、疏忽或故意失責所引致，否則閣下須彌償本行、任何集團辦事處、任何資料供應商及其各自之主管人員及僱員因提供網上理財服務、資料及╱或報告或行使或保存本行在本網上理財條款及標準章則賦予之權力及權利或因提起或招致之任何法律行動或訴訟所合理及適當地招致之一切法律責任、索償、申索、損失、損害賠償、訟費、任何形式之費用及開支（包括但不限於按全數賠償基準支付之法律費用）。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">13</span>
          <span class="right">修訂</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            本行可不時對本網上理財條款進行修訂及／或增補。網上理財條款之任何修訂及╱或增補在本行透過於互聯網網站刊登或按照本行認為合適之其他方式向閣下發出事先合理通知後，即告生效。倘閣下在修訂生效當日或之後繼續維持或使用網上理財服務，則有關修訂將對閣下具有約束力。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">14</span>
          <span class="right">通知及通訊形式</span>
        </h3>
        <p class="level1">
          <span class="left">14.1</span>
          <span class="right">
            本行可不時根據本網上理財條款就各類通知訂明其通知形式（不論書面通知或其他形式）及通訊模式。
          </span>
        </p>
        <p class="level1">
          <span class="left">14.2</span>
          <span class="right">
            以電郵發出之通訊在傳送至閣下最近期以書面形式通知本行之電郵郵址後，即視作閣下經已收悉。
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">B. 滙豐私人銀行視野及研究的條款及細則</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">本條款及細則的簡介</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">
            閣下使用由本行提供的滙豐私人銀行視野及研究服務（「<b>視野及研究服務</b>」）受以下條款規限：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                本滙豐私人銀行視野及研究的條款及細則（「<b>視野及研究條款</b>」）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                網上理財服務的條款及細則（「<b>網上理財條款</b>」）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">本行的標準章則（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Traditional%20Chinese%20version.pdf">此</a>查閱），
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            以上條款統稱為「<b>條款</b>」。如本視野及研究條款與網上理財條款或標準章則有任何衝突，則須按以上次序決定優先順序（從高到低）。本行可在向閣下發出合理通知後不時修訂視野及研究條款。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">
            視野及研究條款中使用但未有另行界定的詞彙與網上理財條款及標準章則所賦予的涵義相同。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">
            為免生疑問，網上理財條款中界定的「網上理財服務」及「互聯網網站」將分別包括視野及研究服務及視野及研究網站（「<b>視野及研究</b>」）。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">存取及使用刊物</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">
            本行可向閣下及／或閣下的指定網上用戶提供視野及研究的存取權限（各自稱為「<b>用戶</b>」），這是讓用戶（其中包括）存取以下若干事項的電子平台：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                環球私人銀行宏觀金融市場評論（「<b>環球私銀宏觀報告</b>」）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                由本行的環球市場部門下屬的環球研究部（「<b>滙豐環球研究</b>」）提供的滙豐環球研究報告（「<b>滙豐研究報告</b>」）及／或
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                非滙豐的第三方刊物（「<b>第三方刊物</b>」）
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            （本文統稱為「<b>刊物</b>」）。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">
            刊物可能會討論多個資產類別的金融市場及投資產品，並可能包括涵蓋特定金融工具及發行人的金融研究材料。各類用戶可存取的刊物種類將由本行酌情決定。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">
            只有已向本行提供詳細資料並已獲本行批准為用戶的人士方可使用視野及研究服務。閣下有責任確保閣下指定的各用戶均已閱讀並明白條款。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">
            本行將讓各用戶透過網上理財服務經由視野及研究存取刊物。用戶亦可透過電郵從其客戶關係經理或投資顧問收取刊物，或使用視野及研究的訂閱提示功能。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">
            刊物只提供英文版本予各用戶。閣下提名任何用戶，即確認各用戶均具備充分的英語水平，能夠完全明白刊物的內容及當中可能包含的任何重要免責聲明或風險警告。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">
            用戶不得嘗試直接聯絡任何刊物的發行人，包括但不限於任何滙豐環球研究分析師或第三方，或其任何代表或僱員。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">金融知識、經驗及閱讀能力</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            刊物可能涵蓋不同的主題及產品類別，包括但不限於氣候變化、商品、可轉換證券、高等級信用、高收益信用、信用策略、貨幣、經濟、股票策略、固定收益、利率、結構性及資產抵押證券、股權及結構性產品。刊物會涉及市場、經濟及財務數據、概念及比率，以及其他技術資料或概念，各用戶需要具備相當程度的金融知識、經驗及閱讀能力。閣下提名任何用戶，即確認各用戶均具備足分的金融知識、經驗及閱讀能力以閱讀及明白刊物及其限制，特別是下文第6.1及6.2條所述者，並將在評估透過視野及研究定期提供的材料時行使獨立判斷。在理解任何刊物時遇到困難的用戶應聯絡負責客戶戶口的客戶關係經理或投資顧問。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4</span>
          <span class="right">限制存取、安全性及系統可用性</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            刊物並非給予亦不擬分發予任何身為有關分發、出版、提供或使用會違反法例或規例的任何司法管轄區的公民或居民或位於有關司法管轄區的人士或實體的用戶或供其使用。本行將根據各用戶居住記錄的司法管轄區以及本行對各有關司法管轄區適用的法例及監管規定的評估，向各用戶提供刊物的存取權。各用戶確認其並非任何存取刊物可能會違反當地法例或規例的國家的居民。各用戶均有責任在其改變居籍的司法管轄區時立即通知本行。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">5</span>
          <span class="right">知識產權</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            刊物只供各用戶個人專用。刊物仍屬本行、本行的部門（不包括私人銀行部門）、任何集團辦事處的其他成員（例如滙豐環球研究）或製作及出版刊物的第三方的財產，未經本行明確書面同意，用戶並無獲得任何權利以任何方式或形式複製或分發全部或部分刊物或其內容，並且只有在明確承諾確保作允許用途時充分註明來源的情況下方可複製或分發刊物。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">6</span>
          <span class="right">關於刊物內容及用途的重要限制及警告</span>
        </h3>
        <p class="level1">
          <span class="left">6.1</span>
          <span class="right">
            並非投資建議或推薦／不就適當性或合適性作出聲明
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">刊物只供各用戶參考。</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                刊物不構成亦不應詮釋為本行向閣下或任何用戶發出任何形式的法律、稅務或投資建議或招攬及／或推薦，亦非本行向閣下或任何用戶發出認購、購買、贖回或出售任何金融工具的要約或邀請，或進行與有關工具相關的任何交易。刊物的內容可能不適合閣下的財務狀況、投資經驗及投資目標，本行不會就刊物呈列的任何金融工具或投資策略對閣下的適當性或合適性作出任何聲明。用戶應根據閣下的情況自行判斷，以決定特定產品是否適合閣下。在適用的情況下，用戶應考慮聽取專業建議，包括有關閣下的法律、稅務或會計狀況的建議。用戶如希望進行投資產品交易，應聯絡閣下在本行的客戶關係經理或投資顧問。用戶不應單純根據任何刊物的內容作出任何投資決定。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">6.2</span>
          <span class="right">關於刊物內容的重要警告</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                任何刊物所包含的過往表現數據不應視為當前或未來表現的指標或保證，概不就當前或未來表現作出任何明示或暗示的聲明或保證。表現數據可能並無計及發行及贖回（或購買及出售）金融工具所產生的費用、佣金及其他開支。刊物所述任何金融工具的價格、價值及收入可跌可升，並且不能排除閣下可能希望投資或已經投資於該等工具的任何本金全部損失的風險。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                任何刊物的內容只應視為截至首次刊發當日的最新消息，而不論用戶可能存取有關刊物的日期。這包括刊物所包含的任何意見或估計，該等意見或估計僅反映在原刊發日期的判斷。本行可能會定期修訂及更新刊物，並保留酌情決定刪除或修改刊物任何內容的權利，而不作事先通知。雖然本行將嘗試向用戶提供刊物的適時更新版本，但本行並無責任如此行事。向用戶提供的任何更新將透過視野及研究提供，各用戶有責任確保其及時得悉有關更新。刊物可能與過往刊物的內容不一致或得出不同的結論。所有意見均反映在原刊發日期所作的判斷。集團辦事處並無責任確保過往刊物的收件人得悉已發佈刊物。用戶有責任按本身需要檢查是否有更新版本。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                各用戶確認，滙豐研究報告及第三方刊物未經本行獨立驗證。本行、任何集團辦事處或本行／其服務供應商概不就刊物所載資料的準確性或完整性承擔任何責任，本行明確聲明不就滙豐研究報告及第三方刊物中表達的意見、聲明或保證承擔任何及所有責任。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">
                本行可能曾發佈其他內容／材料，與第三方刊物或滙豐研究報告所呈列的資料相比可能存在若干差異、看似不一致或得出不同結論。這反映該內容／材料的作者的不同假設、觀點及分析方法，本行並無責任確保提請各用戶注意任何差異或不一致情況。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">
                本行、本行的部門（不包括私人銀行部門）、本行的母公司、附屬公司或其他聯屬公司，以及其各自的董事、經理及僱員：
              </span>
            </p>
            <ul class="list3">
              <li class="item3">
                <p>
                  <span class="left">(i)</span>
                  <span class="right">
                    過去、現在或未來可能投資於刊物所述的證券或金融工具，並且可能不時增加或出售有關投資；
                  </span>
                </p>
              </li>
              <li class="item3">
                <p>
                  <span class="left">(ii)</span>
                  <span class="right">
                    過去、現在或未來可能充當該等證券或金融工具的莊家；
                  </span>
                </p>
              </li>
              <li class="item3">
                <p>
                  <span class="left">(iii)</span>
                  <span class="right">
                    過去、現在或未來可能與刊物所述的發行人有關係，並且可能已經或將會提供金融服務，例如企業融資、投資銀行或包銷服務；及／或
                  </span>
                </p>
              </li>
              <li class="item3">
                <p>
                  <span class="left">(iv)</span>
                  <span class="right">
                    過去、現在或未來可能出任刊物所述發行人的執行董事會成員。
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li class="item2">
            <p>
              <span class="left"></span>
              <span class="right">
                滙豐環球研究已制定程序識別及管理與其研究業務相關的任何潛在利益衝突。滙豐環球研究的分析師及其他參與編製及發佈滙豐研究報告的員工參與及設有獨立於本行投資銀行業務的管理匯報機制。本行的投資銀行業務與滙豐環球研究之間設有資訊屏障以及相關政策和程序，確保以適當的方式處理任何機密及／或價格敏感資料。
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(F)</span>
              <span class="right">
                刊物所述的金融工具面對貨幣、市場、流動性及其他風險，可能對其價值、價格或收入（如有）產生正面或負面影響。該等風險在與特定金融工具相關的文件中有更詳盡的描述，在進行任何投資前應充分考慮有關風險。刊物可能載有特定的限制／警告條文，各用戶應仔細閱讀有關條文連同任何刊物所載有的任何免責聲明。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">6.3</span>
          <span class="right">超出範圍的報告</span>
        </p>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            滙豐環球研究可能編製涵蓋滙豐環球研究資產配置及滙豐環球研究模型投資組合但與本行私人銀行的觀點不一致的報告。在可行的範圍內，該等報告一般不會在視野及研究刊載。然而，該等報告的內容及提述可能會出現在向私人銀行客戶提供的其他滙豐研究報告中。如對視野及研究所提供的任何研究報告的內容有疑問，用戶應首先聯絡閣下的客戶關係經理或投資顧問。
          </span>
        </p>
        <p class="level1">
          <span class="left">6.4</span>
          <span class="right">進一步披露</span>
        </p>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            有關使用視野及研究服務及刊物的進一步披露及警告，請參考各刊物所包含的「重要提示及免責聲明」連結。各用戶同意在使用視野及研究前閱讀該等披露及警告以及其不時的更新資料。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">7</span>
          <span class="right">終止／暫停存取及修訂</span>
        </h3>
        <p class="level1">
          <span class="left">7.1</span>
          <span class="right">
            在不損害本行根據網上理財條款終止或暫停任何服務的權利的情況下，如本行酌情判斷任何條款已遭違反或因任何其他原因而認為可能需要終止或暫停任何用戶存取視野及研究服務，則本行可暫停或終止有關服務。尤其是，在不限制前述規定的一般性的情況下，如任何用戶的存取可能違反任何法律，則本行可終止或暫停有關存取。本行亦可在向閣下發出合理通知後不時修訂視野及研究條款。
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">C. 推播通知服務的條款及細則</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">本條款及細則的簡介</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">
            閣下使用由本行提供的推播通知服務（定義見下文第1.2條）受以下條款規限：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                本推播通知服務的條款及細則（「<b>推播通知條款</b>」）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                網上理財服務的條款及細則（「<b>網上理財條款</b>」）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                本行的標準章則（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Traditional%20Chinese%20version.pdf">此</a>查閱），</span>
            </p>
          </li>
        </ol>

        <p class="level1">
          <span class="left"></span>
          <span class="right">
            以上條款統稱為「<b>條款</b>」。如本推播通知條款與網上理財條款及標準章則有任何衝突，則須按以上次序決定優先順序（從高到低）。本行可在向閣下發出合理通知後不時修訂推播通知條款。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">在本推播通知條款中：</span>
        </p>
        <ol class="list">
          <li class="item">
            <p>
              <span class="right">
                「<b>訊息中心</b>」指本行的流動理財應用程式中儲存所有推播通知的功能。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>推播通知</b>」指透過推播通知服務發送至本行流動理財應用程式的訊息中心之訊息。
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                「<b>推播通知服務</b>」指本行可根據本推播通知條款提供的服務。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">
            本推播通知條款中使用但未有另行界定的詞彙與網上理財條款所賦予的涵義相同。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.4</span>
          <span class="right">
            為免生疑問，網上理財條款中界定的「流動理財應用程式服務」將包括推播通知服務。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">推播通知服務的範圍</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">
            推播通知服務僅透過本行的流動理財應用程式或不時通知閣下的渠道提供。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">
            閣下可透過適當的電訊設備使用推播通知服務以電子方式接收資訊及通訊。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">
            透過推播通知服務提供的資訊及通訊類型將由本行酌情決定。為免生疑問，推播通知服務與本行標準章則第IX節所界定的電子結單服務無關。推播通知並非亦不應詮釋為電子結單。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">
            本行可能在未有事先通知的情況下不時指定或更改推播通知服務的範圍和範疇及其功能（或將其終止）。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">
            本行可能在未有事先通知的情況下停止向閣下提供推播通知服務（或更改向閣下提供的推播通知服務範圍），以遵循適用法例或規例的限制或與該等限制相關的內部政策而無須向閣下負上法律責任。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">開啟及使用推播通知服務</span>
        </h3>
        <p class="level1">
          <span class="left">3.1</span>
          <span class="right">
            閣下將透過本行的流動理財應用程式登記推播通知服務。當閣下登記推播通知服務時，閣下需要告知本行欲接收的推播通知種類。閣下可在登記後以本行接納的方式或形式不時更改此資料。
          </span>
        </p>
        <p class="level1">
          <span class="left">3.2</span>
          <span class="right">
            本行可能會限制閣下用來接收推播通知的流動裝置數目及類型。本行可能會視乎客戶類型或流動裝置的類型或型號施加不同限制。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4 </span>
          <span class="right">推播通知</span>
        </h3>
        <p class="level1">
          <span class="left">4.1</span>
          <span class="right">
            閣下應確保閣下的流動裝置及相關服務能夠接收推播通知。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.2</span>
          <span class="right">
            本行的推播通知只會發送一次，不予重發。如果閣下已刪除本行發送的推播通知並需要查閱有關資訊，請與本行聯絡。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.3</span>
          <span class="right">
            即使閣下收到聲稱由本行透過推播通知服務發出的要求，要閣下提供密碼或戶口或保安詳情，請勿回覆，因為本行絕不會作出該要求。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.4</span>
          <span class="right">
            如果閣下已更換閣下的流動裝置，閣下需要重新安裝本行的流動理財應用程式。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.5</span>
          <span class="right">
            本行發送的推播通知均為單向形式。閣下不能在訊息中心內對推播通知作出回應。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.6</span>
          <span class="right">
            視乎流動裝置的類型，推播通知服務依賴由Apple Inc.（「<b>Apple</b>」）或Google LLC（「<b>Google</b>」）提供的相關通訊服務。因Apple或Google服務的任何故障或不足而導致推播通知傳送延遲或失敗均不在本行的控制範圍之內。
          </span>
        </p>
        <p class="level1">
          <span class="left">4.7</span>
          <span class="right">
            閣下需要連接至流動數據網絡或WiFi無線網絡以接收推播通知。如欲在海外接收推播通知訊息，請檢查是否已啟用數據漫遊。然而，請注意，如果閣下身在特定國家或地區，或使用某些類型的流動裝置，則未必能向閣下提供推播通知服務。閣下需自行承擔因本地或國際網絡連接而產生的任何費用及收費。
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">D. 電子簽署的條款及細則</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">本條款及細則的簡介</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">
            閣下使用電子簽署（本行的電子簽署服務）存取及以電子方式簽署戶口文件受以下條款規限：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                本電子簽署服務的條款及細則（「<b>電子簽署條款</b>」）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                網上理財服務的條款及細則（「<b>網上理財條款</b>」）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                本行的標準章則（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Traditional%20Chinese%20version.pdf">此</a>查閱），</span>
            </p>
          </li>
        </ol>

        <p class="level1">
          <span class="left"></span>
          <span class="right">
            以上條款統稱為「<b>條款</b>」。如本電子簽署條款與網上理財條款及標準章則有任何衝突，則須按以上次序決定優先順序（從高到低）。本行可在向閣下發出合理通知後不時修訂電子簽署條款。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">
            在本電子簽署條款中，對「<b>Adobe Sign</b>」的提述指由第三方平台營運商Adobe Systems Software Ireland
            Limited（註冊辦事處位於4-6 Riverwalk, Citywest Business Campus,
            Dublin 24, Ireland，「<b>Adobe</b>」）透過Adobe
            Sign平台提供的電子簽署產品及服務。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">
            本電子簽署條款中使用但未有另行界定的詞彙與網上理財條款所賦予的涵義相同。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.4</span>
          <span class="right">
            為免生疑問，網上理財條款中界定的「網上理財服務」將包括本D部的電子簽署。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">Adobe條款及細則</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">
            本互聯網網站可能不時提示閣下以電子方式簽署文件。如閣下選擇以電子方式簽署文件，閣下將被帶往Adobe
            Sign平台，該平台受Adobe本身的特定條款及細則、私隱及cookie聲明（包括免責聲明）約束，並將構成閣下與Adobe之間的獨立法律協議（「<b>Adobe條款及細則</b>」）。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">
            如任何條款及細則之間有任何衝突，則須按以下次序決定優先順序（從高到低）：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">Adobe條款及細則；及</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">本電子簽署條款。</span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">用戶確認</span>
        </h3>
        <p class="level1">
          <span class="left">3.1</span>
          <span class="right">
            Adobe
            Sign平台上以電子方式簽署文件，即表示閣下及／或閣下的指定網上用戶（各自稱為「<b>用戶</b>」）均為本身及代表閣下確認並同意：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                閣下授權並同意本行使用Adobe
                Sign平台以電子形式收集閣下的簽名並將閣下的簽名連結到文件 —
                該等文件連同所收集及連結的簽名稱為<b>「電子文件」</b>；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                本行使用第三方服務供應商Adobe的服務來收集閣下的簽名並將閣下的簽名連結到電子文件。當本行將閣下重新導向到Adobe
                Sign平台時，閣下將可查閱可以電子方式簽署的文件。到訪Adobe
                Sign平台，即表示閣下直接向Adobe表示同意閣下使用Adobe
                Sign平台所適用的Adobe條款及細則。本行或任何集團辦事處概不對Adobe
                Sign平台的營運或其服務及功能負責。閣下須自行承擔使用Adobe
                Sign平台的風險；本行或任何集團辦事處概不對與閣下到訪及使用Adobe
                Sign平台有關的任何損害或以任何其他方式負責或承擔法律責任；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                本互聯網網站上的任何資訊或閣下將被重新導向到Adobe
                Sign平台概不表示或暗示本行或任何集團辦事處對Adobe或其建議、意見、資料、產品或服務的合適性給予認可、發出批准或負上責任；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">
                閣下將在簽署前閱讀整份電子文件，且閣下在每份電子文件上的簽名是由閣下附加，確認閣下有意受有關電子文件約束。閣下並無亦不會允許任何其他人士協助閣下將閣下的簽名附加到任何電子文件；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">
                閣下在每份電子文件上的簽名均須清晰準確地反映在有關電子文件上，而以該電子形式收集、接收及／或儲存的簽名將在各種用途上視為等同於閣下在列印本上的簽名，且須經本行核實該簽名符合本行的內部規定後方可作實；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(F)</span>
              <span class="right">
                由本行或代表本行保存閣下以電子形式附加簽名的電子文件將視為有效、準確及真確，並猶如該等電子文件是以書面形式由閣下在列印本上簽署一樣具有相同效力；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(G)</span>
              <span class="right">
                除非法律另有規定，否則本行有權視任何電子文件所收集的簽名為有效及可予強制執行；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(H)</span>
              <span class="right">
                任何電子文件，任何形式的活動或交易日誌、電腦或電子記錄、電腦打印文件的任何憑證，或任何其他形式的電腦或電子數據或資料儲存或系統（統稱「<b>電子記錄</b>」）即為有關資料的記錄，而閣下載於電子文件及電子記錄中有關任何事宜的指示、同意、確認及協定可在任何法律程序中用作證明其內容的憑據；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(I)</span>
              <span class="right">
                閣下如欲索取電子文件副本，可聯絡閣下的客戶關係經理；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(J)</span>
              <span class="right">
                如不同意或對電子文件的內容有任何疑問，閣下可在按下最後的「點擊簽署」按鈕前隨時關閉瀏覽器並退出簽署程序。所有電子文件僅在閣下點擊最後的「點擊簽署」按鈕（出現在所有電子文件的末端）時方會視為已簽立；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(K)</span>
              <span class="right">
                閣下簽立電子文件並不代表本行接納閣下的指示。電子文件仍須經本行核實及批准，並只有在經本行批准後方告生效。
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center">E. 可疑委託警示模式的條款及細則</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">本條款及細則的介簡</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">
            本條款與閣下使用可疑委託警示模式（「<b>警示模式</b>」）有關，警示模式為在使用流動理財應用程式時向閣下發出潛在可疑轉賬交易警示的服務（「<b>服務</b>」），並僅適用於在香港開立的戶口。使用服務受以下條款規限：
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                與警示模式有關的本條款及細則（「<b>警示模式條款</b>」）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                網上理財服務的條款及細則（「<b>網上理財條款</b>」）；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                本行的標準章則（按<a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20Traditional%20Chinese%20version.pdf">此</a>查閱），</span>
            </p>
          </li>
        </ol>

        <p class="level1">
          <span class="left"></span>
          <span class="right">
            以上條款統稱為「<b>條款</b>」。如本警示模式條款與網上理財條款及標準章則有任何衝突，則須按以上次序決定優先順序（從高到低）。警示模式條款將於服務在流動理財應用程式內可供使用當日生效。本行可在向閣下發出合理通知後不時修訂警示模式條款。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">在本警示模式條款中：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                「警示」指對一項轉賬交易或相關的收款人或收款人戶口可能涉及欺詐或詐騙的警告訊息；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                「防詐資料庫」包括由香港警務處或香港其他執法機關、政府機構或監管機構運作或管理的任何防詐騙搜尋器及／或防欺騙資料庫，不論其是否可供一般公眾人士或指定實體或組織使用（包括但不限於香港警務處的防騙視伏器）；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                「轉賬交易」指閣下透過本行經流動理財應用程式並使用任何本行不時決定的貨幣進行的資金轉移，不論收款人戶口是否在本行開立；如文義要求或允許，包括閣下向本行發出進行轉賬交易的指示。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">
            閣下在本警示模式條款生效日期當日或之後作出任何轉賬交易，即閣下確認閣下已接受本警示模式條款並會受其約束。
          </span>
        </p>
        <p class="level1">
          <span class="left">1.4</span>
          <span class="right">
            警示旨在幫助閣下在作出轉賬交易時保持警覺提防欺詐、詐騙及欺騙。警示並不替代或免除閣下保障自身的利益、資金及資產免受欺詐或其他非法活動損害的責任。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">本行的角色、責任及責任限制</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">本行：</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">
                無法控制防詐資料庫的管理、運作或其他方面；
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                單靠防詐資料庫不時提供的資料來編製警示；及
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                不會就防詐資料庫並無提供資料的收款人、收款人戶口或交易編製警示。
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">
            本行不會保證亦不能保證任何防詐資料庫提供的資料是否完整、真實、準確及最新，也不會保證亦不能保證閣下沒有收到警示的轉賬交易不涉欺詐，或閣下收到警示的轉賬交易必屬欺詐。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">
            本行可按其認為適當的方式編製及傳送警示。本行可不時考慮本行的需要以及相關人士的反饋、意見、指引或建議，酌情決定及／或更改警示的內容、傳送警示的渠道或方式，及／或轉賬交易的貨幣。相關人士可包括但不限於香港的執法機關或其他政府機構、監管機構或行業公會。本行可透過電子或其他方式向閣下傳送警示。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">
            本行無須負責閣下或任何其他人士因任何防詐資料庫提供或未有提供任何資料，或因其延誤、無法使用、中斷、故障或錯誤而可能引致或蒙受的任何種類的損失、損害或開支，或本行可合理控制以外的情況而可能引致或蒙受的任何種類的損失、損害或開支。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">
            本行無須負責閣下或任何其他人士有關或因警示（或其延誤或無法傳送），或有關或因處理、執行或取消警示（或因其延誤或無法傳送）所涉的轉賬交易，而可能引致或蒙受的任何種類的損失、損害或開支，除非所引致或蒙受的任何損失、損害或開支是由於本行的欺詐、疏忽或故意失責引致。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">
            在任何情況下，就任何收益損失或任何特別、間接、附帶、相應而生或懲罰性損失或損害賠償（不論是否可預見或可能招致），本行、本行的關聯公司或集團公司、本行的特許人及上述彼等各自的人員、僱員及代理均無須向閣下或任何其他人士負責。
          </span>
        </p>
        <p class="level1">
          <span class="left">2.7</span>
          <span class="right">
            本條款的内容均無意排除或限制任何不能合法地排除或限制的權利或責任。
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">閣下的責任</span>
        </h3>
        <p class="level1">
          <span class="left">3.1</span>
          <span class="right">
            閣下有責任採取合理可行的步驟以保障閣下自身的利益、資金及資產免受欺詐或其他非法活動的損害。閣下每次均有責任查證及確保收款人、收款人戶口、交易及交易詳情實屬真確並可靠。閣下應認真考慮是否進行或取消一項警示所涉的轉賬交易。閣下就進行或取消一項警示所涉的轉賬交易的決定均對閣下具約束力，且閣下應為後果負全責。
          </span>
        </p>
      </section>
      <br>

      <p>更新：2024年 4月 18 日</p>


</body></html>
`;
