export default `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8" lang="EN-US">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<title>T&amp;C_en</title>
    <style>
		.level1{
		margin-left:1.5cm;
	    text-indent:-30pt;
		}
        .level1 .left{		
		    display: inline-block;
			width: 15px;
			text-align:right}
        .level1 .right{
		    margin-left:0.5cm;
			text-align:left}	
	
        .list{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-24pt;
		text-align:left;
		}

		.item .left{	
		    display: inline-block;	
			text-align:right;}
        .item .right{
		    margin-left:0.9cm;
			text-align:left}
			
        .list2{
		list-style: none;		 
		margin-left:1.5cm;
	    text-indent:-24pt;
		text-align:left;
		}
		
		.item2 .left{	
		    display: inline-block;			
			width: 15px;
			text-align:right}
        .item2 .right{	
		    margin-left:0.3cm;
			text-align:left}

        .list3{
		list-style: none;		 
		margin-left:0.4cm;
	    text-indent:-20pt;
		text-align:left;
		}

		.item3 .left{
		    width:15px;
			text-align:left;}
        .item3 .right{	
		    margin-left:0.2cm;	
			text-align:left}
        h2 {
            font-size: 16px;
            font-weight: 700;
        }
        
        h3 {
            font-size: 14px;
            font-weight: 700;
        }

    </style>
</head>
<body>  
      
      <br>
      <section>
        <p style="margin-left:0.8cm;">
          The terms of the services available on our Internet Banking platform are set out below. Please read them carefully before you proceed to the next page:
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">
                <b>A. </b>
              </span>
              <span class="right">
                <b>Terms and Conditions for Internet Banking Services</b>, which sets out terms and conditions for your access and use of the HSBC Global Private Banking internet banking platform;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>B. </b>
              </span>
              <span class="right">
                <b>Terms and Conditions for HSBC Private Banking Insights &amp; Research</b>, which sets out additional terms and conditions for HSBC Private Banking Insights &amp; Research which you may access and use through the HSBC Global Private Banking internet banking  platform;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>C. </b>
              </span>
              <span class="right">
                <b>Terms and Conditions for Push Notification Service</b>, which sets out additional terms and conditions for our provision of Push Notification Services to you in respect of products and services you can access or use through the HSBC Global Private Banking internet banking  platform; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>D. </b>
              </span>
              <span class="right">
                <b>Terms and Conditions for eSignature Service</b>, which sets out additional terms and conditions application to any document executed using eSignature.
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">
                <b>E. </b>
              </span>
              <span class="right">
                <b>Terms and Conditions for the Suspicious Proxy Alert Model </b>
                which relates to alerts for potentially suspicious money transfers. 
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center" style="font-size: 16px;font-weight: 700;">A.	Terms and Conditions for Internet Banking Services</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">Definitions and Interpretation</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">Unless otherwise defined here, expressions defined in our Standard Terms and Conditions (as found
            <a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20English%20version.pdf">here</a>, the "<b>Standard Terms</b>") as may be amended from time to time shall have the same meaning when used in these Terms and Conditions for Internet Banking Services (the "<b>Internet Banking Terms</b>"). If there are any inconsistencies between these Internet Banking Terms and the Standard Terms, these Internet Banking Terms shall prevail.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">
            In these Internet Banking Terms, the following capitalised terms shall have the following meanings unless the context requires otherwise:
          </span>
        </p>
        <ol class="list">
          <li class="item">
            <p>
              <span class="right">
                "<b>Authorized Internet User</b>" means such person(s) designated by (and may include) you with authority to use the Internet Banking Services in respect of your Account(s).
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Information</b>" means financial, market or other information and data made available by us via any Internet Site, supplied by any Information Provider, including any such information contained in "Publications" as defined in Part B.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Information Provider</b>" means any person or entity, including us and any Group Office who supplies Information and any other person or entity who supplies any information to an Information Provider.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Internet Site</b>" means any Internet site or other platform established, operated and/or maintained by or on behalf of us for the provision of the Internet Banking Services and includes the Mobile Banking Application.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Internet Banking Services</b>" means the services described in Clause 2 (<i>Internet Banking Services</i>) below, as may be amended from time to time and includes the I&amp;R Services provided under Part B, Mobile Banking Application Services and eSignature provided under Part D.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Internet Banking Terms</b>" means these Terms and Conditions for Internet Banking Services.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Mobile Banking Application</b>" means the application made available by us and downloaded to your mobile device through which the Mobile Banking Application Services may be accessed.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Mobile Banking Application Services</b>" means services made available to you to enable you to access Internet Banking Services using the Mobile Banking Application, including the Push Notification Service as defined in Part C.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Password</b>" means the password used by the Authorized Internet User for the purposes of accessing the Internet Banking Services.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Reports</b>" means any reports compiled from Information in any form, medium or means, including any "Publications" as defined in Part B.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Security Code</b>" means any code provided by us and used for the purposes of accessing the Internet Banking Services.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Standard Terms</b>" has the meaning ascribed to it in Clause 1 (<i>Definitions and Interpretation</i>) of these Internet Banking Terms.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>Us</b>", "<b>our</b>", "<b>we</b>" or words to that or a similar effect means the Bank.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>User ID</b>" means the Authorized Internet User’s user identification code accepted by us for the purposes of accessing the Internet Banking Services.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">
                "<b>You</b>", "<b>your</b>" or words to that or a similar effect means the Customer and, where the context permits, includes any person authorized by the Customer to give instructions or requests to us in connection with the use of the Internet Banking Services, including any Authorized Internet User.
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">Internet Banking Services</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">We may make available through any Internet Site online services from time to time to enable you to give Communication to us for the purposes of operating the Customer Account(s), conducting banking, investment, financial and other transactions and dealings of various nature and obtaining services, products, Information, Reports, benefits and privileges from us as shall be made available from time to time.</span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">
            We have the right to determine and vary from time to time the scope and type of the Internet Banking Services to be made available including, without limitation:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">expanding, modifying or reducing the Internet Banking Services at any time;</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">
                imposing and varying any restrictions on the use of the Internet Banking Services (or any part thereof) such as minimum and maximum daily limits with respect to the value of any transaction or dealing or type of transactions or dealings which you may conduct by using the Internet Banking Services; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">prescribing and changing the normal service hours during which the Internet Banking Services (or any part thereof) are available and any daily cut-off time for any type of Internet Banking Services, transactions or dealings. Any instruction of yours received after any applicable daily cut off time shall be deemed to be received on the next Business Day. We may specify Business Days and daily cut-off times by reference to the time of various markets operating in different time zones.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">We may, in our discretion, suspend or terminate the Internet Banking Services generally or in relation to any Customer Account or group of Customer Accounts provided that in any such instance, we shall provide you with as much notice as possible if you are affected provided that in circumstances where it is reasonably necessary to protect our legitimate interests or where required by any Laws or in relation to Financial Crime Management Activity (including but not limited to fraud prevention) when suspension or termination may be made without prior notice. In the event your access to the Internet Banking Services is suspended, restricted or terminated, we may close any accounts, products or services that are only available through the Internet Banking Services, you will no longer receive digital statements, and you should delete our Mobile Banking Application from your device (if applicable).
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">We may require you to nominate or register specific Customer Account(s) for the purposes of the Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">You may terminate the use of the Internet Banking Services and/or the authority of any Authorized Internet User at any time by giving to us prior written notice.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">All provisions of these Internet Banking Terms which in order to give effect to their meaning need to survive the suspension or termination of the Internet Banking Services and/or your use of the Internet Banking Services shall remain in full force and effect after suspension or termination including, without limitation, Clauses 4 (<i>General Disclaimers</i>), 7 (<i>User ID and Password &amp; Security</i>), 9 (<i>Information and Information Providers</i>), 11 (<i>Liabilities of the Bank</i>) and 12 (<i>Liabilities of the Customer</i>). Notwithstanding such suspension or termination, you shall continue to be bound by these Internet Banking Terms to the extent that they relate to any of your obligations or liabilities which remain to be performed or discharged.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">Governing Terms</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">Any transactions and dealings effected by using the Internet Banking Services are subject to these Internet Banking Terms, the Standard Terms and all other Account Documentation governing the relevant Customer Account(s), transactions, dealings, services, products, Information, Reports, benefits or privileges.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4</span>
          <span class="right">General Disclaimers</span>
        </h3>
        <p class="level1">
          <span class="left">4.1</span>
          <span class="right">All transactions that you may enter into through the Internet Banking Services (as made available from time to time) are conducted on an execution-only basis and based on your own judgment. We do not have any duty to assess or ensure suitability of the products or the transactions that you enter into through the Internet Banking Services. This clause 4.1 shall not apply where expressly excluded by the Bank in writing.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.2</span>
          <span class="right">A solicitation or recommendation we may have made to or for you for which applicable Laws require a suitability assessment be made is valid only as at the time it was made, and we cannot ensure on-going suitability of such product. By executing a transaction for a product through use of Internet Banking Services, you understand and accept that if the circumstances relating to you, such a product or its issuer or market conditions change, the product may no longer be suitable for you and you trade using the Internet Banking Services at your own risk.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.3</span>
          <span class="right">You are responsible for determining independently market prices and rates for trading purposes through your usual trading channels, verifying any Information and/or Report before relying or acting on it and seeking independent professional advice on legal, tax and other issues in connection with the use of the Internet Banking Services, the Information and the Reports and any transactions and dealings which may affect you under all applicable Laws.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.4</span>
          <span class="right">Any exchange rate, interest rate, dealing rate and other prices and Information quoted by us on the Internet Site(s) or otherwise in response to an online inquiry is for reference only and is not binding. Any rate, price and Information offered by us for the purpose of the relevant transaction shall be binding on you upon you confirming acceptance, irrespective of any different rate, price or Information quoted by us.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.5</span>
          <span class="right">You understand that there are risks associated with the use of services through the internet or through email, including but not limited to unauthorized access, system outages, delays and disruption in telecommunications services and the internet.  Response times for Internet Banking Services may vary due to market conditions, system performance, access device location or other factors, and that access to Information may be limited or unavailable during periods of peak demand, market volatility, system upgrades, maintenance, or for any other reason. You acknowledge that interruption or malfunction of communications, failure of software equipment, malfunction of the your or our computer system for any reason, or failure to keep your device browser or operating system or the Mobile Banking Application up to date may prevent or restrict your ability to access the Internet Banking Services, and that we will have no liability in respect of any such interruption or malfunction where the interruption or malfunction is beyond our reasonable control.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.6</span>
          <span class="right">The Internet Banking Services are only available in selected jurisdictions. Information which is accessible using the Internet Banking Services is not intended for distribution to, or use by, any person in any jurisdiction where such distribution would be contrary to any law or regulation of that jurisdiction. We do not guarantee that the Internet Banking Services or the Information therein complies with the Laws of, or are appropriate for use in, other jurisdictions.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.7</span>
          <span class="right">You acknowledge  that any products or services you may view on or through the Internet Banking Services may not be registered or authorized by any central bank, governmental or regulatory authority outside of any jurisdiction where we are licensed or authorized to provide such products or services or in your place of residence or location. Accordingly, you may not be protected by the securities laws, banking laws, insurance laws or other relevant Laws of any jurisdiction outside of where we are licensed or authorized to provide such products or services or your place of residence with respect to products or services you may view on or through Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.8</span>
          <span class="right">If you access and/or download our Mobile Banking Application through a mobile application store, you acknowledge that:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">we are responsible for the performance of our Mobile Banking Application and that you should contact us regarding any maintenance and support issues, content issues, malfunctions or other claims related to our Mobile Banking Application; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">when you use our Mobile Banking Application, such mobile application stores are considered as third party beneficiaries for the purposes of these Internet Banking Terms and they may  rely on these Internet Banking Terms.
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">5</span>
          <span class="right">Authorized Internet Users</span>
        </h3>
        <p class="level1">
          <span class="left">5.1</span>
          <span class="right">You shall:</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">designate the persons authorized by you to use the Internet Banking Services in respect of the Customer Account(s) and the scope of the authority of each such person in such manner and form as we may prescribe from time to time;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">advise us of any change in the mailing address and/or email address of the Authorized Internet User(s); and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">ensure that all Authorized Internet Users comply with these Internet Banking Terms and the Standard Terms.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">5.2</span>
          <span class="right">You and each Authorized Internet User shall provide such information as we may from time to time reasonably request for the provision of the Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">5.3</span>
          <span class="right">You are responsible for the use of the Internet Banking Services, for taking appropriate measures to monitor and control the use of the Internet Banking Services and the appointment and change of the Authorized Internet Users, and to adopt proper safeguards against the Internet Banking Services being used by unauthorized persons or for unauthorized purposes, including but not limited to those measures set out in Clause 7 (<i>User ID and Password</i>) of these Internet Banking Terms.
          </span>
        </p>
        <p class="level1">
          <span class="left">5.4</span>
          <span class="right">Where you have appointed an Authorized Internet User to use any Internet Banking Service, such Authorized Internet User accepts and agrees to these Internet Banking Terms for and on your behalf, and you agree to be bound by such acceptance and/or agreement by the Authorized Internet User.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">6</span>
          <span class="right">Use of the Internet Banking Services</span>
        </h3>
        <p class="level1">
          <span class="left">6.1</span>
          <span class="right">The Internet Banking Services are for the sole and exclusive use of Customers subscribing for the Internet Banking Services.</span>
        </p>
        <p class="level1">
          <span class="left">6.2</span>
          <span class="right">You shall neither do nor attempt to do any of the following:</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">decompile, reverse engineer, translate, convert, adapt, alter, modify, enhance, add to, delete or in any way tamper with, or gain access to, any part of the Internet Banking Services or any Internet Site or any software comprised in them;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">use or knowingly allow any other person to use the Internet Banking Services, the Information and/or the Reports for or in connection with any illegal purpose or activity;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">remove or attempt to remove any titles or trademarks, copyrights or restricted rights notices in the Internet Banking Services;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">use any automated tools (i.e. bots) to access the Internet Banking Services or perform activities; and/or
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">permit the use of any security testing tools in order to provide, scan, or attempt to penetrate or assess the security of the Internet Banking Services,
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">and shall notify us as soon as is practicable if you become aware of any such activity.</span>
        </p>
        <p class="level1">
          <span class="left">6.3</span>
          <span class="right">You must not upload or distribute files that contain viruses or other malicious code to the Internet Banking Services or do anything else to disrupt or attempt to disrupt, the systems and networks used for the provision of the Internet Banking Services. On becoming aware of an actual or suspected introduction of a virus or other malicious code to the Internet Banking Services you must notify us promptly and cooperate with us in mitigating the effects of such virus or malicious code.
          </span>
        </p>
        <p class="level1">
          <span class="left">6.4 </span>
          <span class="right">You acknowledge that there may be a time lag in transmission of Information or Communication via the Internet.
          </span>
        </p>
        <p class="level1">
          <span class="left">6.5</span>
          <span class="right">You acknowledge that:</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">any information provided by you  in connection with your use of the Internet Banking Services may be disclosed to or made accessible within our business, any Group Office, and/or our authorized service providers who may be located in another jurisdiction; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">your use of the Internet Banking Services may be monitored by us, any Group Office and/or our authorized service providers and that any resulting information may be collected and used by us for our internal business considerations or for any applicable Law,
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">all in accordance with the terms of the Standard Terms and Conditions and you consent to such disclosure, monitoring and/or use.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">7</span>
          <span class="right">User ID and Password &amp; Security</span>
        </h3>
        <p class="level1">
          <span class="left">7.1</span>
          <span class="right">Each Authorized Internet User shall comply with such procedures for designating his/her User ID and Password as we may prescribe from time to time.
          </span>
        </p>
        <p class="level1">
          <span class="left">7.2</span>
          <span class="right">Each Authorized Internet User may change the User ID and Password at any time but any change shall be effective only if accepted by us.
          </span>
        </p>
        <p class="level1">
          <span class="left">7.3</span>
          <span class="right">We may, in our sole discretion, require you to use a Security Code to access the Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">7.4</span>
          <span class="right">Each Authorized Internet User shall act in good faith, exercise reasonable care and diligence and take all reasonable precautions in keeping safe and prevent fraudulent use of the User ID, the Password and the Security Code. At no time and under no circumstances shall any Authorized Internet User disclose the User ID, the Password and/or the Security Code to any other person.
          </span>
        </p>
        <p class="level1">
          <span class="left">7.5</span>
          <span class="right">You are fully responsible for any accidental or unauthorized disclosure of the User ID, the Password and/or the Security Code of any Authorized Internet User to any other person and shall bear the risks of the User ID, the Password, the Security Code or mobile device of any Authorized Internet User being used by unauthorized persons or for unauthorized purposes.
          </span>
        </p>
        <p class="level1">
          <span class="left">7.6</span>
          <span class="right">If you activate the feature that allows you to use your biometric credentials (such as fingerprint identity or face recognition) in the Mobile Banking Application and enables the use of such biometric credentials to log on to the Mobile Banking Application (or any other Internet Banking Services that we may support from time to time (for compatible devices only)), you must ensure that only your own biometric credentials are registered on the device. You acknowledge and agree that:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">we have no control over the technology, availability, capability, verification method, security and integrity of the mobile device and cannot be held responsible for any unauthorized access to the Internet Banking Services using biometric credentials; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">you must not take any action to disable any function provided by, or agree to any settings of, your device that would otherwise compromise the security of the use of your biometric credentials for authentication purposes (e.g. disabling "attention-aware" for facial recognition).
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">7.7</span>
          <span class="right">Upon notice or suspicion of an Authorized Internet User’s User ID, the Password and/or the Security Code being disclosed to any unauthorized person or any unauthorized use of the Internet Banking Services being made, you or the relevant Authorized Internet User shall notify us in person as soon as practicable or by telephone at such telephone number(s) as we may from time to time prescribe. We may ask you or the relevant Authorized Internet User to confirm in writing any details given and until our actual receipt of such notification, you shall remain responsible for any and all use of the Internet Banking Services by unauthorized persons or for unauthorized purposes.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">8</span>
          <span class="right">Customer’s instructions</span>
        </h3>
        <p class="level1">
          <span class="left">8.1</span>
          <span class="right">Each Authorized Internet User shall enter his/her User ID, Password, the Security Code and any other identifiers as shall be prescribed by us from time to time to logon at the Internet Site to use the Internet Banking Services and give Communication on your behalf to us on-line. We are authorized to act on the Communication of each Authorized Internet User.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.2</span>
          <span class="right">Any Communication must be sent by the Authorized Internet Users to us at such website address and contact point as we may specify from time to time. Unless otherwise expressly agreed by us, Authorized Internet Users shall not send any Communication to our individual officers.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.3</span>
          <span class="right">A Communication shall not be considered to be received by us unless it is given in such manner as we may prescribe from time to time and until we have actually received it.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.4</span>
          <span class="right">Any Communication, once given, may not be rescinded or withdrawn without our consent. We shall be under no duty to verify the identity or authority of the person sending any Communication or the authenticity of any Communication apart from verifying the User ID, the Password, the Security Code and such other identifiers (if any) of the relevant Authorized Internet User.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.5</span>
          <span class="right">We will only act on a Communication insofar as it is in our opinion practicable and reasonable to do so and in accordance with our regular business practices and procedures. We have a right to delay acting or refuse to act on a Communication if we are aware of or suspect a breach of security or other suspicious circumstances relating to the use of Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.6</span>
          <span class="right">All transactions effected by us for you pursuant to a Communication shall be binding on you.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.7</span>
          <span class="right">Advice or Confirmation that a Communication has been received by us and/or a transaction or dealing has been effected may be provided by us on-line if you have so elected and agreed. You agree to  check such advice or Confirmation to ensure its accuracy and completeness. You will enquire  with us if you do not receive an advice or Confirmation  or if you believe that any information in such advice or Confirmation is inaccurate or incomplete.
          </span>
        </p>
        <p class="level1">
          <span class="left">8.8</span>
          <span class="right">Information relating to any account, transaction or dealing made available on the Internet Site(s) are for reference only. Our records of such account, transaction or dealing shall in absence of fraud or manifest error be  evidence thereof.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">9</span>
          <span class="right">Information Providers</span>
        </h3>
        <p class="level1">
          <span class="left">9.1</span>
          <span class="right">An Information Provider may impose from time to time terms and conditions in relation to the availability of any Information supplied by it. Access to such Information by you on or after the effective date, subject to prior notification, of such terms and conditions shall constitute your acceptance thereof.
          </span>
        </p>
        <p class="level1">
          <span class="left">9.2</span>
          <span class="right">The Information and the Reports are made available for reference only.</span>
        </p>
        <p class="level1">
          <span class="left">9.3</span>
          <span class="right">Except to the extent required by applicable Laws or provided for in any Relevant Document, neither we nor any Information Provider warrants, represents or guarantees the sequence, accuracy, truth, reliability, adequacy, timeliness or completeness of any of the Information or the Reports or whether it is fit for any purpose. You agree to independently assess and verify such matters in any Information or Report and, except to the extent required by applicable Laws or provided for in any Relevant Document, neither we nor any Information Provider assumes any liability (whether in tort or contract or otherwise) for any reliance on the Information or the Reports by you or any other person. However, this clause does not preclude any responsibility that we or the Information Provider may have to you or any other person in relation to conduct, statements or representations that we or the Information Provider may have under applicable Laws.
          </span>
        </p>
        <p class="level1">
          <span class="left">9.4</span>
          <span class="right">The Information will be made available as it is supplied by the Information Provider. We do not endorse or express any comment on any Information supplied by any other Information Provider nor assume any duty to check or verify any Information.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">10</span>
          <span class="right">Confidential Information</span>
        </h3>
        <p class="level1">
          <span class="left">10.1</span>
          <span class="right">Confidential Information are trade secrets and confidential and proprietary property of us and the respective Information Providers.
          </span>
        </p>
        <p class="level1">
          <span class="left">10.2</span>
          <span class="right">Unless expressly permitted by these Internet Banking Terms or us, you shall neither do nor attempt to do any of the following:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">sell, transfer, disclose, assign, convey, lease, sub-license, share, loan, distribute, transmit, broadcast, cablecast, put in circulation, duplicate or otherwise provide or disseminate any Confidential Information in any form or by any means to any other person or commercially exploit any Confidential Information;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">download or reproduce any Confidential Information other than for your own personal reference and use; and/or
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">remove, obliterate, erase, relocate or modify in any way any proprietary marking on or appearing with the Confidential Information including, without limitation, any trademark or copyright notice.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.3</span>
          <span class="right">The restrictions on disclosure shall not apply to any Confidential Information:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">where its disclosure is compelled by law but only to the extent required by law and only after written notice of the requirement to disclose has been given by you to us; or
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">where we have expressly agreed in writing to its disclosure.</span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">10.4</span>
          <span class="right">All right, title and interest in and relating to the Confidential Information and any and all related copyright, patent, trademark, service mark, proprietary property, trade secrets and exclusive works are and shall remain the exclusive property of us and the respective Information Providers. No right, title or interest other than the right to access the Information and the Reports subject to these Internet Banking Terms is conveyed or transferred to you. You shall not make any representation or do any act which may be taken to indicate that you have any such right, title or interest.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">11</span>
          <span class="right">Liabilities of the Bank</span>
        </h3>
        <p class="level1">
          <span class="left">11.1</span>
          <span class="right">We will take reasonably practicable steps to ensure that our systems in connection with the Internet Banking Services are installed with adequate security designs and to control and manage the risks in operating the systems, taking into account any Laws, circulars, codes of conduct and prevailing market practices which may be applicable to us from time to time.
          </span>
        </p>
        <p class="level1">
          <span class="left">11.2</span>
          <span class="right">None of us, any Group Office or any Information Provider warrants or represents that the Internet Banking Services, the Information and the Reports are free from viruses or other destructive features which may adversely affect your hardware, software or equipment.
          </span>
        </p>
        <p class="level1">
          <span class="left">11.3</span>
          <span class="right">Unless Clause 12.2 below applies or due to our fraud, negligence or wilful default, or the fraud, negligence or wilful default of any Group Office or our/their respective officers or employees, , neither we nor any Group Office assumes any liability or responsibility to you or any other person for the consequences arising from or in connection with:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">the use of the Internet Banking Services and/or access to any Information as a result of such use by you or any other person whether or not authorized;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">any interruption, interception, suspension, delay, loss, unavailability, mutilation or other failure in providing the Internet Banking Services (including, without limitation, any interruption, interception, suspension, delay or failure to generate the Security Code), in transmitting Information or Communication relating to the Internet Banking Services or in connecting with the Internet Site(s) caused by any acts, omissions or circumstances beyond our reasonable control including, without limitation, failure of any communication network, act or omission of any third party service providers, mechanical failure, power failure, malfunction, breakdown, or inadequacy of equipment, installation or facilities, or any Law, codes, directions, regulatory guidelines or government order (whether or not having the force of law); and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">transmission and/or storage of any information and/or data relating to you and/or the Internet Banking Services through or in any system, equipment or instrument of any communication network provider.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">11.4</span>
          <span class="right">Neither we, any Group Office or any Information Provider shall be liable to you or any other person for any incidental, indirect, special, consequential or exemplary damages including, without limitation, any loss of use, revenue, profits or savings. This clause does not apply in circumstances of our/their fraud, negligence or wilful default.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">12</span>
          <span class="right">Liabilities of the Customer</span>
        </h3>
        <p class="level1">
          <span class="left">12.1</span>
          <span class="right">Unless Clause 12.2 below applies or unless there is fraud, negligence or wilful default by us , you shall be fully liable and responsible for all consequences arising from or in connection with the use of the Internet Banking Services and/or access to any Information or Report or any other information as a result of such use by you or any other person whether or not authorized.
          </span>
        </p>
        <p class="level1">
          <span class="left">12.2</span>
          <span class="right">Subject to your obligations under Clause 7 (<i>User ID and Password</i>) and if, in our reasonable opinion, there is no gross negligence or fraud on your part, you shall not be liable for loss or misplacement of funds caused by unauthorized transactions conducted through the use of the Internet Banking Services.
          </span>
        </p>
        <p class="level1">
          <span class="left">12.3</span>
          <span class="right">You shall indemnify us, any Group Office, any Information Provider and their respective officers and employees against all liabilities, claims, demands, Losses, damages, costs, charges and expenses of any kind (including, without limitation, legal costs on a full indemnity basis) which may be reasonably and properly incurred by any of them and all actions or proceedings which may be brought by or against any of them in connection with the provision of the Internet Banking Services, the Information and/or the Reports or the exercise or preservation of our powers and rights under these Internet Banking Terms and the Standard Terms, unless due to our fraud, negligence or wilful default, or the fraud, negligence or wilful default of any Group Office, any Information Provider or our/their respective officers or employees or unless Clause 12.2 above applies.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">13</span>
          <span class="right">Amendments</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">We may amend and/or add to,  these Internet Banking Terms from time to time.  Any revision and/or addition to the Internet Banking Terms shall become effective subject to us giving prior reasonable notice which may be given to you by posting it on the Internet Site(s) or by any other means as we think fit, and shall be binding on you if you continue to maintain or use the Internet Banking Services on or after the effective date of variation.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">14</span>
          <span class="right">Form of Notice and Communication</span>
        </h3>
        <p class="level1">
          <span class="left">14.1</span>
          <span class="right">We shall be entitled to prescribe, from time to time, the form of notice (whether written or any other form) and the mode of Communication with respect to each type of notice to be given pursuant to these Internet Banking Terms.
          </span>
        </p>
        <p class="level1">
          <span class="left">14.2</span>
          <span class="right">Communication sent by e-mail shall be deemed to have been received by you immediately after transmitting to the e-mail address last notified in writing by you to us.
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center" style="font-size: 16px;font-weight: 700;">B.	Terms and Conditions for HSBC Private Banking Insights &amp; Research</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">Introduction to these Terms and Conditions</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">Your use of the HSBC Private Banking Insights &amp; Research Services ("<b>I&amp;R Services</b>") made available by us is governed by:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">these Terms and Conditions for HSBC Private Banking Insights &amp; Research (the "<b>I&amp;R Terms</b>");
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">the Terms and Conditions for Internet Banking Services (the "<b>Internet Banking Terms</b>"); and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">our Standard Terms and Conditions as found
                <a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20English%20version.pdf">here</a>,</span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">together the "<b>Terms</b>". To the extent of any conflict between these I&amp;R Terms and the Internet Banking Terms or the Standard Terms and Conditions, they shall take priority in the order listed above (highest to lowest).   We may amend the I&amp;R Terms from time to time upon giving reasonable notice to you.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">Capitalised terms used but not otherwise defined in the I&amp;R Terms have the same meanings ascribed to them in the Internet Banking Terms and Standard Terms and Conditions.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">For the avoidance of doubt, the terms "Internet Banking Services" and "Internet Site" as defined in the Internet Banking Terms will, respectively, include the I&amp;R Services and the Insights &amp; Research site ("<b>I&amp;R</b>").
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">Access and use of Publications</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">We may provide you and/or your Authorized Internet User with access to I&amp;R (each a "<b>User</b>"), a digital platform which enables the User (among other things) to access certain:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">Global Private Banking macro commentary on financial markets ("<b>GPB Macro Reports</b>");
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">HSBC Global Research reports ("<b>HSBC Research Reports</b>") by Global Research, a subdivision of our global markets division ("<b>HSBC Global Research</b>") and/or
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">non-HSBC third party publications ("<b>Third Party Publications</b>") 
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left"></span>
          <span class="right">
            (collectively referred to herein as "<b>Publications</b>"). 
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">The Publications may discuss financial markets and investment products across several asset classes and may include financial research material covering specific financial instruments and issuers. The types of Publications accessible to each category of User will be determined by us in our discretion.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">Only persons whose details have been provided to and who have been approved by us as Users may use the I&amp;R Services. It is your responsibility to ensure that each User appointed by you has read and understood the Terms. 
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">We will provide each User with access to the Publications via I&amp;R which can be accessed using our Internet Banking Services. Users may also receive Publications via email from their relationship managers or investment counsellors, or by using the subscription alert function on I&amp;R.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">The Publications are provided to each User exclusively in the English language. By nominating any User, you confirm that each User is sufficiently proficient in English to fully understand the contents of the Publications and any important disclaimer or risk warning which they may contain. 
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">No User will try to contact the issuer of any Publication directly, including but not limited to any HSBC Global Research analyst or third party, or any of their representatives or employees.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">Financial Knowledge, Experience, and Literacy</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">The Publications may cover different topics and product categories including, but not limited to, climate change, commodities, convertibles, credit high grade, credit high yield, credit strategy, currencies, economics, equity strategy, fixed income, rates, structured and asset backed securities, equity and structured products. The Publications make reference to market, economic and financial data, concepts, and ratios, as well as other technical information or concepts which require each User to possess a significant degree of financial knowledge, experience, and literacy. By nominating any User, you confirm that each User possesses a sufficient level of financial knowledge, experience, and literacy to read and understand the Publications and their limitations, in particular those mentioned in Clauses 6.1 and 6.2 below, and will exercise independent judgment in evaluating the materials periodically made available through I&amp;R. Any User who experiences difficulty in the comprehension of any of the Publications should contact the relationship manager or investment counsellor in charge of the Customer Account.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4</span>
          <span class="right">Restricted Access and Security and System Availability</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">The Publications are not directed to, intended for distribution to, or use by, any User, as a person or entity being a citizen or resident of, or being located in, any jurisdiction where such distribution, publication, availability or use would be contrary to law or regulation. We will provide each User access to the Publications based on the jurisdiction of each User’s residence of record and on our assessment of the legal and regulatory requirements applicable to each of these jurisdictions. Each User confirms that he/she is not a resident of any country where such access to the Publications might violate local laws or regulations.  It is each User’s responsibility to advise us immediately upon change of the jurisdiction of his/her residence.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">5</span>
          <span class="right">Intellectual Property</span>
        </h3>
        <p class="level1">
          <span class="left"></span>
          <span class="right">The Publications are intended for the exclusive personal use of each User only. The Publications remain property of us, our divisions (other than our private banking division), other members of any Group Office (e.g. HSBC Global Research) or third parties that produce and publish the Publications, and the Users do not acquire any right to reproduce or distribute, in whole or in part and in any manner or form, the Publications or their content without first obtaining our express written consent and only then upon a clear undertaking to ensure, in such permitted use, a full acknowledgement of the source.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">6</span>
          <span class="right">Important Limitations and Warnings on the Content and Use of Publications</span>
        </h3>
        <p class="level1">
          <span class="left">6.1</span>
          <span class="right">No Investment Advice or Recommendation/No Representation on Suitability or Appropriateness
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">The Publications are provided to each User for information purposes only.</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">The Publications do not constitute and should not be construed as legal, tax or investment advice or a solicitation and/or recommendation of any kind from us to you or any User, nor as an offer or invitation from us to you or any User to subscribe to, purchase, redeem or sell any financial instruments, or to enter into any transaction with respect to such instruments. The content of the Publications may not be suitable for your financial situation, investment experience and investment objectives, and we do not make any representation with respect to the suitability or appropriateness to you of any financial instrument or investment strategy presented in the Publications.  The User should exercise their own judgment in deciding whether or not a particular product is appropriate for you taking into account your circumstances. Where appropriate, the User should consider taking professional advice including as to your legal, tax or accounting position. The User should contact your relationship manager or investment counsellor at the Bank if they wish to enter into a transaction for an investment product. No User should make any investment decision based solely on the content of any Publication.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">6.2</span>
          <span class="right">Important Warnings on the Content of Publications</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">Past performance data contained in any Publication should not be taken as an indication or guarantee of current or future performance, and no representation or warranty, express or implied, is made regarding current or future performance. Performance data may not take into account fees, commissions and other expenses incurred in the issue and redemption (or purchase and sale) of financial instruments. The price, value of and income from any financial instruments mentioned in the Publications may fall or rise, and the risk of a total loss of any original amount you may wish to invest, or have invested, in these instruments cannot be excluded.
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">The content of any Publication should only be considered current as of the date of initial publication, without regard to the date on which the User may access such Publication. This includes any opinion or estimate contained in the Publications, which reflect a judgment only at the original date of publication. The Publications may be subject to periodic revision and update by us, and we reserve our rights to delete or modify any content of the Publications at our discretion and without prior notice. While we will attempt to provide Users with timely updates of the Publications, we are under no obligation to do so. Any update provided to Users will be made available through I&amp;R, and it is each User’s responsibility to ensure that he/she is kept abreast of such updates. There may be Publications that are inconsistent with, or may reach different conclusions than, those represented in past Publications. All opinions are reflective of judgments made on the original date of publication. No Group Office is under any obligation to ensure that a Publication is brought to the attention of a recipient of a past Publication. It is the User’s responsibility to check for updates as each User may deem necessary.
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">Each User acknowledges that the HSBC Research Reports and Third Party Publications have not been independently verified by us. Neither we, any Group Office, nor our/ their service providers will have any liability for the accuracy or completeness of the information contained in the Publications, and we expressly disclaim any and all liability for opinions, representations or warranties, expressed in the HSBC Research Reports and Third Party Publications.
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">We may have issued other content/material that may contain some discrepancies with, or  appear to be inconsistent with or reach different conclusions from, the information presented in the Third Party Publications or HSBC Research Reports. Those reflect the different assumptions, views and analytical methods of the authors of this content/material, and we are under no obligation to ensure that any discrepancy or inconsistency is brought to each User’s attention.
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">We, our divisions other than the Private Banking division, our parent company, subsidiaries or other affiliated companies, as well as their respective directors, managers and employees, may have:
              </span>
            </p>
            <ul class="list3">
              <li class="item3">
                <p>
                  <span class="left">(i)</span>
                  <span class="right">previously invested in, are currently invested in, or will in future be invested in securities or financial instruments mentioned in the Publications and may, from time to time, add to or dispose of such investments;
                  </span>
                </p>
              </li>
              <li class="item3">
                <p>
                  <span class="left">(ii)</span>
                  <span class="right">acted as, may currently be acting as, or may in future act as market makers in connection with these securities or financial instruments;
                  </span>
                </p>
              </li>
              <li class="item3">
                <p>
                  <span class="left">(iii)</span>
                  <span class="right">had, may have or may in future have, a relationship with issuers mentioned in the Publications, and may have provided, or will be providing, financial services such as corporate finance, investment banking, or underwriting services; and/or
                  </span>
                </p>
              </li>
              <li class="item3">
                <p>
                  <span class="left">(iv)</span>
                  <span class="right">sat, may currently sit or may in future sit on the executive boards of issuers mentioned in the Publications.
                  </span>
                </p>
              </li>
            </ul>
          </li>
          <li class="item2">
            <p>
              <span class="left"></span>
              <span class="right">HSBC Global Research has procedures in place to identify and manage any potential conflicts of interest that arise in connection with its research business. HSBC Global Research’s analysts and other staff who are involved in the preparation and dissemination of HSBC Research Reports operate and have a management reporting line independent of our investment banking business. Information barriers and related policies and procedures are in place between our investment banking business and HSBC Global Research to ensure that any confidential and/or price sensitive information is handled in an appropriate manner.
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(F)</span>
              <span class="right">Financial instruments mentioned in the Publications are subject to currency, market, liquidity and other risks which may have a positive or adverse effect on their value, price, or income if any. These risks are more extensively described in the documentation relevant to the particular financial instrument, which should be duly considered prior to making any investment. The Publications may otherwise contain specific restrictions/warning clauses which each User should read carefully, along with any disclaimer contained in any Publication.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">6.3</span>
          <span class="right">Out of Scope Reports</span>
        </p>
        <p class="level1">
          <span class="left"></span>
          <span class="right">HSBC Global Research may produce reports covering HSBC Global Research asset allocation and HSBC Global Research model portfolios which are not aligned to our private banking house view. To the extent possible, these reports will usually not be posted on I&amp;R. However, it is possible that content from and references to these reports can appear in the other HSBC Research Reports which are made available to our private banking clients. When in doubt regarding the content of any of the research reports made available on I&amp;R, the User should contact your relationship manager or investment counsellor in the first instance.
          </span>
        </p>
        <p class="level1">
          <span class="left">6.4</span>
          <span class="right">Further Disclosures</span>
        </p>
        <p class="level1">
          <span class="left"></span>
          <span class="right">Further disclosures and warnings relating to the use of the I&amp;R Service and the Publications can be found in the "Important Notes and Disclaimers" link contained in each Publication. Each User agrees to read such disclosures and warnings prior to using I&amp;R and any updates to them from time to time.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">7</span>
          <span class="right">Termination/Suspension of Access and Amendment</span>
        </h3>
        <p class="level1">
          <span class="left">7.1</span>
          <span class="right">Without prejudice to our rights to terminate or suspend any service under the Internet Banking Terms, we may suspend or terminate any User’s access to the I&amp;R Services if, we determine in our discretion that any of the Terms have been breached, or for any other reason which, in our opinion, may require such termination or suspension. In particular, and without limiting the generality of the foregoing, we may terminate or suspend any User’s access if such access could contravene any Laws. We may also amend the I&amp;R Terms from time to time upon giving reasonable notice to you.
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center" style="font-size: 16px;font-weight: 700;">C.	Terms and Conditions for Push Notification Service</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">Introduction to these Terms and Conditions</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">Your use of the Push Notification Services (as defined in Clause 1.2 below) made available by us is governed by:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">these Terms and Conditions for Push Notification Service (the "<b>Push Notification Terms</b>"); and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">the Terms and Conditions for Internet Banking Services (the "<b>Internet Banking Terms</b>"); and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">
                our Standard Terms and Conditions as found 
                <a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20English%20version.pdf">here</a>,</span>
            </p>
          </li>
        </ol>

        <p class="level1">
          <span class="left"></span>
          <span class="right">together the "<b>Terms</b>". To the extent of any conflict between these Push Notification Terms and the Internet Banking Term and the Standard Terms and Conditions, they shall take priority in the order listed above (highest to lowest). We may amend the Push Notification Terms from time to time upon giving reasonable notice to you.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">In these Push Notification Terms:</span>
        </p>
        <ol class="list">
          <li class="item">
            <p>
              <span class="right">
                "<b>Message Centre</b>" means the function within our Mobile Banking Application which stores all Push Notifications.
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">"<b>Push Notifications</b>" means messages sent to the Message Centre of our Mobile Banking Application via the Push Notification Service. 
              </span>
            </p>
          </li>
          <li class="item">
            <p>
              <span class="right">"<b>Push Notification Service</b>" means the service which we may provide pursuant to these Push Notification Terms.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">Capitalised terms used but not otherwise defined in these Push Notification Terms have the same meanings ascribed to them in the Internet Banking Terms.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.4</span>
          <span class="right">For the avoidance of doubt, the term "Mobile Banking Application Services" as defined in the Internet Banking Terms will include the Push Notification Service.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">Scope of Push Notification Service</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">
            The Push Notification Service is available only through our Mobile Banking application or such channels as are notified to you from time to time.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">
            You may use the Push Notification Service to receive information and communications electronically through appropriate telecommunications equipment.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">The type of information and communications available through the Push Notification Service will be determined by us in our discretion. For the avoidance of doubt, the Push Notification Service is separate from the eStatement Service as defined under Section IX of our Standard Terms. Push Notifications are not and should not be construed as eStatements.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">We may specify or vary the scope and extent of (or otherwise discontinue) the Push Notification Service and its features from time to time without prior notice.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">We may stop providing you with the Push Notification Service (or vary the scope of the Push Notification Service available to you) without prior notice in order to comply with restrictions under applicable laws or regulations or internal policies relating to such restrictions, without being liable to you.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">Opening and using Push Notification Service</span>
        </h3>
        <p class="level1">
          <span class="left">3.1</span>
          <span class="right">You will enol for the Push Notification Service via our Mobile Banking Application. When you enrol for the Push Notification Service, you will be required to advise us of the type of Push Notifications you want to receive. You may be able to vary this information from time to time after enrolment in such manner or form accepted by us.
          </span>
        </p>
        <p class="level1">
          <span class="left">3.2</span>
          <span class="right">We may restrict the number and type of mobile devices which you use to receive Push Notifications. We may impose different restrictions depending on the type of customers or the type or model of the mobile device.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">4 </span>
          <span class="right">Push Notifications</span>
        </h3>
        <p class="level1">
          <span class="left">4.1</span>
          <span class="right">You should ensure that your mobile device and related services are capable of receiving Push Notifications.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.2</span>
          <span class="right">We only send a Push Notification once and will not re-send it. If you delete the Push Notification which we have sent you and need access to the information, you will need to contact us.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.3</span>
          <span class="right">You should never respond to a request to send your password or account or security details even if the request appears to be sent by us through the Push Notification Service. We will never make such requests.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.4</span>
          <span class="right">If you change your mobile device, you will need to reinstall our Mobile Banking Application.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.5</span>
          <span class="right">All Push Notifications we send are one-way. You will not be able to respond to the Push Notification within the Message Centre.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.6</span>
          <span class="right">The Push Notification Service is dependent on the respective communication services provided by Apple Inc. ("<b>Apple</b>") or Google LLC ("<b>Google</b>"), depending on the type of mobile device. Any delay or failure in delivering Push Notifications arising from any failure or inadequacy of Apple's or Google's services is beyond our control.
          </span>
        </p>
        <p class="level1">
          <span class="left">4.7</span>
          <span class="right">You will need cellular data internet connection or WiFi internet connection in order to receive Push Notifications. If you wish to receive push notification message whilst you are overseas, please check whether you have enabled data roaming. However, please note that the Push Notification Service may not be available to you in certain countries on certain types of mobile devices. You are responsible for any fees and charges arising from internet connection (whether local or international).
          </span>
        </p>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center" style="font-size: 16px;font-weight: 700;">D.	Terms and Conditions for eSignature</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">Introduction to these Terms and Conditions</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">Your use of eSignature, our electronic signing service, to access and electronically sign Account Documentation is governed by:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">these Terms and Conditions for the eSignature Service (the "<b>eSignature Terms</b>"); and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">the Terms and Conditions for Internet Banking Services (the "<b>Internet Banking Terms</b>"), and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">our Standard Terms and Conditions as found 
                <a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20English%20version.pdf">here</a>,</span>
            </p>
          </li>
        </ol>

        <p class="level1">
          <span class="left"></span>
          <span class="right">together the "<b>Terms</b>". To the extent of any conflict between these eSignature Terms and the Internet Banking Terms and the Standards Terms and Conditions, they shall take priority in the order listed above (highest to lowest).  We may amend the eSignature Terms from time to time upon giving reasonable notice to you.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">In these eSignature Terms references to the "<b>Adobe Sign</b>" means the electronic signature products and services provided by the third party platform operator, Adobe Systems Software Ireland Limited, with registered office at 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Ireland ("<b>Adobe</b>"), via the Adobe Sign platform.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">Capitalised terms used but not otherwise defined in these eSignature Terms have the same meanings ascribed to them in the Internet Banking Terms.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.4</span>
          <span class="right">For the avoidance of doubt, the term "Internet Banking Services" as defined in the Internet Banking Terms will include eSignature under this Part D.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">Adobe Terms and Conditions</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">From time to time, you may be prompted on this Internet Site to sign documents electronically. Where you choose to do so, you will be redirected to the Adobe Sign platform which is subject to Adobe’s own specific terms and conditions, privacy and cookie statements including disclaimers, and which will form a separate legal agreement between you and Adobe ("<b>Adobe Terms and Conditions</b>").
          </span>
        </p>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">To the extent that there is any conflict between any of the terms and conditions, they shall take priority in the order listed below (highest to lowest):
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">Adobe Terms and Conditions; and</span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">these eSignature Terms.</span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">User Acknowledgements</span>
        </h3>
        <p class="level1">
          <span class="left">3.1</span>
          <span class="right">By signing documents electronically on the Adobe Sign platform, you and/or your Authorized Internet User (each a "<b>User</b>") acknowledges and agrees for yourself and on your behalf that:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">you authorize and provide your consent for us to collect and link your signature to the documents in electronic form using the Adobe Sign platform – such documents together with the collected and linked signatures are referred to as the "<b>Electronic Documents</b>";
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">we use the services of Adobe, a third party service provider to collect and link your signatures to the Electronic Documents. When we redirect you to the Adobe Sign platform, you will be able to access the documents  which are available for signing electronically. By accessing the Adobe Sign platform you agree directly with Adobe to the Adobe Terms and Conditions applicable to your use of the Adobe Sign platform. Neither we nor any Group Office is responsible for the operation of the Adobe Sign platform or its services and functionalities. The use of the Adobe Sign platform shall be at your own risk; neither we nor any Group Office shall be responsible or liable for any damages or in any other way in connection with your access and use of the Adobe Sign platform;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">no endorsement, approval or responsibility on our part or any Group Office for the appropriateness of Adobe or its advice, opinions, information, products or services is expressed or implied by any information on this Internet Site or by the fact that you will be redirected to the Adobe Sign Platform;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(D)</span>
              <span class="right">you will read the Electronic Document in full prior to signing and your signature on each Electronic Document is attached by you, confirming your intention to be bound by the Electronic Document. You have not and will not permit any other person to assist you in attaching your signature to any Electronic Document;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(E)</span>
              <span class="right">your signature on each Electronic Document shall be reflected clearly and accurately on the Electronic Document and your signature collected, received and/or stored in such electronic form shall be deemed to be equivalent to your signature in hard copy for all purposes subject to verification by us that such signature complies with our internal requirements;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(F)</span>
              <span class="right">the Electronic Documents maintained by us or on behalf of us to which your signatures in electronic form have been attached shall be deemed to be valid, accurate and authentic, and shall be given the same effect as though such Electronic Documents were written and had been signed by you in hard copy;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(G)</span>
              <span class="right">unless otherwise required by Laws, we are entitled to rely on your signature collected in any Electronic Document as valid and enforceable;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(H)</span>
              <span class="right">any Electronic Document or any evidence in the form of activity or transaction logs, computer or electronic records, computer printouts or any other form of computer or electronic data or information storage or system (collectively referred to as "<b>Electronic Records</b>") shall be a record of the information and your instructions, consents, acknowledgments and agreements in respect of any matter set out in the Electronic Documents and the Electronic Records can be used as evidence in any court proceedings as proof of their contents;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(I)</span>
              <span class="right">if you require a copy of your Electronic Documents at any time, you may reach out to your relationship manager;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(J)</span>
              <span class="right">if you disagree with or have any queries about the contents of the Electronic Documents, you may leave the signing process by closing the browser at any point before you click on the final ‘Click To Sign’ button. All the Electronic Documents are considered to be executed only at the time when you click on the final ‘Click To Sign’ button which appears at the end of all the Electronic Documents; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(K)</span>
              <span class="right">the execution of the Electronic Documents by you does not mean that your instructions are accepted by us. The Electronic Documents will still have to be verified and approved by us and the Electronic Documents shall be effective only after they have been approved by us.
              </span>
            </p>
          </li>
        </ol>
      </section>
      <section>
        <br>
        <div>
          <h2 align="center" style="font-size: 16px;font-weight: 700;">E.	Terms and Conditions for Suspicious Proxy Alert Model</h2>
        </div>
        <br>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">1</span>
          <span class="right">Introduction to these Terms and Conditions</span>
        </h3>
        <p class="level1">
          <span class="left">1.1</span>
          <span class="right">These terms relate to your use of the Suspicious Proxy Alert Model (the "<b>Alert Model</b>"), which is a service to alert you of potentially suspicious money transfers when using the Mobile Banking Application  (the "<b>Service</b>") and apply only to accounts booked in Hong Kong.  Use of the Serviceis governed by:
          </span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">these Terms and Conditions relating to the Alert Model (the "<b>Alert Model Terms</b>"); and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">the Terms and Conditions for Internet Banking Services (the "<b>Internet Banking Terms</b>"), and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">our Standard Terms and Conditions as found 
                <a href="https://www.privatebanking.hsbc.com/content/dam/privatebanking/gpb/utilities/mobile/Standard%20Terms%20and%20Conditions%20-%20November%2022%20edition%20-%20English%20version.pdf">here</a>,</span>
            </p>
          </li>
        </ol>

        <p class="level1">
          <span class="left"></span>
          <span class="right">together the “<b>Terms</b>”. To the extent of any conflict between these Alert Model Terms and the Internet Banking Terms and the Standards Terms and Conditions, they shall take priority in the order listed above (highest to lowest).  The Alert Model Terms will take effect on such date as the Service is made available on the Mobile Banking Application.  We may amend the Alert Model Terms from time to time upon giving reasonable notice to you.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.2</span>
          <span class="right">In these Alert Model Terms:</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">“Alert” means a warning message that a Money Transfer or the relevant payee or payee account may involve fraud or a scam;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">“Anti-fraud Database” includes any anti-fraud search engine and/or anti-deception database operated or managed by the Hong Kong Police Force or any other law enforcement agency or governmental body or regulatory authority of Hong Kong, whether it is accessible by the public in general or by designated entities or organisations (including but not limited to the Hong Kong Police Force’s Scameter)
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">“Money Transfer” means a transfer of money by you through the Bank via the Mobile Banking Application in any currency determined by us from time to time whether the payee account is maintained with the Bank or not; and if the context requires or permits, includes an instruction given by you to the Bank to make a Money Transfer.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">1.3</span>
          <span class="right">By making any Money Transfer on or after the date on which these Alert Model Terms come into effect, you confirm that you have accepted and will be bound by these Alert Model Terms.
          </span>
        </p>
        <p class="level1">
          <span class="left">1.4</span>
          <span class="right">The Alerts are intended to help you stay vigilant against frauds, scams and deceptions when making Money Transfers. The Alerts do not replace or remove your responsibility for safeguarding your own interests, money and assets from fraud or other illegal activities.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">2</span>
          <span class="right">The Bank’s Role, Responsibilities and Restriction of Liability</span>
        </h3>
        <p class="level1">
          <span class="left">2.1</span>
          <span class="right">The Bank:</span>
        </p>
        <ol class="list2">
          <li class="item2">
            <p>
              <span class="left">(A)</span>
              <span class="right">does not control the management, operation or any other aspect of the Anti-fraud Databases;
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(B)</span>
              <span class="right">compiles the Alerts solely based on the information available from the Anti-fraud Databases from time to time; and
              </span>
            </p>
          </li>
          <li class="item2">
            <p>
              <span class="left">(C)</span>
              <span class="right">will not compile any Alert relating to a payee, a payee account or a transaction if no information about it is available from the Anti-fraud Databases.
              </span>
            </p>
          </li>
        </ol>
        <p class="level1">
          <span class="left">2.2</span>
          <span class="right">We do not and cannot warrant whether the information available from any Anti-fraud Database is complete, true, accurate or up-to-date, nor that the Money Transfers for which you do not receive Alerts are not fraudulent nor that Money Transfers for which you receive Alerts are fraudulent.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.3</span>
          <span class="right">We may compile and deliver the Alerts in such ways as we consider appropriate. We will have discretion to determine and/or vary, from time to time, the contents of the Alerts, the channels or means through which the Alerts are delivered, and/or the currency(ies) of the Money Transfers, having regard to our needs and the feedback, comments, guidance or recommendations from the relevant persons. Relevant persons may include but are not limited to law enforcement agencies or other governmental bodies, or regulatory authorities or industry associations in Hong Kong. The Bank may deliver the Alerts to you by electronic or other means.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.4</span>
          <span class="right">We are not liable for loss, damage or expense of any kind which you or any other person may incur or suffer arising from any information available or omitted from any Anti-fraud Database, or any delay, unavailability, disruption, failure, error of or caused by any Anti-fraud Database, or arising from any circumstances beyond our reasonable control.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.5</span>
          <span class="right">We are not liable for loss, damage or expense of any kind which you or any other person may incur or suffer arising from or in connection with the Alerts (or any delay or unavailability of the Alerts), or the processing, execution or cancellation of Money Transfers affected by the Alerts (or by any delay or unavailability of the Alerts), except to the extent that any loss, damage or expense incurred or suffered results from our fraud, negligence or wilful default.
          </span>
        </p>
        <p class="level1">
          <span class="left">2.6</span>
          <span class="right">In no event will the Bank, its affiliates or group companies, its licensors, and its and their respective officers, employees and agents be liable to you or any other person for any loss of profit or any special, indirect, incidental, consequential or punitive loss or damages (whether or not they were foreseeable or likely to occur).
          </span>
        </p>
        <p class="level1">
          <span class="left">2.7</span>
          <span class="right">Nothing in these Terms is intended to exclude or restrict any right or liability to the extent it may not be lawfully excluded or restricted.
          </span>
        </p>
      </section>
      <section>
        <h3 class="level1">
          <span class="left">3</span>
          <span class="right">Your Responsibilities</span>
        </h3>
        <p class="level1">
          <span class="left">3.1</span>
          <span class="right">You are responsible for taking reasonably practicable steps to safeguard your own interests, money and assets from fraud or other illegal activities. You are responsible for checking and ensuring that any payee, any payee account, any transaction and any transaction details are real and trustworthy in each case. You should consider carefully whether to proceed with or cancel a Money Transfer affected by an Alert. Your decision to proceed with or cancel a Money Transfer affected by an Alert is binding on you and you shall be solely responsible for the consequences.
          </span>
        </p>
      </section>
      <br>
      <p>Last updated 18 April 2024</p>




</body></html>
`;
