import { history } from "util/history";
import { envConfig } from "../env-config";
import JsEncrypt from "jsencrypt/bin/jsencrypt.min.js";

export function isTextSelected() {
    let selecttxt = "";
    if (window.getSelection) {
        selecttxt = window.getSelection();
    } else if (document.getSelection) {
        selecttxt = document.getSelection();
    } else if (document.selection) {
        selecttxt = document.selection.createRange().text;
    }
    if (selecttxt === "") {
        return false;
    }
    return true;
}

let initializedTransmitSdkPromise;
export function promiseInitializedTransmitSdk() {
    if (!initializedTransmitSdkPromise) {
        initializedTransmitSdkPromise = new Promise((accept, reject) => {
            window.requirejs(["XmSdk"], function(xm) {
                const sdk = xm.XmSdk();
                const endpoint = envConfig.transmitHost;
                const appId = envConfig.multiTenancy
                    ? envConfig.appId
                    : "web_app";
                const apiTokenId = envConfig.multiTenancy
                    ? envConfig.apiTokenId
                    : "logon_token";
                const apiToken = envConfig.multiTenancy
                    ? envConfig.apiToken
                    : "bb6f9545-4c35-4147-a52f-a3ae7a4054aa";
                const connectionSettings = window.com.ts.mobile.sdk.SDKConnectionSettings.create(
                    endpoint,
                    appId,
                    apiTokenId,
                    apiToken
                );

                if (envConfig.multiTenancy) {
                    connectionSettings.setRealm(envConfig.realm);
                }

                if (envConfig.crypto) {
                    connectionSettings.setCryptoMode(envConfig.cryptoMode);
                }
                sdk.setConnectionSettings(connectionSettings);
                sdk.setLocale(envConfig.locale);

                window.transmitSDK = sdk;
                sdk.initialize().then(function() {
                    accept(sdk);
                });
            });
        });
    }
    return initializedTransmitSdkPromise;
}

promiseInitializedTransmitSdk();

let resetIdleTimeOut;
sessionStorage.setItem("idleTimeOutValid", false);
function resetIdle(e, time = 10 * 60 * 1000) {
    if (resetIdleTimeOut) {
        clearTimeout(resetIdleTimeOut);
    }
    resetIdleTimeOut = setTimeout(() => {
        const idleTimeOutValid = sessionStorage.getItem("idleTimeOutValid");
        console.log(
            "Idle time's up, will jump to logon?",
            idleTimeOutValid,
            "current time is:",
            new Date()
        );
        if (idleTimeOutValid !== "false") {
            history.push("/");
            history.push("/logon?forcedLogout");
            sessionStorage.setItem("idleTimeOutValid", false);
        }
        resetIdle();
    }, time);
}
export function changeIdleTimeOutValid(bool) {
    sessionStorage.setItem("idleTimeOutValid", bool);
}

function registeIdleTimeOut() {
    window.addEventListener("mousemove", resetIdle, true);
    window.addEventListener("scroll", resetIdle, true);
    window.addEventListener("mousedown", resetIdle, true);
    window.addEventListener("touchstart", resetIdle, true);
    window.addEventListener("click", resetIdle, true);
    window.addEventListener("keypress", resetIdle, true);
}
registeIdleTimeOut();

export function encryptPassword(password, publicKey) {
    const jse = new JsEncrypt();
    jse.setPublicKey(publicKey);
    return jse.encrypt(password);
}

export function handleMaskNumber(str) {
    if (!str) {
        return null;
    }
    str = str.replace(/\*+/, "masked ");
    for (let i = 0; i < str.length; i++) {
        if (!isNaN(str.charAt(i))) {
            str = str.slice(0, i) + " " + str.slice(i, str.length);
            i++;
        }
    }
    return str;
}

export function guid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        const crypto = window.crypto || window.msCrypto;
        var arr2 = new Uint32Array(32);
        var r = (crypto.getRandomValues(arr2)[0] * Math.pow(2, -28)) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}
