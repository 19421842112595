export default `<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:等线;
	panose-1:2 1 6 0 3 1 1 1 1 1;}
@font-face
	{font-family:Calibri;
	panose-1:2 15 5 2 2 2 4 3 2 4;}
@font-face
	{font-family:"\@等线";
	panose-1:2 1 6 0 3 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:8.0pt;
	margin-left:0cm;
	line-height:107%;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;}
.MsoChpDefault
	{font-size:11.0pt;
	font-family:"Calibri",sans-serif;}
.MsoPapDefault
	{margin-bottom:8.0pt;
	line-height:107%;}
 /* Page Definitions */
 @page WordSection1
	{size:595.3pt 841.9pt;
	margin:72.0pt 72.0pt 72.0pt 72.0pt;}
div.WordSection1
	{page:WordSection1;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>

</head>

<body lang="ZH-CN">

<div class="WordSection1">

<p class="MsoNormal" style="margin-top:.4pt;line-height:22.45pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.45pt">Before we begin</span></p>

<p class="MsoNormal" style="margin-top:7.3pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.1pt">This policy (Privacy Policy) applies to information held
by members of the HSBC Group as data controllers, as described below. It
explains what information we collect about you, how we’ll use that information,
who we’ll share it with, the circumstances when we’ll share it and what steps
we’ll take to make sure it stays private and secure. It continues to apply even
if your agreement for banking or other products and services with us ends. It
should also be read alongside your relevant product terms and conditions, as
these include sections relating to the use and disclosure of information.</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.05pt">This Privacy Policy covers
any private bank products or services you have with us in the UK, including
savings, loans, credit cards, mortgages, investments and insurance. Sometimes
we may need to provide you with separate or further information about specific
products and services. This information will also apply. If you also have a
relationship with other parts of the HSBC Group (e.g. HSBC personal or business
banking, first direct, M&amp;S Bank or HSBC in any other countries outside the
UK), they’ll provide you with information separately where required.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:11.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">Some of the links on our websites lead to other
HSBC or non HSBC websites with their own privacy policies, which may be
different to this policy. You’ll need to make sure you’re happy with their
privacy policies when using those other sites.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:11.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">Wherever we’ve said ‘you’
or ‘your’, this means you, whether you are an individual or an entity, and any
person connected with you including, for example any guarantor, a director,
officer or employee of a company, partners or members of a partnership, any
substantial owner, controlling person, or beneficial owner, trustee, settlor or
protector of a trust, account holder of a designated account, recipient of a
designated payment, your attorney or representative (e.g. authorised
signatories), agent or nominee, any other persons or entities with whom you
have a relationship that’s relevant to your relationship with us.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">Parts of this Policy
relate only to individuals, and we have highlighted this where relevant. To be
clear this includes individuals acting in a business or other non-personal
capacity (e.g. a sole trader, partner in a general partnership, trustee in a
Trust).</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Where
you provide information to us about a connected person it is your
responsibility to draw their attention to this Policy and make sure they agree
to us using their information as described in it. More details about this can
be found in our relevant banking or investment services terms and conditions.</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.2pt">When we say ‘we’, we mean
HSBC Group companies which act as a data controller in respect of your data.
Unless otherwise stated below, the data controller for the purposes of this
policy is HSBC UK Bank plc.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:18.0pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:11.9pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">HSBC
Bank plc will also act as a data controller where it is providing products or
services to you.</span></p>

<p class="MsoNormal" style="line-height:11.95pt;punctuation-wrap:simple;
vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:
&quot;Arial&quot;,sans-serif">The address for the HSBC Group companies set out in this policy
is 8 Canada Square, London E14 5HO. If you’d like to get in touch with us, you
can also find contact details below.</span></p>

<p class="MsoNormal" style="margin-top:12.2pt;margin-right:39.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:19.9pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:17.0pt;font-family:&quot;Arial&quot;,sans-serif">What
information we collect</span></p>

<p class="MsoNormal" style="margin-top:8.0pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">We’ll only collect your
information in line with relevant regulations and law. We may collect it from a
range of sources and it may relate to any of our products or services you apply
for, currently hold or have held in the past. We may also collect information
about you when you interact with us, e.g. visit our websites or mobile
channels, call us, or ask about any of our products and services.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.05pt">Some of it will come directly from you, e.g. when you
provide ID to open an account. It can also come from your financial advisor,
broker or mortgage intermediary, other HSBC companies, or other sources you’ve
asked us to obtain information from. We might also get some of it from publicly
available sources. The information we collect may include:</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><b><span lang="EN-GB" style="font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif">Information
that you provide to us or provided to us on your behalf, e.g.:</span></b></p>

<p class="MsoNormal" style="margin-top:5.75pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">personal details, e.g. name, previous names, gender, date and place of
birth;</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">contact
details, e.g. address, email address, landline and mobile numbers;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
11.95pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">information
concerning your identity e.g. photo ID, passport information, National
Insurance number, National ID card and nationality;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
11.95pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">market
research, e.g. information and opinions expressed when participating in market
research;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:25.2pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.2pt">user login and subscription data, e.g. login credentials for phone and
online banking and mobile banking apps;</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:0cm;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">other information about you that you give us or which is given to us on
your behalf by filling in forms or by communicating with us whether
face-to-face, by phone, email, online, or otherwise</span></p>

<p class="MsoNormal" style="margin-top:9.9pt;margin-right:25.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><b><span lang="EN-GB" style="font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.15pt">Information we collect or generate about you, e.g.:</span></b></p>

<p class="MsoNormal" style="margin-top:5.7pt;margin-right:0cm;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">your
financial information and information about your relationship with us, including
the products and services you hold, the channels you use and your ways of
interacting with us, your ability to get and manage your credit, your payment
history, transactions records, market trades, payments into your account
including salary details and information concerning complaints and disputes;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">information
we use to identify and authenticate you, e.g. your signature and your biometric
information, such as your voice for voice ID, additional information that we
receive from external sources that</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:0cm;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">we need for
compliance purposes;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">geographic
information, e.g. about which branches or ATMs you use;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">information
included in customer documentation, e.g. a record of advice that we may have
given you;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">marketing and
sales information,<span style="letter-spacing:-.05pt">e.g. details of the
services you receive</span> <span style="letter-spacing:-.05pt">and your
preferences;</span></span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">cookies and
similar technologies we use to recognise you, remember your preferences and
tailor the content we provide to you – our cookie policy contains more details
about how we use cookies and can be found at </span><span lang="EN-GB"><a href="http://www.hsbcprivatebank.com/en/utilities/cookie-policy"><span style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:windowtext;
text-decoration:none">hsbcprivatebank.com/en/utilities/ cookie-policy</span></a></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">risk rating
information, e.g. credit risk rating, transactional behaviour and underwriting
information;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">investigations
data, e.g. due diligence checks, sanctions and anti-money laundering checks,
external intelligence reports, content and metadata related to relevant
exchanges of information between and among individuals, organisations,
including emails, voicemail, live chat;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">records of
correspondence and other communications between us, including email, live chat,
instant messages and social media communications;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">information
that we need to support our regulatory obligations, e.g. information about
transaction details, detection of any suspicious and unusual activity and
information about parties connected to you or these activities</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:0cm;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><b><span lang="EN-GB" style="font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif">Information
we collect from other sources, e.g.:</span></b></p>

<p class="MsoNormal" style="margin-top:5.75pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">information
you’ve asked us to collect for you, e.g. information about your accounts or
holdings with other companies including transaction information;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">information from third party providers, e.g. information that helps us
to combat fraud or that relates to your social interactions (including your </span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">communications
via social media, between individuals, organisations, prospects and other
stakeholders acquired from companies that collect combined information)</span></p>

<p class="MsoNormal" style="margin-top:12.05pt;margin-right:0cm;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:22.15pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:.25pt">How we’ll use </span><span lang="EN-GB" style="font-size:
19.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.2pt">your information</span></p>

<p class="MsoNormal" style="margin-top:7.45pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">We’ll
only use your information where we have your consent or we have another lawful
reason for using it. These reasons include where we:</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;margin-right:0cm;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">need to
pursue our legitimate interests;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:3.6pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">need to process the information to carry out an agreement we have with
you;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">need to
process the information to comply with a legal obligation;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:18.0pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">believe the use of your information as described is in the public
interest, e.g. for the purpose of preventing or detecting crime</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;margin-right:28.8pt;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.15pt">The reasons we use your information include to:</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:0cm;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">deliver our products and services;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">carry out
your instructions, e.g. to fulfil a payment request;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">manage our
relationship with you, including (unless you tell us otherwise) telling you
about products and services we think may be relevant for you;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">understand how you use your accounts and services;</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:0cm;margin-bottom:0cm;
margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">banking
operations support;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">prevent or detect crime including fraud and financial crime, e.g.
financing for terrorism and human trafficking;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">security and
business continuity;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">risk
management;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">online
banking, mobile apps and other online product platforms;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">product and
service improvement;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">data
analytics to better understand your circumstances and preferences so we can
make sure we can provide you with the best advice and offer you a tailored
service;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">protect our
legal rights and comply with our legal obligations;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">correspond
with solicitors, surveyors, valuers, other lenders, conveyancers and third
party intermediaries;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">system or
product development and planning, insurance, audit and administrative purposes</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;margin-right:28.8pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Further
details of how we’ll use your information can be found in the Appendix below.</span></p>

<p class="MsoNormal" style="margin-top:11.7pt;line-height:10.25pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.15pt">How we make decisions
about you</span></b></p>

<p class="MsoNormal" style="margin-top:5.4pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">We may use automated systems
to help us make decisions, e.g. for example about particular products and
services you may wish to access and to carry out fraud and money laundering
checks. We may use technology that helps us identify the level of risk involved
in customer or account activity, e.g. for credit, fraud or financial crime
reasons, or to identify if someone else is using your card without your
permission.</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:11.85pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Individuals
may have a right to certain information about how we make these decisions. More
details can be found in the ‘Rights of individuals’ section.</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:11.85pt;punctuation-wrap:simple;vertical-align:
baseline"><b><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Tracking
or recording what you say or do</span></b></p>

<p class="MsoNormal" style="margin-top:5.65pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">To help keep you and your
money safe, we may record details of your interactions with us. We may record
and keep track of conversations you have with us including phone calls, face-to-face
meetings, letters, emails, live chats, video chats and any other kinds of
communication. We may use these recordings to check your instructions to us,
assess, analyse and improve our service, train our people, manage risk or to
prevent and detect fraud and other crimes. We may also capture additional
information about these interactions, e.g. telephone numbers that you call us
from and information about the devices or software that you use. We use closed
circuit television (CCTV) in and around our sites and these may collect photos
or videos of you, or record your voice.</span></p>

<p class="MsoNormal" style="margin-top:9.95pt;margin-right:10.8pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><b><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Compliance
with laws and regulatory compliance obligations</span></b></p>

<p class="MsoNormal" style="margin-top:5.65pt;line-height:11.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.05pt">We’ll use your information
to meet our compliance obligations, to comply with other laws and regulations
and to share with regulators and other authorities that HSBC Group companies
are subject to. This may include using it to help detect or prevent crime
(including terrorism financing, money laundering and other financial crimes).
We’ll only do this on the basis that it’s needed to comply with a legal
obligation or it’s in our legitimate interests and that of others.</span></p>

<p class="MsoNormal" style="margin-top:.85pt;line-height:10.25pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.1pt">Marketing and market
research</span></b></p>

<p class="MsoNormal" style="margin-top:5.7pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">We may use your information to provide you with
details about HSBC products and services, and also products and services from our
partners and other relevant third parties. We may send you marketing messages
by post, email, telephone, text or secure messages. You can change your mind on
how you receive marketing messages or choose to stop receiving them at any
time. To make that change, please contact us in the usual way.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">If you ask us not to send you marketing, it may
take us a short period of time to update our systems and records to reflect
your request, during which time you may continue to receive marketing messages.
Even if you tell us not to send you marketing messages, we’ll continue to use
your contact details to provide you with important information, such as changes
to your terms and conditions or if we need to tell you something to comply with
our regulatory obligations.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:11.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.1pt">We may use your information
for market research and to identify trends. Market research agencies acting on
our behalf may get in touch with you by post, telephone, email or other methods
of communication to invite you to take part in research. We won’t invite you to
take part in research using a communication method if you’ve asked us not to
get in touch that way. Any responses that you provide whilst participating in
market research will be reported back to us anonymously unless you give us
permission for your details to be shared.</span></p>

<p class="MsoNormal" style="margin-top:12.05pt;margin-right:0cm;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:22.15pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:.25pt">Who we might share your information with</span></p>

<p class="MsoNormal" style="margin-top:7.5pt;margin-right:0cm;margin-bottom:8.0pt;
margin-left:3.6pt;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">We
may share your information with others where lawful to do so including where we
or they:</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.2pt">need to in order to provide you with products or services you’ve
requested, e.g. fulfilling a payment request;</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:21.6pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">have a public
or legal duty to do so, e.g. to assist with detecting and preventing fraud, tax
evasion and financial crime;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">need to in
connection with regulatory reporting, litigation or asserting or defending
legal rights and interests;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="fon


t:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="

font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.2pt">have a legitimate business reason for doing so, e.g. to manage risk,
verify your identity, enable another company to provide you with services
you’ve requested, or assess your suitability for products and services;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">have asked
you for your permission to share it, and you’ve agreed</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;margin-right:10.8pt;margin-bottom:
8.0pt;margin-left:3.6pt;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">We
may share your information for these purposes with others including:</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">other HSBC Group companies and any sub-contractors, agents or service
providers who work for us or provide services to us or other HSBC Group
companies (including their employees, sub-contractors, service providers,
directors and officers);</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">any joint account holders, trustees, beneficiaries or executors;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">people who give guarantees or other security for any amounts you owe
us;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">people you make payments to and receive payments from;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">your beneficiaries, intermediaries, correspondent and agent banks,</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">clearing houses, clearing or settlement systems, market counterparties
and any companies you hold securities in through us, e.g. stocks, bonds or
options;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="

font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">other financial institutions, lenders and holders of security over any
property you charge to us, tax authorities, trade associations, credit
reference agencies, payment service providers and debt recovery agents;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">any fund managers who provide asset management services to you and any
brokers who introduce you to us or deal with us for you;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">any entity that has an interest in the products or services that we
provide to you, including if they take on the risk related to them;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">any people or companies where</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">required in connection with potential or actual corporate
restructuring, merger, acquisition or takeover,</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">including any transfer or potential transfer of any of our rights or
duties under our agreement with you;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">law enforcement, government, courts, dispute resolution bodies, our
regulators, auditors and any party appointed or requested by our regulators to
carry out investigations or audits of our activities;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">other parties involved in any disputes, including disputed
transactions;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">fraud prevention agencies who’ll also use it to detect and prevent
fraud and other financial crime and to verify your identity;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">anyone who provides instructions or operates any of your accounts on
your behalf, e.g. Power of Attorney, solicitors, intermediaries, etc;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">anybody else that we’ve been instructed to share your information with
by either you, a joint account holder or anybody else who provides instructions
or operates any of your accounts on your behalf;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">our card processing supplier(s) to carry out credit, fraud and risk
checks, process your payments, issue and manage your card;</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.2pt">·<span style="


font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">insurers so they can provide you with a quote or cover. For further details,
please refer to your policy documentation. You can also refer to </span><span lang="EN-GB"><a href="http://www.hsbc.co.uk"><span style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;color:windowtext;letter-spacing:-.15pt;
text-decoration:none">hsbc.co.uk</span></a></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.15pt">
for more information</span></p>

<p class="MsoNormal" style="margin-top:9.95pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">Sharing aggregated or anonymised information</span></b></p>

<p class="MsoNormal" style="margin-top:5.65pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.1pt">We may share aggregated or anonymised information within
and outside of the HSBC Group with partners such as research groups,
universities or advertisers. You won’t be able to be identified from this
information, e.g. we may share information about general spending trends in the
UK to assist in research.</span></p>

<p class="MsoNormal" style="margin-top:12.05pt;line-height:22.2pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.4pt">How long we’ll keep </span><span lang="EN-GB" style="


font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">information</span></p>

<p class="MsoNormal" style="margin-top:7.4pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">We keep information in
line with our data retention policy. For example, we’ll normally keep your core
banking data for a period of seven years from the end of our relationship with
you. This enables us to comply with legal and regulatory requirements or use</span></p>

<p class="MsoNormal" style="margin-right:7.2pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">it where we need to for our legitimate purposes
such as managing your account and dealing with any disputes or concerns that
may arise.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">We may need to retain
information for a longer period where we need the information to comply with
regulatory or legal requirements or where we may need it for our legitimate
purposes, e.g. to help us respond to queries or complaints, fighting fraud and
financial crime, responding to requests from regulators, etc.</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">If
we don’t need to retain information for this period of time, we may destroy,
delete or anonymise it more promptly</span></p>

<p class="MsoNormal" style="margin-top:12.55pt;line-height:21.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.25pt">Transferring </span><span lang="EN-GB" style="


font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
.6pt">information</span><span lang="EN-GB" style="font-size:19.0pt;font-family:
&quot;Arial&quot;,sans-serif;letter-spacing:.25pt"> </span><span lang="EN-GB" style="


font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.15pt">overseas</span></p>

<p class="MsoNormal" style="margin-top:7.55pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.05pt">Your information may be transferred to and stored in
locations outside the European Economic Area (EEA) or the United Kingdom (UK),
including countries that may not have the same level of protection for personal
information. When we do this, we’ll ensure it has an appropriate level of
protection and that the transfer is lawful. We may need to transfer your
information in this way to carry out our contract with you, to fulfil a legal
obligation, to protect the public interest and/or for our legitimate interests.
In some countries the law might compel us to share certain information, e.g.
with tax authorities. Even in these cases, we’ll only share your information
with people who have the right to see it. </span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">You can obtain more
details of the protection given to your information when it’s transferred
outside the EEA or UK by contacting us using the details in the ‘More details
about your information’ section below.</span></p>

<p class="MsoNormal" style="margin-top:11.8pt;line-height:10.4pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.5pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.15pt">Rights of individuals</span></b></p>

<p class="MsoNormal" style="margin-top:5.4pt;margin-right:21.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.05pt">Individuals have a number of rights in relation to the information
that we hold about them. These rights include:</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">the
right to access information we hold about them and to obtain information about
how we process it;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:0cm;margin-bottom:0cm;
margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">in some circumstances, the right to withdraw their consent to our
processing of their information, which they can do at any time. We may continue
to process their information if we have another legitimate reason for doing so;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:14.4pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">in some circumstances, the right to receive certain information they
have provided to us in an electronic format and/or request that we transmit it
to a third party;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:21.6pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">the
right to request that we rectify their information if it’s inaccurate or
incomplete;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">in some circumstances, the right to request that we erase their
information. We may continue to retain their information if we’re entitled or
required to retain it;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-indent:-14.4pt;line-height:
11.9pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">the right to object to, and to request that we restrict, our processing
of their information in some circumstances. Again, there may be situations
where they object to, or ask us to restrict, our processing of their
information but we’re entitled to continue processing their information and/or
to refuse that request</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;line-height:11.9pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt"><br>
</span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.15pt">Individuals can exercise their rights by contacting us
using the details set out in the ‘More details about your information’ section
below. Individuals also have a right to complain to the UK Information
Commissioner’s Office by visiting </span><span lang="EN-GB"><a href="http://www.ico.org.uk"><b><span style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
color:blue;letter-spacing:-.15pt">ico.org.uk</span></b></a></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">, or to the data protection regulator in the country where they live or
work.</span></p>

<p class="MsoNormal" style="margin-top:12.35pt;line-height:22.05pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.35pt">Credit Reference Checks,
Fraud and </span><span lang="EN-GB" style="font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:.65pt">Money Laundering</span></p>

<p class="MsoNormal" style="margin-top:9.15pt;line-height:10.25pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.05pt">Credit Reference Checks</span></b></p>

<p class="MsoNormal" style="margin-top:5.7pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">If
you apply for new products or services (including credit like a mortgage,
personal loan or credit card), we may perform credit and identity checks on you
with one or more credit reference agencies (CRAs). When you use our banking
services, we may also make periodic searches at CRAs to manage your account
with us.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">To
do this, we’ll supply your information to CRAs and they’ll give us information
about you. This will include information from your credit application and about
your financial situation and financial history CRAs will supply us with both
public (including the electoral register) and shared credit information,
financial situation, history and fraud prevention information.</span></p>

<p class="MsoNormal" style="margin-top:11.65pt;line-height:10.2pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">We may use this information to:</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;margin-right:18.0pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">assess if we
can offer you credit and whether you can afford to take the product you applied
for;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:28.8pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
11.95pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">verify the
accuracy of the data you’ve provided to us;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:28.8pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
11.95pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">prevent
criminal activity, fraud and money laundering;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">manage your
account(s);</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:0cm;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">trace and
recover debts;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:18.0pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">ensure any offers provided to you are appropriate to your circumstances</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">We’ll continue to exchange information about
you with CRAs while you have a relationship with us. We’ll also inform the CRAs
about your repayment history. If you borrow and do not repay in full and on
time, CRAs will record the outstanding debt. This information may be supplied
to other organisations by CRAs.</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">When CRAs receive a search
request from us they’ll place a search footprint on your credit file that may
be seen by other lenders. If you apply for a bank account or credit (such as
where you apply for a mortgage, loan or credit card), we’ll get details of your
credit history from a CRA (and share information about you with the CRA) and
use this information to work out how much you can afford to borrow or pay back.
We may also carry out further credit checks on you while you’re a customer to
maintain an accurate and up-to-date record of your credit history. We may use
your information to verify the accuracy of the information you’ve provided to
us, prevent criminal activity, fraud and money laundering, manage your
account(s), trace and recover debts and ensure any offers provided to you are
appropriate to your circumstances.</span></p>

<p class="MsoNormal" style="margin-top:9.8pt;margin-right:10.8pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:11.9pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.1pt">If you’re making a joint application, or tell us that you
have a spouse or financial associate, we’ll link your records together. You
should discuss this with them, and share this information with them before
submitting the application. CRAs will also</span></p>

<p class="MsoNormal" style="margin-right:14.4pt;line-height:11.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.15pt">link your records
together and these links will remain on your and their files until you or your
partner successfully files for a disassociation with the CRAs to break that
link.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">The identities of the CRAs, their role also as
fraud prevention agencies, the data they hold, the ways in which they use and
share information, data retention periods and your data protection rights with
the CRAs are explained in more detail on their websites. They’ve created a
joint document called the Credit Reference Agency Information Notice (CRAIN)
which is accessible from each of the three CRAs – clicking on any of these
three links will also take you to the same CRAIN document:</span></p>

<p class="MsoNormal" style="margin-top:11.9pt;line-height:10.25pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">Credit reference agencies:</span></b></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Call Credit –
</span><span lang="EN-GB"><a href="http://www.callcredit.co.uk/crain"><b><span style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:blue">callcredit.co.uk/crain</span></b></a></span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Equifax – </span><span lang="EN-GB"><a href="http://www.equifax.co.uk/crain"><b><span style="font-size:
9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:blue">equifax.co.uk/crain</span></b></a></span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Experian – </span><span lang="EN-GB"><a href="http://www.experian.co.uk/crain"><b><span style="font-size:
9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:blue">experian.co.uk/crain</span></b></a></span></p>

<p class="MsoNormal" style="margin-top:9.7pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">To
comply with the law and for our own legitimate interest to enable us to assess
and manage risk, we can share details about your financial situation and
financial history with CRAs, fraud prevention agencies, etc. This includes
information on any bank accounts or credit you have with us, including:</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:14.4pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="
font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">how you
manage your bank accounts or credit;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">if you owe us
money;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:21.6pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">if we have
concerns about financial crime;</span></p>

<p class="MsoNormal" style="margin-top:1.75pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
10.25pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol;letter-spacing:-.05pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">if you
haven’t kept up with your payments or paid off what you owe us (unless there’s
a genuine dispute over how much you owe us), or if you’ve agreed and stuck to a
repayment plan</span></p>

<p class="MsoNormal" style="margin-top:1.75pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;line-height:10.25pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></b></p>

<p class="MsoNormal" style="margin-top:1.75pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:0cm;margin-bottom:.0001pt;line-height:10.25pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">Fraud Prevention Agencies</span></b></p>

<p class="MsoNormal" style="margin-top:5.65pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.05pt">We’ll carry out checks
with fraud prevention agencies for the purposes of preventing fraud and money
laundering, and to verify your identity before we provide products and services
to you. These checks require us to process personal information about you.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">The information you provide or which we’ve
collected from you, or received from third parties, will be used to carry out
these checks in order to prevent fraud and money laundering, and to verify your
identity.</span></p>

<p class="MsoNormal" style="margin-top:10.05pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.2pt">We’ll process personal
information, such as your name, address, date of birth, contact details,
financial information, employment details, and device identifiers e.g. IP
address.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">We and fraud prevention agencies may also
enable law enforcement agencies to access and use your personal data to detect,
investigate and prevent crime.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">We process this information on the basis that
we have a legitimate interest in preventing fraud and money laundering and to
verify your identity. This enables us to protect our business and to comply
with laws that apply to us. This processing is also a contractual requirement
of any of our products or services you use.</span></p>

<p class="MsoNormal" style="margin-top:9.85pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">Fraud
prevention agencies can hold information for different periods of time. If
they’re concerned about a possible fraud or money laundering risk, your data
can be held by them for up to six years.</span></p>

<p class="MsoNormal" style="margin-top:.1pt;line-height:10.25pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">Consequences of Processing</span></b></p>

<p class="MsoNormal" style="margin-top:5.7pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">If
we, or a fraud prevention agency, have reason to believe there’s a fraud or
money laundering risk, we may refuse to provide the services and credit you’ve
requested. We may also stop providing existing products and services to you. A
record of any fraud or money laundering risk will be retained by the fraud
prevention agencies, and may result in others refusing to provide services to
you. The information we hold about you could make it easier or harder for you
to get credit in the future.</span></p>

<p class="MsoNormal" style="margin-top:9.9pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">To
find out more about credit and fraud checks, read our ‘Guide to Credit Scoring,
Credit Reference and Fraud Prevention Agencies’ leaflet. You can get it from
our website, in any of our branches or you can request a paper copy by
contacting us in your preferred way. To find out more about fraud prevention
agencies and how they manage your information, please visit </span><span lang="EN-GB"><a href="http://www.cifas.org.uk/privacy-notice"><b><span style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:blue">cifas.org.uk/privacy-notice</span></b></a></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">.</span></p>

<p class="MsoNormal" style="margin-top:12.05pt;line-height:22.1pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.25pt">&nbsp;</span></p>

<p class="MsoNormal" style="margin-top:12.05pt;line-height:22.1pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.25pt">What we need from you</span></p>

<p class="MsoNormal" style="margin-top:7.55pt;margin-right:3.6pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:11.95pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;
letter-spacing:-.05pt">You’re responsible for making sure the information you
give us or which is given to us by your connected persons or provided on your
behalf is accurate and up to date, and you must tell us if anything changes as
soon as possible. If you provide information for another person on your account
or another connected person, you’ll need to direct them to this policy and make
sure they agree to us using their information as described in it.</span></p>

<p class="MsoNormal" style="margin-top:.1pt;line-height:10.25pt;punctuation-wrap:
simple;vertical-align:baseline"><b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.1pt">How we keep information
secure</span></b></p>

<p class="MsoNormal" style="margin-top:5.7pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">We use a range of measures to keep information
safe and secure which may include encryption and other forms of security. We
require our staff and any third parties who carry out any work on our behalf to
comply with appropriate compliance standards including obligations to protect
any information and applying appropriate measures for the use and transfer of
information.</span></p>

<p class="MsoNormal" style="margin-top:12.1pt;line-height:22.1pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.15pt">More details about </span><span lang="EN-GB" style="
font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
.2pt">your information</span></p>

<p class="MsoNormal" style="margin-top:7.55pt;line-height:12.0pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">If you’d like further
information on anything we’ve said in this Privacy Policy, or to contact our
Data Protection Officer, contact us at PO Box 6201, Coventry CV3 9HW addressed
‘for the attention of the DPO’.</span></p>

<p class="MsoNormal" style="margin-top:9.9pt;margin-right:7.2pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">This
Privacy Policy may be updated from time to time and the most recent version can
be found at </span><span lang="EN-GB"><a href="http://www.privatebanking.hsbc.com/privacy-notice/privacy-notice-for-hsbc-private-bank-uk-limited"><b><span style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;color:blue">privatebanking.hsbc.
com/privacy-notice/privacy-notice-for-hsbc-private-bank-uk-limited</span></b></a></span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">.</span></p>

<p class="MsoNormal" style="margin-top:.65pt;line-height:21.95pt;punctuation-wrap:
simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:19.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:.25pt">Appendix – </span><span lang="EN-GB" style="
font-size:19.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
.2pt">How we process</span><span lang="EN-GB" style="font-size:19.0pt;font-family:
&quot;Arial&quot;,sans-serif;letter-spacing:.25pt"> your information</span></p>

<p class="MsoNormal" style="margin-top:7.55pt;margin-right:18.0pt;margin-bottom:
8.0pt;margin-left:0cm;line-height:12.0pt;punctuation-wrap:simple;vertical-align:
baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">We’ll
use your information for purposes including:</span></p>

<p class="MsoNormal" style="margin-top:10.1pt;margin-right:3.6pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="
font-size:9.5pt;font-family:Symbol;letter-spacing:-.15pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><b><span lang="EN-GB" style="
font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">To deliver our products and services (including insurance): </span></b><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.15pt">administer your accounts, or process your transactions. We’ll do this
in order to perform our contract with you;</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:0cm;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="
font-size:9.5pt;font-family:Symbol;letter-spacing:-.15pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><b><span lang="EN-GB" style="
font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">Banking operations support: </span></b><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">we’ll
use your information to enable the provision and function of our banking
services in line with regulation, laws and customer rights and interests, e.g.
complaints management and exit management. The lawful reasons for processing
these are legitimate interest, legal obligation and in order to perform our
contract with you;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
11.95pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="
font-size:9.5pt;font-family:Symbol;letter-spacing:-.15pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><b><span lang="EN-GB" style="
font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">To prevent and detect crime including, e.g. fraud, terrorist financing
and money laundering: </span></b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">this will include
monitoring, mitigation and risk management, carrying out customer due
diligence, name screening, transaction screening and customer risk
identification. We do this to comply with our legal obligations and because
it’s in our legitimate interest. We may share your information with relevant
agencies, law enforcement and other third parties where the law allows us to
for the purpose of preventing or detecting crime. Additionally, we and other
financial institutions may take steps to help prevent financial crime and
manage risk. We’ll do this because we have a legitimate interest, a legal
obligation </span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">to
prevent or detect crime or it’s in the public interest. We may be required to
use your information to do this, even if you’ve asked us to stop using your
information. That could include (among other things):<br>
<br>
</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:21.6pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">screening,
intercepting and investigating any payments, instructions or communications you
send or receive (including drawdown requests and application forms);</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:10.8pt;margin-bottom:
0cm;margin-left:21.6pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">investigating
who you’re paying or who’s paying you, e.g. checks on payments into and out of
your account and other parties related to those payments;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:21.6pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">passing
information to relevant agencies if we think you’ve given us false or
inaccurate information, or we suspect criminal activity;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:21.6pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">combining
the information, we have about you with information from other HSBC companies
to help us better understand any potential risk;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:21.6pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.0pt;font-family:Symbol">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-GB" style="font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">checking
whether the people or organisations you’re paying or receiving payments from
are who they say they are, and aren’t subject to any sanctions.<br>
<br>
</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:0cm;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:11.95pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="
font-size:9.5pt;font-family:Symbol;letter-spacing:-.15pt">·<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><b><span lang="EN-GB" style="
font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif">Risk
management: </span></b><span lang="EN-GB" style="font-size:9.0pt;font-family:
&quot;Arial&quot;,sans-serif">we’ll use your information to measure, detect and prevent
the likelihood of financial, reputational, legal, compliance or customer risk.
This includes credit risk, traded risk, operational risk and insurance risk
(e.g. for underwriting or claims management purposes). We’ll do this because we
have a legitimate interest in ensuring that we carry out a proper risk
assessment prior to providing credit, insurance or other finance;</span></p>

<p class="MsoNormal" style="margin-top:0cm;margin-right:3.6pt;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="
font-size:9.5pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><b><span lang="EN-GB" style="font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif">Online
Banking, Mobile Apps and other online product platforms: </span></b><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">we’ll use
your information to allow us to provide you with access to HSBC online
platforms and mobile apps (e.g. the HSBC UK Mobile Banking App). The platform
may allow you to directly or indirectly communicate with us through mobile
apps, such as using Online Banking, or applying for products and services
online. The lawful basis for using your information for this purpose is to
perform our contract with you or that processing for this purpose is in our
legitimate interest;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:0cm;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.5pt;font-family:Symbol;
letter-spacing:-.15pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><b><span lang="EN-GB" style="
font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif">Product and
service improvement: </span></b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">we’ll analyse your information to identify
possible service and product improvements. Where we provide you with aggregated
information services we’ll use your information to understand how you use these
products which may include your transactional information from other financial
institutions to help improve our products and services. The lawful basis for
processing your information for this purpose is our legitimate interest. We do
this to improve our products and services to best meet the need of our
customers;</span></p>

<p class="MsoNormal" style="margin-top:1.2pt;margin-right:7.2pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.5pt;font-family:Symbol;
letter-spacing:-.15pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><b><span lang="EN-GB" style="
font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.05pt">Data analytics for tailored services: </span></b><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.05pt">we’ll
analyse your information to identify relevant opportunities to promote products
and services to existing or prospective customers. This may include reviewing
historical customer transactional behaviour, comparison of customer activity or
it may include an analysis of your transactional information from other
financial institutions. We do this to help us provide you with products </span><span lang="EN-GB" style="
font-size:9.0pt;font-family:&quot;Arial&quot;,sans-serif">and services
we think will be of most relevance to you. The lawful basis for using your
information in this way is our legitimate interest;</span></p>

<p class="MsoNormal" style="margin-top:1.45pt;margin-right:3.6pt;margin-bottom:
0cm;margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:
12.0pt;punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="font-size:9.5pt;font-family:Symbol;
letter-spacing:-.15pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><b><span lang="EN-GB" style="
font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:
-.1pt">Marketing: </span></b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">we’ll use your information
to provide you with information about HSBC products and services, and also
products and services from our partners and other relevant third parties. The
lawful basis for this is our legitimate interest. We may need your consent to
communicate by certain channels and we’ll always make sure we get this where we
need to. You can change your mind on how you receive marketing messages or
choose to stop receiving them at any time. To make that change, contact us in
the usual way;</span></p>

<p class="MsoNormal" style="margin-top:1.4pt;margin-right:0cm;margin-bottom:0cm;
margin-left:10.8pt;margin-bottom:.0001pt;text-indent:-10.8pt;line-height:12.0pt;
punctuation-wrap:simple;vertical-align:baseline"><span lang="EN-GB" style="
font-size:9.5pt;font-family:Symbol;letter-spacing:-.15pt">·<span style="font:7.0pt &quot;
Times New Roman&quot;">&nbsp;&nbsp;&nbsp; </span></span><b><span lang="EN-GB" style="
font-size:9.5pt;font-family:&quot;Arial&quot;,sans-serif">Protecting
our legal rights: </span></b><span lang="EN-GB" style="font-size:9.0pt;
font-family:&quot;Arial&quot;,sans-serif">we may need to use your information to protect
our legal rights, e.g. in the case of defending or the protection of legal
rights and interests (e.g. collecting money owed, enforcing or protecting our
security or defending rights of intellectual property); court action; managing
complaints or disputes; in the event of a restructuring of companies or other
mergers or acquisition. This may be in connection with action taken against you
or other persons, e.g. joint borrowers or persons who give a guarantee or other
security for your obligations to us. We’d do this on the basis that it’s in our
legitimate interest.</span></p>

<p class="MsoNormal" style="margin-top: 10px; text-align: left;"><span>Last update: 14 June 2021</span></p>

</div>


</body></html>
`;
