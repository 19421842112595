export default `
<html><head>
<meta http-equiv="Content-Type" content="text/html; charset=utf-8">
<meta name="Generator" content="Microsoft Word 15 (filtered)">
<title>Wealth View TC's v6</title>
<style>
<!--
 /* Font Definitions */
 @font-face
	{font-family:Wingdings;
	panose-1:5 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:Batang;
	panose-1:2 3 6 0 0 1 1 1 1 1;}
@font-face
	{font-family:"Cambria Math";
	panose-1:2 4 5 3 5 4 6 3 2 4;}
@font-face
	{font-family:"Helvetica Neue for HSBC Lt";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Wingdings 2";
	panose-1:5 2 1 2 1 5 7 7 7 7;}
@font-face
	{font-family:"Arial Bold";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"Times New Roman Bold";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:Tahoma;
	panose-1:2 11 6 4 3 5 4 4 2 4;}
@font-face
	{font-family:"Univers Next for HSBC Medium";
	panose-1:2 11 6 3 3 2 2 2 2 3;}
@font-face
	{font-family:"Univers Next Pro Light";
	panose-1:0 0 0 0 0 0 0 0 0 0;}
@font-face
	{font-family:"\@MS Mincho";
	panose-1:2 2 6 9 4 2 5 8 3 4;}
@font-face
	{font-family:"\@Batang";
	panose-1:2 3 6 0 0 1 1 1 1 1;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
h1
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	page-break-after:avoid;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h2
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:1.0cm;
	text-indent:-1.0cm;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h3
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:36.0pt;
	text-indent:-36.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h4
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:45.0pt;
	text-indent:-45.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h5
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:49.5pt;
	text-indent:-49.5pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
h6
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:54.0pt;
	text-indent:-54.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:normal;}
p.MsoHeading7, li.MsoHeading7, div.MsoHeading7
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:58.5pt;
	text-indent:-58.5pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeading8, li.MsoHeading8, div.MsoHeading8
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:63.0pt;
	text-indent:-63.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeading9, li.MsoHeading9, div.MsoHeading9
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:67.5pt;
	text-indent:-67.5pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc1, li.MsoToc1, div.MsoToc1
	{margin-top:6.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.MsoToc2, li.MsoToc2, div.MsoToc2
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc2CxSpFirst, li.MsoToc2CxSpFirst, div.MsoToc2CxSpFirst
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc2CxSpMiddle, li.MsoToc2CxSpMiddle, div.MsoToc2CxSpMiddle
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc2CxSpLast, li.MsoToc2CxSpLast, div.MsoToc2CxSpLast
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc3, li.MsoToc3, div.MsoToc3
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:14.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc4, li.MsoToc4, div.MsoToc4
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:14.4pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc5, li.MsoToc5, div.MsoToc5
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:14.4pt;
	border:none;
	padding:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoToc6, li.MsoToc6, div.MsoToc6
	{margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:14.4pt;
	text-indent:18.0pt;
	border:none;
	padding:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoNormalIndent, li.MsoNormalIndent, div.MsoNormalIndent
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:36.0pt;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoFootnoteText, li.MsoFootnoteText, div.MsoFootnoteText
	{margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:10.8pt;
	margin-bottom:.0001pt;
	text-indent:-10.8pt;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
	{mso-style-link:"Comment Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoHeader, li.MsoHeader, div.MsoHeader
	{mso-style-link:"Header Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoFooter, li.MsoFooter, div.MsoFooter
	{mso-style-link:"Footer Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoTof, li.MsoTof, div.MsoTof
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.MsoFootnoteReference
	{vertical-align:super;}
span.MsoPageNumber
	{font-family:"Arial",sans-serif;}
span.MsoEndnoteReference
	{vertical-align:super;}
p.MsoEndnoteText, li.MsoEndnoteText, div.MsoEndnoteText
	{mso-style-link:"Endnote Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
	{mso-style-link:"Body Text Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoBodyText2, li.MsoBodyText2, div.MsoBodyText2
	{mso-style-link:"Body Text 2 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:200%;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoBodyText3, li.MsoBodyText3, div.MsoBodyText3
	{mso-style-link:"Body Text 3 Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoBlockText, li.MsoBlockText, div.MsoBlockText
	{margin-top:0cm;
	margin-right:57.6pt;
	margin-bottom:0cm;
	margin-left:57.6pt;
	margin-bottom:.0001pt;
	border:none;
	padding:0cm;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:#DB0011;
	font-style:italic;}
a:link, span.MsoHyperlink
	{color:blue;
	text-decoration:underline;}
a:visited, span.MsoHyperlinkFollowed
	{color:blue;
	text-decoration:underline;}
p
	{margin-right:0cm;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Times New Roman",serif;}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
	{mso-style-link:"Comment Subject Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
	{mso-style-link:"Balloon Text Char";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:8.0pt;
	font-family:"Tahoma",sans-serif;
	color:black;}
p.MsoRMPane, li.MsoRMPane, div.MsoRMPane
	{margin:0cm;
	margin-bottom:.0001pt;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.MsoTocHeading, li.MsoTocHeading, div.MsoTocHeading
	{margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	margin-bottom:.0001pt;
	line-height:107%;
	page-break-after:avoid;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:#A4000C;}
p.TNCBulletOne, li.TNCBulletOne, div.TNCBulletOne
	{mso-style-name:TNC_BulletOne;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Footnote, li.Footnote, div.Footnote
	{mso-style-name:Footnote;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:14.4pt;
	margin-bottom:.0001pt;
	text-indent:-14.4pt;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;}
p.TNCCoverpagetitle, li.TNCCoverpagetitle, div.TNCCoverpagetitle
	{mso-style-name:"TNC_Cover page title";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	line-height:36.0pt;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCCoverpagesubtitle, li.TNCCoverpagesubtitle, div.TNCCoverpagesubtitle
	{mso-style-name:"TNC_Cover page subtitle";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:24.0pt;
	margin-left:0cm;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TNCCoverpagedetails, li.TNCCoverpagedetails, div.TNCCoverpagedetails
	{mso-style-name:"TNC_Cover page details";
	margin-top:24.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Pageheader, li.Pageheader, div.Pageheader
	{mso-style-name:"Page header";
	margin-top:44.0pt;
	margin-right:0cm;
	margin-bottom:175.0pt;
	margin-left:0cm;
	line-height:55.0pt;
	font-size:54.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.2ndlevelbullettext, li.2ndlevelbullettext, div.2ndlevelbullettext
	{mso-style-name:"2nd level bullet text";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:36.0pt;
	text-indent:-18.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TNCBodyText1, li.TNCBodyText1, div.TNCBodyText1
	{mso-style-name:"TNC_Body Text1";
	mso-style-link:"TNC_Body Text1 Char";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Bullettext, li.Bullettext, div.Bullettext
	{mso-style-name:"Bullet text";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Paragraphhead, li.Paragraphhead, div.Paragraphhead
	{mso-style-name:"Paragraph head";
	margin-top:108.0pt;
	margin-right:0cm;
	margin-bottom:84.0pt;
	margin-left:0cm;
	line-height:19.0pt;
	page-break-after:avoid;
	font-size:18.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.TNCBodyText1Char
	{mso-style-name:"TNC_Body Text1 Char";
	mso-style-link:"TNC_Body Text1";
	font-family:"Arial",sans-serif;
	color:black;}
p.Tabletextbold, li.Tabletextbold, div.Tabletextbold
	{mso-style-name:Tabletextbold;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Tabletext, li.Tabletext, div.Tabletext
	{mso-style-name:Tabletext;
	mso-style-link:"Tabletext Char";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:110%;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Quotetext, li.Quotetext, div.Quotetext
	{mso-style-name:Quotetext;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-style:italic;}
p.TableBulletOne, li.TableBulletOne, div.TableBulletOne
	{mso-style-name:TableBulletOne;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.footnote0, li.footnote0, div.footnote0
	{mso-style-name:footnote;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	line-height:7.05pt;
	text-autospace:none;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:#626469;}
p.GraphTablecontent, li.GraphTablecontent, div.GraphTablecontent
	{mso-style-name:"Graph\/Table content";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:#626469;}
p.Tabletext2, li.Tabletext2, div.Tabletext2
	{mso-style-name:"Table text 2";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	text-align:right;
	line-height:110%;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Tbaletextbold2, li.Tbaletextbold2, div.Tbaletextbold2
	{mso-style-name:"Tbale text bold 2";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	text-align:right;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.BulletTwo, li.BulletTwo, div.BulletTwo
	{mso-style-name:BulletTwo;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:28.8pt;
	text-indent:-14.4pt;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.BulletThree, li.BulletThree, div.BulletThree
	{mso-style-name:BulletThree;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:43.2pt;
	text-indent:-14.4pt;
	line-height:10.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Source, li.Source, div.Source
	{mso-style-name:Source;
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	margin-bottom:.0001pt;
	text-autospace:none;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Tableheadredborder, li.Tableheadredborder, div.Tableheadredborder
	{mso-style-name:Tableheadredborder;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:12.0pt;
	page-break-after:avoid;
	border:none;
	padding:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.Tabletitle, li.Tabletitle, div.Tabletitle
	{mso-style-name:Tabletitle;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:12.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	font-weight:bold;}
p.TableBulletTwo, li.TableBulletTwo, div.TableBulletTwo
	{mso-style-name:TableBulletTwo;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:28.8pt;
	text-indent:-14.4pt;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TableBulletThree, li.TableBulletThree, div.TableBulletThree
	{mso-style-name:TableBulletThree;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:43.2pt;
	text-indent:-14.4pt;
	line-height:10.0pt;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TabletextboldWhite, li.TabletextboldWhite, div.TabletextboldWhite
	{mso-style-name:TabletextboldWhite;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:white;
	font-weight:bold;}
p.Tabletextobject, li.Tabletextobject, div.Tabletextobject
	{mso-style-name:Tabletextobject;
	margin:0cm;
	margin-bottom:.0001pt;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Tombstonetext, li.Tombstonetext, div.Tombstonetext
	{mso-style-name:Tombstonetext;
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	page-break-after:avoid;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.PageheaderContents, li.PageheaderContents, div.PageheaderContents
	{mso-style-name:"Page headerContents";
	margin-top:44.0pt;
	margin-right:0cm;
	margin-bottom:175.0pt;
	margin-left:0cm;
	line-height:55.0pt;
	font-size:54.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Pageheadercontd, li.Pageheadercontd, div.Pageheadercontd
	{mso-style-name:"Page header\(contd\)";
	margin-top:44.0pt;
	margin-right:0cm;
	margin-bottom:255.0pt;
	margin-left:0cm;
	line-height:28.0pt;
	font-size:28.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.BalloonTextChar
	{mso-style-name:"Balloon Text Char";
	mso-style-link:"Balloon Text";
	font-family:"Tahoma",sans-serif;
	color:black;}
p.StyleBodyText18ptLeft02cmRight02cmBefore2, li.StyleBodyText18ptLeft02cmRight02cmBefore2, div.StyleBodyText18ptLeft02cmRight02cmBefore2
	{mso-style-name:"Style Body Text1 + 8 pt Left\:  0\.2 cm Right\:  0\.2 cm Before\:  2 \.\.\.";
	margin-top:2.0pt;
	margin-right:5.65pt;
	margin-bottom:2.0pt;
	margin-left:5.65pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.EndnoteTextChar
	{mso-style-name:"Endnote Text Char";
	mso-style-link:"Endnote Text";
	font-family:"Arial",sans-serif;
	color:black;}
p.SpacerStyle, li.SpacerStyle, div.SpacerStyle
	{mso-style-name:"Spacer Style";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Tombstonetext6pt, li.Tombstonetext6pt, div.Tombstonetext6pt
	{mso-style-name:"Tombstonetext6 pt";
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	page-break-after:avoid;
	font-size:6.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TabletextBanding, li.TabletextBanding, div.TabletextBanding
	{mso-style-name:"Tabletext Banding";
	mso-style-link:"Tabletext Banding Char";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:110%;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.TabletextBandingChar
	{mso-style-name:"Tabletext Banding Char";
	mso-style-link:"Tabletext Banding";
	font-family:"Arial",sans-serif;
	color:black;}
span.TabletextChar
	{mso-style-name:"Tabletext Char";
	mso-style-link:Tabletext;
	font-family:"Arial",sans-serif;
	color:black;}
p.BulletFour, li.BulletFour, div.BulletFour
	{mso-style-name:BulletFour;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:57.6pt;
	text-indent:-14.4pt;
	line-height:10.0pt;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Sectionpagetitle, li.Sectionpagetitle, div.Sectionpagetitle
	{mso-style-name:"Section page title";
	margin-top:450.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:10.35pt;
	margin-bottom:.0001pt;
	font-size:30.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Sectionpagesubtitle, li.Sectionpagesubtitle, div.Sectionpagesubtitle
	{mso-style-name:"Section page subtitle";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:24.0pt;
	margin-left:0cm;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.BodyTextChar
	{mso-style-name:"Body Text Char";
	mso-style-link:"Body Text";
	font-family:"Arial",sans-serif;
	color:black;}
p.NumberOne, li.NumberOne, div.NumberOne
	{mso-style-name:NumberOne;
	mso-style-link:"NumberOne Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	line-height:115%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.NumberOneChar
	{mso-style-name:"NumberOne Char";
	mso-style-link:NumberOne;
	font-family:"Arial",sans-serif;
	color:black;}
p.NumberTwo, li.NumberTwo, div.NumberTwo
	{mso-style-name:NumberTwo;
	mso-style-link:"NumberTwo Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-indent:-18.0pt;
	line-height:115%;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.NumberTwoChar
	{mso-style-name:"NumberTwo Char";
	mso-style-link:NumberTwo;
	font-family:"Arial",sans-serif;
	color:black;}
p.NumberThree, li.NumberThree, div.NumberThree
	{mso-style-name:NumberThree;
	mso-style-link:"NumberThree Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:61.2pt;
	text-indent:-25.2pt;
	line-height:115%;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.NumberThreeChar
	{mso-style-name:"NumberThree Char";
	mso-style-link:NumberThree;
	font-family:"Arial",sans-serif;
	color:black;}
p.NumberFive, li.NumberFive, div.NumberFive
	{mso-style-name:NumberFive;
	mso-style-link:"NumberFive Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:118.8pt;
	text-indent:-32.4pt;
	line-height:115%;
	font-size:6.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.NumberFiveChar
	{mso-style-name:"NumberFive Char";
	mso-style-link:NumberFive;
	font-family:"Arial",sans-serif;
	color:black;}
p.BulletFive, li.BulletFive, div.BulletFive
	{mso-style-name:BulletFive;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:72.0pt;
	text-indent:-14.4pt;
	line-height:10.0pt;
	font-size:6.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Sectionpagetitle2, li.Sectionpagetitle2, div.Sectionpagetitle2
	{mso-style-name:"Section page title 2";
	margin-top:280.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:10.8pt;
	margin-bottom:.0001pt;
	font-size:30.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Sectiontitle, li.Sectiontitle, div.Sectiontitle
	{mso-style-name:"Section title";
	margin-top:20.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	line-height:36.0pt;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:white;
	font-weight:bold;}
p.StyleCoverpagedetailsBold, li.StyleCoverpagedetailsBold, div.StyleCoverpagedetailsBold
	{mso-style-name:"Style Cover page details + Bold";
	margin-top:24.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:0cm;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.StyleStyleCoverpagedetailsBoldBold, li.StyleStyleCoverpagedetailsBoldBold, div.StyleStyleCoverpagedetailsBoldBold
	{mso-style-name:"Style Style Cover page details + Bold + Bold";
	margin-top:24.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:0cm;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.StyleParagraphheadtitle, li.StyleParagraphheadtitle, div.StyleParagraphheadtitle
	{mso-style-name:"Style Paragraph head \(title\)";
	margin-top:108.0pt;
	margin-right:0cm;
	margin-bottom:84.0pt;
	margin-left:0cm;
	line-height:40.0pt;
	page-break-after:avoid;
	font-size:40.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.StyleBodyText1Bold, li.StyleBodyText1Bold, div.StyleBodyText1Bold
	{mso-style-name:"Style Body Text1 + Bold";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.GraphTitle, li.GraphTitle, div.GraphTitle
	{mso-style-name:"Graph Title";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.ChartSubtitle, li.ChartSubtitle, div.ChartSubtitle
	{mso-style-name:"Chart Subtitle";
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
span.captionguide
	{mso-style-name:caption_guide;
	font-family:"Arial",sans-serif;
	color:#7F7F7F;
	font-weight:normal;}
p.ColumnTitle, li.ColumnTitle, div.ColumnTitle
	{mso-style-name:"Column Title";
	margin:0cm;
	margin-bottom:.0001pt;
	line-height:25.0pt;
	font-size:24.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.PageTitle, li.PageTitle, div.PageTitle
	{mso-style-name:"Page Title";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.SubtitleParagraph, li.SubtitleParagraph, div.SubtitleParagraph
	{mso-style-name:"Subtitle Paragraph";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TableTitle0, li.TableTitle0, div.TableTitle0
	{mso-style-name:"Table Title";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:10.0pt;
	font-size:10.0pt;
	font-family:"Univers Next for HSBC Medium",sans-serif;
	color:black;}
p.TableHeader, li.TableHeader, div.TableHeader
	{mso-style-name:"Table Header";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:3.0pt;
	margin-left:0cm;
	line-height:10.0pt;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TableText0, li.TableText0, div.TableText0
	{mso-style-name:"Table Text";
	margin-top:1.0pt;
	margin-right:0cm;
	margin-bottom:1.0pt;
	margin-left:0cm;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TableTextHSBCRed, li.TableTextHSBCRed, div.TableTextHSBCRed
	{mso-style-name:"Table Text + HSBC Red";
	margin-top:1.0pt;
	margin-right:0cm;
	margin-bottom:1.0pt;
	margin-left:0cm;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:#DB0011;
	font-weight:bold;}
p.StyleTableTextRight, li.StyleTableTextRight, div.StyleTableTextRight
	{mso-style-name:"Style Table Text + Right";
	margin-top:1.0pt;
	margin-right:0cm;
	margin-bottom:1.0pt;
	margin-left:0cm;
	text-align:right;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Colourpalettetextblack, li.Colourpalettetextblack, div.Colourpalettetextblack
	{mso-style-name:"Colour palette text black";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;}
p.Colourpalettetextwhite, li.Colourpalettetextwhite, div.Colourpalettetextwhite
	{mso-style-name:"Colour palette text white";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:white;}
p.StyleColourpalettetextwhite, li.StyleColourpalettetextwhite, div.StyleColourpalettetextwhite
	{mso-style-name:"Style Colour palette text white";
	margin:0cm;
	margin-bottom:.0001pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:white;}
span.Stylecaptionguide
	{mso-style-name:"Style caption_guide +";
	font-family:"Arial",sans-serif;
	color:#7F7F7F;
	font-weight:normal;}
p.FootnoteReference1, li.FootnoteReference1, div.FootnoteReference1
	{mso-style-name:"Footnote Reference1";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:10.8pt;
	margin-bottom:.0001pt;
	text-indent:-10.8pt;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;}
p.TNCParagraphTitle, li.TNCParagraphTitle, div.TNCParagraphTitle
	{mso-style-name:"TNC_Paragraph Title";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
span.HeaderChar
	{mso-style-name:"Header Char";
	mso-style-link:Header;
	font-family:"Arial",sans-serif;
	color:black;}
p.Disclaimertext, li.Disclaimertext, div.Disclaimertext
	{mso-style-name:"Disclaimer text";
	margin-top:.7pt;
	margin-right:0cm;
	margin-bottom:0cm;
	margin-left:0cm;
	margin-bottom:.0001pt;
	text-align:center;
	line-height:105%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Alphalist1, li.Alphalist1, div.Alphalist1
	{mso-style-name:"Alpha list 1";
	margin-top:4.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:18.0pt;
	text-indent:-18.0pt;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Alphalist2, li.Alphalist2, div.Alphalist2
	{mso-style-name:"Alpha list 2";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:36.0pt;
	text-indent:-18.0pt;
	page-break-after:avoid;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Alphalist3, li.Alphalist3, div.Alphalist3
	{mso-style-name:"Alpha list 3";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:12.0pt;
	margin-left:28.8pt;
	text-indent:-10.8pt;
	page-break-after:avoid;
	font-size:7.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.HeaderBold, li.HeaderBold, div.HeaderBold
	{mso-style-name:"Header Bold";
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCPageTitle, li.TNCPageTitle, div.TNCPageTitle
	{mso-style-name:"TNC_Page Title";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Pagetitlefirst, li.Pagetitlefirst, div.Pagetitlefirst
	{mso-style-name:"Page title \(first\)";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.PageTitlelast, li.PageTitlelast, div.PageTitlelast
	{mso-style-name:"Page Title \(last\)";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
span.FooterChar
	{mso-style-name:"Footer Char";
	mso-style-link:Footer;
	font-family:"Arial",sans-serif;
	color:black;}
p.TNCContenttitle, li.TNCContenttitle, div.TNCContenttitle
	{mso-style-name:"TNC_Content title";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Sectiondivider, li.Sectiondivider, div.Sectiondivider
	{mso-style-name:"Section divider";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	line-height:24.0pt;
	page-break-before:always;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:white;
	font-weight:bold;}
p.TableofContents, li.TableofContents, div.TableofContents
	{mso-style-name:"Table of Contents";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.PageTitleSecondary, li.PageTitleSecondary, div.PageTitleSecondary
	{mso-style-name:"Page Title_Secondary";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.PageTitleMain, li.PageTitleMain, div.PageTitleMain
	{mso-style-name:"Page Title_Main";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCParagraphHeader, li.TNCParagraphHeader, div.TNCParagraphHeader
	{mso-style-name:"TNC_Paragraph Header";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCBodyTitle, li.TNCBodyTitle, div.TNCBodyTitle
	{mso-style-name:"TNC_Body Title";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCPageTitleA, li.TNCPageTitleA, div.TNCPageTitleA
	{mso-style-name:"TNC_Page Title A";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCNumList, li.TNCNumList, div.TNCNumList
	{mso-style-name:"TNC_Num List";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:21.6pt;
	text-indent:-21.6pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TNCRomanList, li.TNCRomanList, div.TNCRomanList
	{mso-style-name:"TNC_Roman List";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:21.6pt;
	text-indent:-21.6pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Alphalist1number, li.Alphalist1number, div.Alphalist1number
	{mso-style-name:"Alpha list 1_number";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:21.6pt;
	text-indent:-21.6pt;
	line-height:110%;
	page-break-after:avoid;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.BodyText1indent, li.BodyText1indent, div.BodyText1indent
	{mso-style-name:"Body Text1_indent";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:18.0pt;
	line-height:110%;
	font-size:9.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TNCHeading1, li.TNCHeading1, div.TNCHeading1
	{mso-style-name:"TNC_Heading 1";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	text-indent:0cm;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCHeading1Body, li.TNCHeading1Body, div.TNCHeading1Body
	{mso-style-name:"TNC_Heading 1 Body";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:21.6pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.BulletOneindent, li.BulletOneindent, div.BulletOneindent
	{mso-style-name:BulletOne_indent;
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:36.0pt;
	text-indent:-14.15pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Tocpagetitle, li.Tocpagetitle, div.Tocpagetitle
	{mso-style-name:"Toc page title";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:24.0pt;
	margin-left:0cm;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.BodyTextlvl3, li.BodyTextlvl3, div.BodyTextlvl3
	{mso-style-name:"Body Text_lvl3";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:54.0pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.TNCHeading1Bullet, li.TNCHeading1Bullet, div.TNCHeading1Bullet
	{mso-style-name:"TNC_Heading 1 Bullet";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:43.2pt;
	text-indent:-21.6pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.BodyText3Char
	{mso-style-name:"Body Text 3 Char";
	mso-style-link:"Body Text 3";
	font-family:"Arial",sans-serif;
	color:black;}
p.TNCHeading2, li.TNCHeading2, div.TNCHeading2
	{mso-style-name:"TNC_Heading 2";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:54.0pt;
	text-indent:-32.4pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.PageTitlewithSub, li.PageTitlewithSub, div.PageTitlewithSub
	{mso-style-name:"Page Title with Sub";
	margin:0cm;
	margin-bottom:.0001pt;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.PageTitlewithSub2, li.PageTitlewithSub2, div.PageTitlewithSub2
	{mso-style-name:"Page Title with Sub 2";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:66.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.StyleBodyText18ptBefore2ptAfter2ptLinespacing, li.StyleBodyText18ptBefore2ptAfter2ptLinespacing, div.StyleBodyText18ptBefore2ptAfter2ptLinespacing
	{mso-style-name:"Style Body Text1 + 8 pt Before\:  2 pt After\:  2 pt Line spacing\:\.\.\.";
	margin-top:2.0pt;
	margin-right:0cm;
	margin-bottom:2.0pt;
	margin-left:0cm;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.NumberFour, li.NumberFour, div.NumberFour
	{mso-style-name:NumberFour;
	mso-style-link:"NumberFour Char";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:86.4pt;
	text-indent:-25.2pt;
	line-height:115%;
	font-size:6.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.NumberFourChar
	{mso-style-name:"NumberFour Char";
	mso-style-link:NumberFour;
	font-family:"Arial",sans-serif;
	color:black;}
p.Default, li.Default, div.Default
	{mso-style-name:Default;
	margin:0cm;
	margin-bottom:.0001pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
span.BodyText2Char
	{mso-style-name:"Body Text 2 Char";
	mso-style-link:"Body Text 2";
	font-family:"Arial",sans-serif;
	color:black;}
p.StyleParagraphtitle10ptNotBold, li.StyleParagraphtitle10ptNotBold, div.StyleParagraphtitle10ptNotBold
	{mso-style-name:"Style Paragraph title + 10 pt Not Bold";
	margin-top:12.0pt;
	margin-right:0cm;
	margin-bottom:4.0pt;
	margin-left:0cm;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.BodyText1indent2, li.BodyText1indent2, div.BodyText1indent2
	{mso-style-name:"Body Text1_indent2";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:40.3pt;
	line-height:12.0pt;
	font-size:8.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Pa15, li.Pa15, div.Pa15
	{mso-style-name:Pa15;
	margin:0cm;
	margin-bottom:.0001pt;
	text-autospace:none;
	font-size:12.0pt;
	font-family:"Univers Next Pro Light",sans-serif;}
p.TNCParagraphHeaderA, li.TNCParagraphHeaderA, div.TNCParagraphHeaderA
	{mso-style-name:"TNC_Paragraph Header A";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.PageTitlesub, li.PageTitlesub, div.PageTitlesub
	{mso-style-name:"Page Title_sub";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:24.0pt;
	margin-left:0cm;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;}
p.Pagetitlelast0, li.Pagetitlelast0, div.Pagetitlelast0
	{mso-style-name:"Page title \(last\)";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:24.0pt;
	margin-left:0cm;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
span.CommentTextChar
	{mso-style-name:"Comment Text Char";
	mso-style-link:"Comment Text";
	font-family:"Arial",sans-serif;
	color:black;}
span.CommentSubjectChar
	{mso-style-name:"Comment Subject Char";
	mso-style-link:"Comment Subject";
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.Paratitle16pts, li.Paratitle16pts, div.Paratitle16pts
	{mso-style-name:"Para title 16pts";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.paratitle16TOC, li.paratitle16TOC, div.paratitle16TOC
	{mso-style-name:"para title 16 \(TOC\)";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.PagetitlemainB, li.PagetitlemainB, div.PagetitlemainB
	{mso-style-name:"Page title main B";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.pagetitlesecondaryB, li.pagetitlesecondaryB, div.pagetitlesecondaryB
	{mso-style-name:"page title secondary B";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.pagetitlemainfirst, li.pagetitlemainfirst, div.pagetitlemainfirst
	{mso-style-name:"page title main first";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCSectionTitle, li.TNCSectionTitle, div.TNCSectionTitle
	{mso-style-name:"TNC_Section Title";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCSectionSubtitle, li.TNCSectionSubtitle, div.TNCSectionSubtitle
	{mso-style-name:"TNC_Section Subtitle";
	margin-top:3.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:26.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphTitle, li.AParagraphTitle, div.AParagraphTitle
	{mso-style-name:A_ParagraphTitle;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphHeader, li.AParagraphHeader, div.AParagraphHeader
	{mso-style-name:A_ParagraphHeader;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphTitle2, li.AParagraphTitle2, div.AParagraphTitle2
	{mso-style-name:A_ParagraphTitle2;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphHeader2, li.AParagraphHeader2, div.AParagraphHeader2
	{mso-style-name:A_ParagraphHeader2;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphTitle3, li.AParagraphTitle3, div.AParagraphTitle3
	{mso-style-name:A_ParagraphTitle3;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphHeader3, li.AParagraphHeader3, div.AParagraphHeader3
	{mso-style-name:A_ParagraphHeader3;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphTitle4, li.AParagraphTitle4, div.AParagraphTitle4
	{mso-style-name:A_ParagraphTitle4;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphHeader4, li.AParagraphHeader4, div.AParagraphHeader4
	{mso-style-name:A_ParagraphHeader4;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphTitle5, li.AParagraphTitle5, div.AParagraphTitle5
	{mso-style-name:A_ParagraphTitle5;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphHeader5, li.AParagraphHeader5, div.AParagraphHeader5
	{mso-style-name:A_ParagraphHeader5;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.BParagraphTitle, li.BParagraphTitle, div.BParagraphTitle
	{mso-style-name:B_ParagraphTitle;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.BParagraphHeader, li.BParagraphHeader, div.BParagraphHeader
	{mso-style-name:B_ParagraphHeader;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.AParagraphTitle6, li.AParagraphTitle6, div.AParagraphTitle6
	{mso-style-name:A_ParagraphTitle6;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.DParagraphTitle, li.DParagraphTitle, div.DParagraphTitle
	{mso-style-name:D_ParagraphTitle;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:16.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.DParagraphHeader, li.DParagraphHeader, div.DParagraphHeader
	{mso-style-name:D_ParagraphHeader;
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.BTitle, li.BTitle, div.BTitle
	{mso-style-name:B_Title;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.DTitle, li.DTitle, div.DTitle
	{mso-style-name:D_Title;
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.NewBodyNumHeaderlvl1, li.NewBodyNumHeaderlvl1, div.NewBodyNumHeaderlvl1
	{mso-style-name:"New Body Num Header_lvl1";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:6.0pt;
	margin-left:21.6pt;
	text-indent:-21.6pt;
	line-height:110%;
	font-size:10.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCPageTitleB, li.TNCPageTitleB, div.TNCPageTitleB
	{mso-style-name:"TNC_Page Title B";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCParagraphHeaderB, li.TNCParagraphHeaderB, div.TNCParagraphHeaderB
	{mso-style-name:"TNC_Paragraph Header B";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCPageTitleC, li.TNCPageTitleC, div.TNCPageTitleC
	{mso-style-name:"TNC_Page Title C";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCParagraphHeaderC, li.TNCParagraphHeaderC, div.TNCParagraphHeaderC
	{mso-style-name:"TNC_Paragraph Header C";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCPageTitleD, li.TNCPageTitleD, div.TNCPageTitleD
	{mso-style-name:"TNC_Page Title D";
	margin-top:0cm;
	margin-right:0cm;
	margin-bottom:92.0pt;
	margin-left:0cm;
	line-height:26.0pt;
	page-break-before:always;
	font-size:20.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
p.TNCParagraphHeaderD, li.TNCParagraphHeaderD, div.TNCParagraphHeaderD
	{mso-style-name:"TNC_Paragraph Header D";
	margin-top:18.0pt;
	margin-right:0cm;
	margin-bottom:10.0pt;
	margin-left:0cm;
	line-height:110%;
	font-size:14.0pt;
	font-family:"Arial",sans-serif;
	color:black;
	font-weight:bold;}
.MsoChpDefault
	{font-size:10.0pt;}
 /* Page Definitions */
 @page WordSection1
	{size:595.45pt 841.7pt;
	margin:64.8pt 48.25pt 80.65pt 48.25pt;
	border-top:#1200DD;
	border-left:#0000DC;
	border-bottom:white;
	border-right:#120084;
	border-style:none;
	border-width:1.0pt;
	padding:12.0pt 27.0pt 13.0pt 12.0pt;}
div.WordSection1
	{page:WordSection1;}
@page WordSection2
	{size:595.45pt 841.7pt;
	margin:63.35pt 47.5pt 79.2pt 47.5pt;
	border-top:#1200DD;
	border-left:#0000DC;
	border-bottom:white;
	border-right:#120084;
	border-style:none;
	border-width:1.0pt;
	padding:12.0pt 27.0pt 13.0pt 12.0pt;}
div.WordSection2
	{page:WordSection2;}
@page WordSection3
	{size:595.45pt 841.7pt;
	margin:63.35pt 47.5pt 79.2pt 47.5pt;
	border-top:#1200DD;
	border-left:#0000DC;
	border-bottom:white;
	border-right:#120084;
	border-style:none;
	border-width:1.0pt;
	padding:12.0pt 27.0pt 13.0pt 12.0pt;}
div.WordSection3
	{page:WordSection3;}
 /* List Definitions */
 ol
	{margin-bottom:0cm;}
ul
	{margin-bottom:0cm;}
-->
</style>
</head>
<body lang="ZH-CN" link="blue" vlink="blue">
<div class="WordSection2">
<p class="TNCBodyText1"><b><span lang="EN-US">Please read these terms and
conditions carefully before applying to Access, nominating a user for and/or
using, the Private Banking investment website and/or app including the
applications which you can access via the website and/or app </span></b><span lang="
EN-US">(Collectively this website <a href="https://www.privatebanking.hsbc.co.uk/online-investments/">https://www.privatebanking.hsbc.co.uk/online-investments/</a>,
the mobile app and the underlying applications which you can access via the
website and mobile app shall be referred to as the “Digital Services”).</span></p>
<p class="TNCBodyText1"><span lang="EN-US">By using these Digital Services, you
signify your agreement to these terms and conditions and if you are using these
Digital Services on behalf of any other accountholder(s) (including, without
limitation, any other individual(s), a partnership, a company or any other
legal entity), you are confirming that you have full authority to bind that
person to these terms and conditions.&nbsp; If you do not agree to these terms
and conditions, please do not use the Digital Services.</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">References
to 'Terms and Conditions' are references to these terms and conditions, as
amended from time to time;</span></p>
<p class="TNCBulletOne" style="margin-left:21.6pt;text-indent:-21.6pt"><span lang="EN-US" style="
font-family:Wingdings;color:#DB0011">u<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;
</span></span><span lang="EN-US">References to 'you', 'your' and 'yours' are
references to the person accessing the Digital Services or where that person is
acting on behalf of any other accountholder(s), then to the person accessing
the web and/or mobile app and to the relevant accountholder(s);</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">References
to "we", "us" and "our" are references to HSBC UK
Bank plc acting through our Private Banking division; </span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">References
to "HSBC" are references to HSBC UK Bank plc together with its
affiliates and associated entities including other members of the HSBC Group; </span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">References
to the "HSBC Group" are to HSBC Holdings plc and its direct and
indirect subsidiaries;</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">References
to your "Relationship Manager" are to the individual from time to
time notified by us to you (or, where you are accessing the Digital Services on
behalf of any other accountholder, notified by us to them) as your (or their) Private
Banking Relationship Manager.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">&nbsp;</span></p>
<p class="TNCNumList"><span lang="EN-US">1.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Company information</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">The
Digital Services are owned by HSBC UK Bank plc, registered in England and Wales
under Company Registered Number 09928412 and with its registered office at 1
Centenary Square, Birmingham, B1 1HQ, UK </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">The
information contained on the Digital Services is for information purposes only.
HSBC does not hold itself out as providing legal, financial or other advice via
the Digital Services.</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Please
note that we will use your personal information in accordance with our Privacy
Policy. </span></p>
<p class="TNCNumList"><span lang="EN-US">2.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Digital services terms</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">These
Terms and Conditions govern your access and use of the Digital Services and
each time you access or use the Digital Services you agree to be bound by them.
If you do not accept these Terms and Conditions in full you should not access
or use these Digital Services and should leave the Digital Services
immediately. These Terms and Conditions refer to the following additional terms,
which also apply to your access or use of our Digital Services:</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">our
Privacy Policy, which sets out the basis on which we process any personal
information we collect from you, or that you provide to us.</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">our
Cookie Policy, which sets out information about the cookies on the Digital
Services.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">&nbsp;</span></p>
<p class="TNCNumList"><span lang="EN-US">3.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Accessing the digital services</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">These
Digital Services are made available free of charge.</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">We
do not guarantee that the Digital Services, or any content on it, will always
be available or be uninterrupted. Access to the Digital Services is permitted
on a temporary basis. We may at any time suspend, withdraw, or discontinue all
or any part of the Digital Services including under sections 15 (Suspension and
Termination of Access) and 17 (Ending this Agreement). </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">You
are responsible for making all arrangements necessary for you to have access to
our Digital Services.</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">You
are also responsible for ensuring that all persons who access the Digital
Services through your Internet connection are aware of these Terms and
Conditions and other applicable terms and conditions (including the Privacy
Policy), and that they comply with them.</span></p>
<p class="TNCNumList"><span lang="EN-US">4.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">No warranty</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">This
section 4 and sections 7 (Linked Websites / Frames / Use) and 12 (Limitation of
Liability) below limit or exclude our legal liability for the Digital Services
and your use of the Digital Services.&nbsp; You should read them carefully.</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Other
than the warranties and representations contained in these Terms and Conditions
all other warranties and representations, whether express, implied or statutory
are, to the fullest extent permitted by applicable law, excluded. </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Whilst
we have taken reasonable steps to ensure the accuracy, currency, availability,
correctness and completeness of the information contained on the Digital
Services the information (including text, graphics, and functionality) is
presented “As Is” and “As Available.”</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">In
particular, HSBC makes no warranties or representations regarding:</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">the
accuracy, completeness or timeliness of the content, information and material
available on the Digital Services; or </span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">the
error-free use of the Digital Services; or</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">the
availability of the Digital Services or that it will meet any requirements you
might have, that access will be uninterrupted or that there will be no delays,
failures, errors or omissions of information available through the Digital
Services.</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">the
adequate protection and back up of data on the Digital Services, and you
acknowledge that you have sole responsibility for adequate protection and back
up of your own data and/or equipment and for undertaking reasonable and
appropriate precautions to scan for computer viruses or other destructive
properties when accessing or using the Digital Services; or</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">the
accuracy, functionality or performance of any third party software that may be
used in connection with the Digital Services</span></p>
<p class="TNCNumList" style="text-indent:0cm"><span lang="EN-US">Please see section
16 (Important Information) for further information concerning the content of
the Digital Services and certain limitations which may be applicable to it.</span></p>
<p class="TNCNumList"><span lang="EN-US">5.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Intellectual property rights</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">All
intellectual property rights (including, without limitation, copyright,
database rights, Design rights, patents and trade marks) in these Digital
Services (including text and “look and feel” attributes) are owned by HSBC or
licensed to HSBC by third party licensors unless otherwise stated. Your access
to or use of the Digital Services does not grant you any intellectual property
rights in the Digital Services. You may print, copy, download or temporarily
store extracts from the Digital Services for your personal use or to help you
use our products and services. You may not alter or otherwise make any changes
to any material obtained from the Digital Services in any form, including,
without limitation, removing any proprietary information, identifying marks or
legends from such material. Any other use is prohibited unless you first
request and obtain our written permission. Without limiting the above, unless
you first obtain our written consent, you may not reproduce, modify, adapt,
transmit, publish, broadcast, create derivative works of, store, archive or in
any way exploit all or any part of these Digital Services.&nbsp; All rights in
such content are hereby reserved.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Content
from a third party provider available on or through the Digital Services may
not be duplicated, distributed, published, transferred, transmitted, copied,
altered, sold, used to create derivative works or otherwise misused. You must
comply with all terms disclosed to you as required from time to time by any
third party supplier of data or services to the Digital Services, including but
not limited to, where necessary, entering into a direct agreement with such
third party in respect of your use of their data or complying with any terms
and conditions such third party supplier of data or services may require you to
comply with.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">HSBC
and the hexagon logo are trade marks of HSBC Group Management Services Limited
and all rights in and to them vest in HSBC Group Management Services Limited.
Other than as provided above you may not use or reproduce the HSBC trade mark,
hexagon logo or brand name.&nbsp; </span></p>
<p class="TNCNumList"><span lang="EN-US">6.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Viruses</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Because
of the marked increase in the fabrication and proliferation of computer viruses
affecting the Internet, HSBC wants to warn you about infections or viral
contamination on your system.&nbsp; It is your responsibility to undertake
reasonable and appropriate precautions to scan any and all downloaded materials
received from the Internet or submitted to you via the Digital Services.</span></p>
<p class="TNCNumList"><span lang="EN-US">7.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Linked website/frames/use</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">These
Digital Services may provide links to or data from third party websites for
your convenience in locating related information and services.&nbsp; HSBC does
not maintain any of these other websites and has no control over the
organisations that maintain these websites or the information, products, or services
these organisations provide.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">HSBC
cannot guarantee such websites' accuracy, completeness or suitability for any
purpose.&nbsp; Accordingly, HSBC expressly disclaims any responsibility for the
content of these other websites, the accuracy of the information on these
websites and/or the quality of products or services provided by the
organisations that maintain them.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">HSBC
does not recommend or endorse these organisations or their products or services
in any way and access to them is at the user’s own risk.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">You
are prohibited from linking to these Digital Services from any other website;
from framing any of the materials on these Digital Services; and/or from
suggesting any affiliation or endorsement between us, without our prior written
authorisation.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">You
may print copies of the material contained in these Digital Services solely for
your personal use in connection with the services provided to you by HSBC and
solely in accordance with these Terms and Conditions.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">You
are expressly prohibited from selling; distributing; copying, amending;
modifying; posting; transmitting; uploading; or similar action regarding the
material in these Digital Services including: graphics; text; content; logos or
the like.&nbsp; Further, you are prohibited from using these Digital Services
unlawfully and will be responsible for any and all of your unlawful use of
these Digital Services.</span></p>
<p class="TNCNumList"><span lang="EN-US">8.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">&nbsp;Responsibility for use and control of
security details and your information</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">The
method by which you access HSBC Private Banking Digital Investment Services may
vary over time and you will be fully informed as these changes occur.</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">With
your Security Details, you will have access to the Digital Services and the
tools and information available through the Digital Services and we hereby
grant you a limited right to use the Digital Services and such tools and
information, which right we may revoke at any time.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Your
Security Details and any other non-public personal information and other
financial information which you may provide to HSBC about yourself in
connection with the use of the Digital Services, will be stored and used in
accordance with our Privacy Policy and, where relevant, our Private Banking Investment
Services Terms and Conditions.&nbsp; If you require a further copy of our Private
Banking Investment Services Terms and Conditions please contact your
Relationship Manager.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">You
hereby declare that you have all required permission to provide HSBC with this
information and will be solely liable to pay any costs reasonably incurred by
HSBC for any claims that you did not have such authority.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">You
further acknowledge that you are fully responsible for the use of your Security
Details, whether authorised by or known by you or not, and the protection of
your Security Details and subject to any obligations we may have under
applicable law or regulation you shall pay any costs reasonably incurred by
HSBC in respect of any claims related to any unauthorised access or use of the
Digital Services or other HSBC systems, resulting from negligence on your part.&nbsp;
</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">You
agree to immediately notify us if you become aware of any of the following: (a)
loss or theft of your Security Details; (b) unauthorised use of your Security
Details or any unauthorised use of these Digital Services; and (c) any other
information which you believe compromises the security of your information
available through these Digital Services. </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Where
applicable you consent to the provision of these Terms and Conditions through
the Digital Services</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">For
the purposes of this agreement, Security Details means your user name,
temporary password, any password selected thereafter, a mobile PIN that will
consist of a six digit pin and/or biometric, where relevant and any other
security details you may be required by us to use at any time to access the
Digital Services.</span></p>
<p class="TNCNumList"><span lang="EN-US">9.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Online fraud advisory </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">HSBC
will never send emails that require customers to send their information to it
via email, website link or pop-up windows.&nbsp; Any unsolicited request for
HSBC account information you receive through emails, websites, or pop-up
windows should be considered fraudulent.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Online
fraud occurs when someone poses as a legitimate company to obtain sensitive
personal data and then illegally conducts transactions on your existing
accounts.&nbsp; Often called "phishing" or "spoofing," the
most pervasive methods of online fraud are emails, counterfeit websites and
pop-up windows, or any combination of these.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Fraudulent
emails often:</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">appear
to be from a legitimate, trusted source.&nbsp; You should not rely on the name
or address in the "From" field, as this can be easily altered;&nbsp; </span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">ask
you for personal information.&nbsp; These emails often claim that your
information has been compromised or frozen, or ask you to confirm your
identity;&nbsp; </span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">link
to counterfeit websites.&nbsp; These sites may appear legitimate, but actually
collect personal information for illegal use.&nbsp; They may also include a
link to the real website in an attempt to make the link seem legitimate;
and&nbsp; </span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">contain
fraudulent phone numbers.&nbsp; These telephone numbers are usually tied
directly to the fraud perpetrators.&nbsp; Never call a number featured on an
email you suspect is fraudulent, and be sure to double-check any numbers you do
call.&nbsp; In addition, a legitimate number may be included in an attempt to
authenticate the email.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">To
help protect yourself from these fraudulent emails and websites:</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">never
provide sensitive account or personal information in response to an
email;&nbsp; </span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">delete
suspicious emails without opening them.&nbsp; If you do open a suspicious
email, do not open any attachments or click on any links it may contain;&nbsp; </span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">bookmark
any sites that house personal account information and use those bookmarks to
navigate directly to those sites;&nbsp; </span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">install
and regularly update virus protection software; and&nbsp; </span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">keep
your computer operating system and Web browser current.&nbsp; </span></p>
<p class="TNCNumList"><span lang="EN-US">10.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Changes</span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">HSBC
may change the Digital Services or update information, material or content at
any time without notice.&nbsp; While we shall make reasonable efforts to keep
the Digital Services information accurate, HSBC is not obligated to update or
correct information within any specified time period.&nbsp; HSBC is not
responsible for information provided by third parties, whether the information is
part of these Digital Services, is in any linked website, or is information
about us that is provided on any website other than this one.&nbsp; </span></p>
<p class="TNCNumList" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">We
reserve the right, to change these Terms and Conditions at any time where we
reasonably believe the change is to your advantage or is needed for any one or
more of the following reasons:</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">to
respond proportionately to changes in general law or decisions of the Financial
Ombudsman Service;</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">to
meet our regulatory requirements;</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">to
reflect new industry guidance and codes of practice;</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">to
respond to the making of a relevant recommendation, requirement or decision of
any court, ombudsman, regulator or similar organisation; or</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">to
allow us to make changes to the way in which we manage the Digital Services or
the service available through it as a result of changes to technology or the
systems we use including introducing new services or facilities or replacing an
existing service or facility with a new one.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">We
have the right to make changes to these Terms and Conditions for reasons not
stated above as long as we give you prior notice and you are able to end this
service before the change takes effect without charge.&nbsp; We need this
flexibility because we need to be able to manage how we provide our services
over a long period of time and it is very difficult for us to anticipate all
the circumstances when we may need to make a change to how the service
delivered to you via the Digital Services works.&nbsp; If you do not like any
change we make you can exercise your right to end this service and these Terms
and Conditions between us.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Where
you are a user of the Digital Services on behalf of an account holder we may
notify you of changes to these Terms and Conditions via the Digital Services
including by requesting your agreement to the amended Terms and Conditions when
you access the Digital Services. </span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">The
current version of these Terms and Conditions from time to time in force will
be available via the Digital Services.&nbsp; Where these Terms and Conditions
have been changed and you need a further copy of any prior version which is not
available on the Digital Services, please ask your Relationship Manager for a
hard copy. </span></p>
<p class="TNCNumList"><span lang="EN-US">11.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Privacy</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">We
are committed to protecting your privacy. All information gathered from you in
connection with your use of the Digital Services will be maintained in
accordance with the applicable Privacy Policy for the Digital Services and,
where relevant, our Investment Services Terms and Conditions.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">These
Digital Services also use cookies in accordance with the applicable cookie
policies disclosed for the Digital Services.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">By
using these Digital Services, you consent to our processing of the information
referred to in this paragraph 11 and you warrant that all data provided by you
is true and accurate.</span></p>
<p class="TNCNumList"><span lang="EN-US">12.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Limitation of liability</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">We
will not be responsible for any loss of or damage to your data, software,
computer, telecommunications or other equipment caused by you using the Digital
Services unless such loss or damage is directly and solely caused by our
negligence or deliberate default.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Subject
to the foregoing, and to the extent permitted by law, neither we nor any member
of the HSBC Group shall be liable for any damage, loss or liability (whether
arising in contract, tort, including negligence, or otherwise) arising out of
or in connection with (a) your use of these Digital Services; (b) your reliance
on any information or materials on the Digital Services or your inability to
use or access the information or materials on the Digital Services; and/or (c)
any failure in performance, error, omission, interruption, defect, delay in
operation or transmission, computer virus or line or system failure to these
Digital Services or your use or attempted use of it.&nbsp; Please note that if
for any reason the digital services are not accessible you can contact your
Relationship Manager for any information you require concerning your investment
accounts.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">The
above exclusions of liability apply to any damage, loss or liability which is
direct, indirect, special, incidental or consequential or consists of loss of
profits, business, goodwill, opportunity or data. All of the above exclusions
apply even if you have advised us or any other member of the HSBC Group of the
possibility of the above types of damage, loss or liability. </span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Nothing
in these terms shall exclude or limit our liability in relation to:</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">fraud,
fraudulent misrepresentation, personal injury or death; or</span></p>
<p class="TNCBulletOne"><span lang="EN-US" style="font-family:Wingdings;color:#DB0011">u<span style="
font:7.0pt &quot;Times New Roman&quot;">&nbsp; </span></span><span lang="EN-US">any
other matter which cannot be excluded by law including any duties and
liabilities owed to you under the Financial Services and Markets Act 2000, the
rules of the FCA or otherwise under the UK regulatory system.</span></p>
<p class="TNCNumList"><span lang="EN-US">13.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Complaints and disputes</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">If
at any time you are not satisfied with the service you have received through
the Digital Services and wish to make a complaint, you should write, with full
details to:</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Head
of Private Banking</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">HSBC
UK Bank plc</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">8
Cork Street</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">London
W1S 3LJ</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Alternatively
you can call us on: +44 20 7860 5000</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">For
further details about how we handle complaints, you can ask for a copy of our
complaints handling procedure at any time.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">If
you are not satisfied with our response, or if eight weeks have passed since
you first raised the matter with us, you may have the right to refer your
complaint to the Financial Ombudsman Service (FOS).&nbsp; They can be contacted
at:</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">The
Financial Ombudsman Service</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Exchange
Tower</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">London
E14 9SR</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Email:
complaint.info@financial-ombudsman.org.uk</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Telephone:
0800 0234 567 (if calling from a landline) or 0300 1239123 (if calling from a
mobile) </span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Website:
www.financial-ombudsman.org.uk </span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Should
you decide to refer your concerns to FOS you must do so no later than 6 months
from the date of our final response letter.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">In
addition please note that disputes relating to the service provided through the
Digital Services may be submitted for online resolution to the European
Commission Online Dispute Resolution platform <a href="http://ec.europa.eu/odr">http://ec.europa.eu/odr</a>.&nbsp;
This option may cease to be available following expiry of the transition period
for the UK’s exit from the European Union.&nbsp;&nbsp; Please contact your
Relationship Manager if you have any questions about this.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">All
disputes arising out of or in connection with any contractual or
non-contractual obligations arising from or connected with these terms of use
will be governed by and shall be construed in accordance with the laws of
England and Wales. The parties hereby irrevocably submit to the non-exclusive
jurisdiction of the English courts over any claim or dispute arising from, or
related to, use of the Digital Services (whether arising out of or in
connection with contractual or non-contractual obligations).</span></p>
<p class="TNCNumList"><span lang="EN-US">14.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Age and responsibility</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">The
Digital Services are not intended for use by minors.&nbsp; You confirm that you
are of sufficient legal age to use these Digital Services and to create binding
legal obligations for any liability you may incur as a result of the use of these
Digital Services.&nbsp; You agree that you are responsible for all uses of these
Digital Services by you and those using your login information.</span></p>
<p class="TNCNumList"><span lang="EN-US">15.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Suspension and termination of access</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">We
reserve the right to suspend or terminate your access to these Digital Services
where we have reasonable grounds to do so including grounds relating to the
security of, or the suspected unauthorised or fraudulent use of, the Digital
Services and/or any applicable legal or regulatory obligations.</span></p>
<p class="TNCNumList"><span lang="EN-US">16.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Important information </span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">These
Digital Services are maintained and this information is issued by HSBC UK Bank
plc.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">HSBC
UK Bank plc is authorised by the Prudential Regulation Authority and regulated
by the Financial Conduct Authority and the Prudential Regulation Authority. If
you would like further information about the FCA, you can access their website
at <a href="http://www.fca.org.uk/">www.fca.org.uk</a></span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">These
Digital Services have been designed for access from within the United Kingdom.
Because of this we cannot guarantee that the Digital Services, or the
information on it, complies with the local laws or regulations of, or is
appropriate for use in, any other jurisdiction from which you access it. If you
choose to access the Digital Services from a jurisdiction other than the United
Kingdom you must keep yourself informed about and comply with, any restrictions
or other requirements under local laws and regulations.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Access
to these Digital Services is restricted and requires valid user authentication.
No person should attempt to gain access to these Digital Services at any time
when they do not hold valid Security Details.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Information
available on the Digital Services about your holdings may be limited to
investments and assets held in your Private Banking investment accounts with
HSBC UK Bank plc. This means details of some investment, such as OTC derivative
contracts executed with or through us or insurance bonds, may not be available
on the Digital Services. In addition, the Digital Services will not provide
details of any cash in your bank accounts with us (except for cash held on your
investment accounts) or any investments or assets booked through or with other
parts of the HSBC Group (including other HSBC Private Banking booking centres).</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">The
Digital Services are intended to provide you with a general overview of your
relevant investments and assets only. Any values attributed to investments on
the Digital Services (and any associated performance figures) are based on
pricing sources (and where relevant pricing methodologies) selected by us and
are only updated periodically. Actual prices when transacting in any relevant
investment may vary and the value of investments fluctuates.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Accordingly,
all values (and any performance figures) are indicative only. It should be
noted that any stated performance figures are calculated on the basis of total
return and include gross uncrystallised gains and losses. In addition, certain
investments or assets may be omitted from these figures.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">The
information displayed on screen in relation to your investments and assets is
for reference only and is not intended to replace your formal statements of
assets, contract notes or any other formal written communications from us which
you should continue to refer to and will prevail in the event of any
inconsistency.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">We
may give you the option to receive your statements of assets, contract notes or
other formal documents through the Digital Services instead of by post. &nbsp;Where
you opt into this service, we will notify you by email (or by other electronic means)
when any relevant document has been posted in the Digital Services and tell you
how long it will remain available for you to view, download, save or print.&nbsp;
Once posted we will not amend or (during the stated availability period) delete
the document.&nbsp; Any election you make to receive formal documents in this
way can be changed at any time by contacting your Relationship Manager.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">It
is important that you save and safely store in your records any formal documents
provided through the Digital Services. &nbsp;If access through the Digital
Services to any such document is lost because our relationship ends or the
Digital Services are suspended or terminate for any other reason we will at your
request, during the period for which we have confirmed the document will remain
available, provide a copy at no extra cost.&nbsp; </span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">In
addition, we may make available certain publications to you via Digital
Services.&nbsp; These may include any third party macroeconomic research or
issuer/instrument specific research which we have agreed to give you access to as
part of any investment service.&nbsp; Where we provide publications in this way
you will be able to select preferences through the Digital Services which allow
you to search for, filter or order publications and receive email alerts when
new publications are posted (which may include links to the relevant
publications) according to your interests.&nbsp; You can update your
preferences (including turning off any selected email alerts) at any time
through the Digital Services. &nbsp;&nbsp;&nbsp;</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Before
you access any research publications through the Digital Services (or any
related email alerts) please make sure you read the associated disclosures.&nbsp;
These can be accessed in the Digital Services or in the case of any email alert
will be set out in the email. The disclosures include important information
about the timing of any research dissemination, potential conflicts of interest
and third party disclaimers.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Where
you have expressly requested us to do so in writing and we agree, we may provide
you through the Digital Services with information about possible investments
opportunities or themes that could be of interest to you and related fulfilment
ideas.&nbsp; If you request content of this nature you may be asked to agree to
additional terms and conditions governing the basis on which any such
information and ideas are provided.&nbsp; In any event, the purpose of
providing any such information and ideas is solely to draw them to your
attention and they should not be treated as a personal recommendation or
investment advice of any kind. &nbsp;</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">For
further details concerning the information available via the Digital Services
please contact your Relationship Manager.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Nothing
on the Digital Services constitutes an offer or solicitation to sell or buy any
investment or advice or a recommendation in respect of any investment. The
information on these Digital Services is not intended for distribution to, or
use by, any person in any jurisdiction where such distribution would be
contrary to law or regulation.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Persons
interested in acquiring any investment should inform themselves as to: (i) the
legal requirements within the countries of their nationality, residence,
ordinary residence or domicile for such acquisition; (ii) any foreign exchange
control requirement which they might encounter on the acquisition or sale of
investments; and (iii) the income tax and other tax consequences which might be
relevant to the acquisition, holding or disposal of any investment.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Investors
should remember that the price of investments can go down as well as up as a
result of a variety of factors including market conditions, changes in the
value of any underlying securities and currency movements. Past performance is
not a guide to future performance and investors may not get back the full
amount (or any) of their investment.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">No
representation is given that any investments referred to on the Digital
Services are suitable for you or any particular person. The provision of
information through these Digital Services should not be considered and does
not constitute investment or financial advice of any kind. Any investment
decision should be made having read fully (as applicable) the relevant terms
and conditions, application forms and any other documentation relating to the
chosen investment and having taken professional investment and/or financial
advice as appropriate.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Please
refer to the risk disclosure document provided to you headed “Disclosure in
relation to designated investment and associated risks” for general information
about the risks associated with different investment types. For further
information about the various investments and products available through us,
including where relevant, terms and conditions, application forms, information
sheets and other product literature please consult with your Relationship
Manager.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">Where
information is provided regarding the tax characteristics of particular
investments this will reflect our understanding of current tax laws and
practices. However, this does not constitute tax advice and should not be
relied upon as such. The tax treatment of an investment may vary depending on
individual circumstances. There can be no guarantee that the nature, basis or
incidence of taxation will not change,</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">You
are reminded that it is your responsibility to complete tax returns including
all information required by the tax authorities in the country or countries in
which you are subject to tax in accordance with all applicable laws and tax
rules in those countries. You are solely responsible for understanding and
complying with your tax obligations including identifying all your taxable
income, gains and assets as applicable. The information on these Digital
Services has not been designed and should not be used for tax reporting
purposes. We do not provide legal or tax advice and if you are in any doubt
about the tax consequences of any investment and/or your obligations with
respect to tax you should consult an independent tax and/or legal adviser.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">For
the avoidance of doubt, nothing in this section 16 limits the general
applicability of sections 4 (No Warranty),7 (Linked Websites/Frames/Use) and 12
(Limitation of Liability).</span></p>
<p class="TNCNumList"><span lang="EN-US">17.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Ending this agreement</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">You
can end the agreement under these Terms and Conditions by writing to your
Relationship Manager at any time.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">We
can end the agreement under these Terms and Conditions immediately if you have
significantly broken any provisions of these Terms and Conditions or if we have
reasonable grounds for believing you have committed a crime or are about to
commit a crime in connection with your use of the Digital Services or if our
Private Banking Investment Services Terms and Conditions terminate for any
reason.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">We
may end the agreement under these Terms and Conditions for any other reason by
giving you (or, where you have been granted access to the Digital Services on
behalf of any other accountholder(s), by giving the relevant accountholder(s))
at least 30 days' prior notice.</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">If
the agreement under these Terms and Conditions ends you (including, where
relevant, your authorised users) will no longer be able to access the Digital
Services.</span></p>
<p class="TNCNumList"><span lang="EN-US">18.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Notices</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">We
will communicate with you for the purposes of these Terms and Conditions in
English. Any notices to be given by us under these Terms and Conditions may be
given in accordance with our Investment Services Terms and Conditions and/or,
subject to the requirements of any applicable law or regulation, may be given
electronically through the Digital Services.</span></p>
<p class="TNCNumList"><span lang="EN-US">19.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Product and service terms</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">All
HSBC's products and services are subject to the terms and conditions and
disclaimers of the applicable agreement governing their use including, where
relevant, our Private Banking Investment Services Terms and Conditions.</span></p>
<p class="TNCNumList"><span lang="EN-US">20.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;
</span></span><span lang="EN-US">Further information and feedback</span></p>
<p class="TNCBulletOne" style="margin-left:0cm;text-indent:0cm"><span lang="EN-US">If
you need any information concerning the services available through the Digital
Services or you have any feedback regarding the Digital Services, please
contact your Relationship Manager.</span></p>
<p class="TNCHeading1Bullet" style="margin-left:21.6pt;text-indent:0cm"><span lang="EN-US">&nbsp;</span></p>
<p class="MsoHeader" align="right" style="text-align:left"><span lang="EN-US">Version 6, last updated: March 2021</span></p>
</div>
<span lang="EN-US" style="font-size:10.0pt;line-height:110%;font-family:&quot;Arial&quot;,sans-serif;
color:black"><br clear="all" style="page-break-before:auto">
</span>
</body></html>
`;
